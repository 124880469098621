import { RegisterCustomerState, RegisterCustomerResponseProps } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { postRegisterCustomer } from './registerCustomer.services';

export const initialState: RegisterCustomerState = {
    data: {} as RegisterCustomerResponseProps,
    status: "loading",
    error: {}
}

export const registerCustomerSlice = createSlice({
    name: 'appSetting',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(postRegisterCustomer.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(postRegisterCustomer.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(postRegisterCustomer.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })

    }
})

export const RegisterCustomerReducer = registerCustomerSlice.reducer