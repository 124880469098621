import { useState } from "react";
import MainLayout from "components/Layouts/MainLayout";
import { Box, Switch } from "@mui/material";
import { NormalText } from "components/styled/text.styled";
import { Button, Grid } from "@mui/material";
import LogoPagiiOrdering from "asset/logo/pagii-ordering-logomark-default.svg"
import "./styles.css";
import { useNavigate } from "react-router-dom";
import useRedux from "../../redux/useRedux";
import { storeInfo } from "redux/storeInfo";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { selectServices } from "redux/config/config.reducer";
import { persistor } from "redux/store";


const ConfigPage = () => {
  const [storeId, setStoreId] = useState<string>('');
  const [pickservice, setPickServices] = useState(true);
  const [error, setError] = useState(false);
  let navigate = useNavigate();
  const { thunkDispatch } = useRedux();
  const handleSave = () => {
    if (!storeId) {
      setError(true);
    } else {
      setError(false);
      thunkDispatch(selectServices({ enable: pickservice }));
      thunkDispatch(storeInfo(storeId))
        .unwrap()
        .then((res) => {
          if (res && res.status === 'success') {
            persistor.persist();
            navigate('/');
          } else if (res && res.status === 'error') {
            toast.dark(res.error.response.data.message, {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              closeButton: false,
              style: {
                textAlign: 'center',
                margin: '50% 0 0 0',
                width: '80% !important',
              },
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log('CATCH', err.response);
          }
        });
    }
  };
  return (
    <MainLayout
      noTimeout
      background="#FFE39D"
      title="Configure | Kiosk Sandbox"
    >
      <ToastContainer />
      <Box width={'100%'}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <img
            src={LogoPagiiOrdering}
            alt="logo"
            style={{
              width: '224px',
              height: '224px',
              marginTop: '50px',
              backgroundColor: "FFE39D",
            }}
          />
          <NormalText
            fontSize="46px"
            fontWeight="bold"
            letterSpacing="3px"
            lineHeight="32px"
            color={'black'}
            textAlign="center"
            margin="100px 0 100px 0"
          >
            CONFIGURE KIOSK
          </NormalText>
          <input
            type="text"
            name="StoreId"
            placeholder="STORE CODE"
            className={`form-control ${error ? 'error' : ''}`}
            style={{
              height: '80px',
              width: '80%',
              fontSize: '24px',
              marginTop: '30px',
            }}
            value={storeId}
            onChange={(e: any) => setStoreId(e.target.value)}
          />
          {error && (
            <NormalText fontSize="25px" color="#b00101" textAlign="left">
              Silahkan isi store code terlebih dahulu
            </NormalText>
          )}
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ paddingLeft: '110px', marginTop: '30px' }}
          >
            <NormalText
              fontSize="20px"
              fontWeight="bold"
              letterSpacing="3px"
              lineHeight="16px"
              color={'black'}
              textAlign="center"
              margin="10px 0 10px 0"
            >
              Enable Pick Services
            </NormalText>
            <Switch
              checked={pickservice}
              onChange={(event) => setPickServices(event.target.checked)}
            />
          </Grid>
          <Button
            size="medium"
            variant="contained"
            sx={{
              fontSize: '24px',
              fontWeight: '500',
              marginTop: '130px',
              width: '80%',
              padding: '20px 0',
              marginBottom: '30px',
            }}
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            size="medium"
            variant="contained"
            sx={{
              fontSize: '24px',
              fontWeight: '500',
              backgroundColor: 'white',
              color: 'black',
              '&:hover': {
                backgroundColor: 'rgba(255,255,255, 0.9)',
              },
              width: '80%',
            }}
            onClick={() => {
              window.location.reload()
              navigate('/')
            }}
          >
            Back
          </Button>
        </Grid>
      </Box>
    </MainLayout>
  );
};

export default ConfigPage;
