import React from "react";
import { Box, Stack, Skeleton } from "@mui/material";

const PaymentMethodShimmer = () => {
  return (
    <Box margin={'20px 0 0 0'}>
      <Stack direction={"row"} spacing={2}>
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={118}
          sx={{ borderRadius: "20px" }}
        />
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={118}
          sx={{ borderRadius: "20px" }}
        />
      </Stack>
    </Box>
  );
};

export default PaymentMethodShimmer;
