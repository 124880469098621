import React from "react";
import { AppContainer } from "../../components/styled/layout.styled";
import MainLayout from "components/Layouts/MainLayout";
import "./styles.css";
import { Box, Grid, Stack, Avatar } from "@mui/material";
import { NormalText } from "components/styled/text.styled";
import { useNavigate } from "react-router-dom";
import { STORAGE_SERVICE_KEY } from "helper/constants";
import useRedux from "redux/useRedux";
import { AppSettingData } from "redux/appsetting";
// import BrandItem from "../Home/brand.item";


// import icon
import DineInIcon from "asset/icons/ic_dine_in.svg";
import DeliveryIcon from "asset/icons/ic_delivery.svg";
import TakeAwayIcon from "asset/icons/ic_take_away.svg";
import PickupIcon from "asset/icons/ic_pickup_point.svg";
import StorePickupIcon from "asset/icons/ic_store_pickup.svg";
import SandBoxIcon from "asset/icons/ic_sandbox.svg";
import SandBox from "asset/images/sandbox.svg"


const PickServicePage = () => {
  const navigate = useNavigate();
  const {
    storeState: { AppSetting, StoreInfo },
  } = useRedux();
  const data: AppSettingData = AppSetting?.data?.data ?? null;
  console.log(StoreInfo)
  console.log(data)
  const merchantType = StoreInfo?.data?.data?.merchant_type;
  const storeLogo =  StoreInfo?.data?.data?.store_logo;
  // const storeLogo = '';

  console.log(storeLogo)
  return (
    <MainLayout
      // background={data?.primary_color ?? "#FFFFFF" }
      background={ "#FFFFFF" }
      title={data?.merchant_name ?? "Selamat Datang | Kiosk Sandbox"}
      containerStyle={{ textAlign: "-webkit-center" }}
    >
      <AppContainer>

        <Box 
          width={"100%"} 
          height={"100vh"}
          >
        <Box sx={{display:"flex",width:"100%",justifyContent:"center",alignItems:"center",marginTop:"5%"}}>
          <Avatar sx={{width:"150px", height:"150px"}}>
          <img 
            src={data?.app_logo? data.app_logo : SandBox} 
            alt=""
            style={{
              height: "auto",
              width: "100%",
              objectFit: "cover"
            }} 
          />
          </Avatar>
            { storeLogo &&
              <Avatar sx={{width:"150px", height:"150px",marginLeft:"5%"}}>
                <img 
                  src={storeLogo} 
                  alt=""
                  style={{
                    height: "100%",
                    width: "100%",
                  }}  />
              </Avatar>
            }
        </Box>
        
          <div
            className="container text-center mt-15-p"
            style={{ marginTop: "10%" }}
          >
            <NormalText
              fontSize="48px"
              fontWeight="700"
              letterSpacing="3px"
              lineHeight="50px"
              fontFamily="D-DIN Exp"
              margin="147px 0 120px 0"
              color="black"
              style={{ textTransform: "uppercase" }}
            >
              {data?.merchant_name ? "SELAMAT DATANG DI " + data.merchant_name : ""}
            </NormalText>
            <Box width={"100%"} justifyContent={"center"} alignItems={"center"}>
              <Grid container spacing={2}>

                  <Grid item xs={6} sm={6} md={6}>
                    <Box
                      onClick={() => {
                        localStorage.setItem(STORAGE_SERVICE_KEY, merchantType === "FOOD" 
                          ? "DINE IN"
                          : "IN STORE PICKUP"
                        );
                        navigate("/home");
                      }}
                      width={"100%"}
                      style={{ height: "564px", width: "422px" }}
                      alignItems={"center"}
                      justifyContent={"center"}
                      className="card-menu text-center"
                      display={"flex"}
                      margin={"auto"}
                    >
                      <Stack 
                        display={"column"}>
                        { merchantType === "FOOD" &&
                          <img 
                            src={DineInIcon} 
                            alt="DINE IN" 
                            style={{
                              margin: "auto",
                              marginBottom: "30px"
                            }}
                          />
                        }
                        { merchantType !== "FOOD" &&
                          <img 
                            src={StorePickupIcon} 
                            alt="IN STORE PICKUP" 
                            style={{
                              margin: "auto",
                              marginBottom: "90px"
                            }}
                            height={"220px"}
                          />
                        }
                        <NormalText
                          color="black"
                          fontSize="36px"
                          fontWeight="bold"
                          fontFamily="D-DIN Exp"
                          // margin="50px 0 30px 0"
                          position="relative"
                        >
                          { merchantType === "FOOD" 
                            ? "DINE IN"
                            : "IN STORE PICKUP"
                          }
                        </NormalText>
                      </Stack>
                    </Box>
                  </Grid>

                  { merchantType === "FOOD" &&
                    <Grid item xs={6} sm={6} md={6}>
                      <Box
                        onClick={() => {
                          localStorage.setItem(STORAGE_SERVICE_KEY, "TAKEAWAY");
                          navigate("/home");
                        }}
                        width={"100%"}
                        style={{ height: "564px", width: "422px" }}
                        alignItems={"center"}
                        justifyContent={"center"}
                        className="card-menu text-center"
                        display={"flex"}
                        margin={"auto"}
                      >
                        <Stack display={"column"}>
                          <img 
                            src={TakeAwayIcon} 
                            alt="TAKE AWAY"
                            style={{
                              margin: "auto",
                            }} 
                          />

                          <NormalText
                            color="black"
                            fontSize="36px"
                            fontWeight="bold"
                            fontFamily="D-DIN Exp"
                            margin="50px 0 30px 0"
                          >
                            TAKE AWAY
                          </NormalText>
                        </Stack>
                      </Box>
                    </Grid>
                  }

                  <Grid item xs={6} sm={6} md={6}>
                    <Box
                      onClick={() => {
                        localStorage.setItem(STORAGE_SERVICE_KEY, "PICKUP POINT");
                        navigate("/home");
                      }}
                      width={"100%"}
                      style={{ height: "564px", width: "422px" }}
                      alignItems={"center"}
                      justifyContent={"center"}
                      className="card-menu text-center"
                      display={"flex"}
                       margin={"auto"}
                    >
                      <Stack display={"column"}>
                        <img 
                          src={PickupIcon} 
                          alt="PICKUP POINT" 
                          style={{
                            margin: "auto",
                          }}
                        />

                        <NormalText
                          color="black"
                          fontSize="36px"
                          fontWeight="bold"
                          fontFamily="D-DIN Exp"
                          margin="50px 0 30px 0"
                        >
                          PICKUP POINT
                        </NormalText>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={ merchantType === "FOOD" ? 6 : 12}>
                    <Box
                      onClick={() => {
                        localStorage.setItem(STORAGE_SERVICE_KEY, "DELIVERY");
                        navigate("/home");
                      }}
                      width={"100%"}

                      style={{ height: "564px", width: "422px" }}
                      alignItems={"center"}
                      justifyContent={"center"}
                      className="card-menu text-center"
                      display={"flex"}
                      margin={"auto"}

                    >
                      <Stack display={"column"}
                        height={ "408px"}                                            >
                        <img 
                          src={DeliveryIcon} 
                          alt="DELIVERY"
                          style={{
                            margin: "auto",
                          }}
                        />

                        <NormalText
                          color="black"
                          fontSize="36px"
                          fontWeight="bold"
                          fontFamily="D-DIN Exp"
                          margin="50px 0 10px 0"
                          position="relative"
                        >
                          DELIVERY
                        </NormalText>
                      </Stack>
                    </Box>
                  </Grid>

              </Grid>
            </Box>

            <img
              src={SandBoxIcon}
              alt="logo"
              style={{ width: "298px", height: "230px", marginBottom: "-5%" }}
            />
          </div>
        </Box>
      </AppContainer>
    </MainLayout>
  );
};

export default PickServicePage;
