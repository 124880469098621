import React, { ReactNode } from "react";
import { 
  Box, 
  List, 
  ListItemButton, 
  ListItemText,
  Collapse
} from "@mui/material";
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import styled from 'styled-components';
import {
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';


// Import Redux
import { BrandsType } from "redux/brand";
import { CategoryInterface } from "redux/category";

export const Tab = styled(TabUnstyled)`
  color: #0A2942;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  background-color: transparent;
  width: 100%;
  height: 35px;
  padding: 10px 12px;
  border: none;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  margin: 0 -1px;

  &:focus {
    color: #0A2942;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #fff;
    color: #0A2942;
    font-weight: 700;
  }
`;

interface TabsListBg {
  background?: string;
}

export const TabsList = styled(TabsListUnstyled)<TabsListBg>`
  height: 35px;
  background-color: ${(props) => props.background};
  border-radius: 50px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.12);
  `
;

interface Props {
  children?: ReactNode;
  background?: string;
  brands?: BrandsType[];
  categories?: CategoryInterface[];
  storeInfo?: any;
  idActiveCategory?: number;
  onClickBrand: (props:any) =>  void;
  onClickCategory: (props:any) =>  void;
}

const style = {
  position: "absolute" as "absolute",
  top: "37%",
  left: "68%",
  minHeight: "13%",
  maxHeight: "53%",
  boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.12)",
  borderRadius: "20px",
  bgcolor: "white",
  width: "30%",
  padding:"21px 14px 5px 14px",
  outline: "unset!important",
  zIndex: "4",
  overflowX: "hidden",
};

const CategoryFilter = ({ children, background, brands, categories, storeInfo, idActiveCategory, onClickBrand, onClickCategory }: Props) => {
  const [openSub, setOpenSub] = React.useState();
  const [openSubs, setOpenSubs] = React.useState();

  const handleSub = (itemId: any) => {
    if (openSub !== itemId) {
      setOpenSub(itemId)
    } else {
      setOpenSub(undefined)
    }
  };

  const handleSubs = (itemId: any) => {
    if (openSubs !== itemId) {
      setOpenSubs(itemId)
    } else {
      setOpenSubs(undefined)
    }
  };

  return (
      <Box sx={style}>
        <TabsUnstyled defaultValue={0}>
          <TabsList background={background}>
            <Tab>Kategori</Tab>
            <Tab>Brand</Tab>
          </TabsList>

          {/* Category */}
          <TabPanelUnstyled value={0}>
            {/* Merchant Type Retail: Level 1 & Level 2 Category */}
            {storeInfo?.data?.data?.merchant_type === "RETAIL" &&
              categories &&
              categories.length &&
              categories.filter((category) => category.is_active === true && category.total_product !== 0 )
              // categories.filter((category) => category.is_active === true && category.total_product !== 0 && category.id === idActiveCategory)
              .map((item, index) => (                
                <List component="div" disablePadding>
                  <ListItemButton 
                    key={index}
                    sx={{
                      borderBottom: "1px solid #F1F1F1",
                      padding: "unset",
                      marginBottom: "15px!important",
                    }}
                  >
                    <ListItemText 
                      key={index}
                      primary={item.category_name} 
                      onClick={() => {
                        onClickCategory({
                          category_id: item.id,
                          sub_category_id: 0,
                          subs_category_id: 0,
                        })
                      }}
                    />
                  {item.subCategory &&
                    item.subCategory.length > 0 &&
                    item?.subCategory?.filter((subCategory) => subCategory.is_active === true ).length > 0
                    ? ( openSub === item.id 
                      ? <ExpandLessIcon onClick={() => handleSub(item.id)} />
                      : <ExpandMoreIcon onClick={() => handleSub(item.id)} />
                      )
                    : null
                  }
                  </ListItemButton>


                  {/* Category List Level 2 */}
                  <Collapse in={openSub === item.id} timeout="auto" unmountOnExit>
                  {item.subCategory?.filter((subCategory) => subCategory.is_active === true && subCategory.total_product !== 0)
                    .map((subItem, subIndex) => ( 
                      <>
                      <ListItemButton 
                        key={subIndex}
                        sx={{
                          borderBottom: "1px solid #F1F1F1",
                          paddingLeft: "20px!important",
                          padding: "unset",
                          marginBottom: "15px!important",
                        }}
                      >
                        <ListItemText 
                          key={subIndex}
                          primary={subItem.subcategory_name} 
                          onClick={() => {
                            onClickCategory({
                              category_id: item.id,
                              sub_category_id: subItem.id,
                              subs_category_id: 0,
                            })
                          }}
                        />
                        {subItem.subsCategory &&
                          subItem.subsCategory.length > 0 &&
                          subItem?.subsCategory?.filter((subsCategory) => subsCategory.is_active === true ).length > 0
                          ? ( openSubs === subItem.id 
                            ? <ExpandLessIcon onClick={() => handleSubs(subItem.id)} />
                            : <ExpandMoreIcon onClick={() => handleSubs(subItem.id)} />
                            )
                          : null
                        }
                      </ListItemButton>

                      {/* Category List Level 3 */}
                      <Collapse in={openSubs === subItem.id} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                        {subItem.subsCategory?.filter((subsCategory) => subsCategory.is_active === true && subsCategory.total_product !== 0 )
                          .map((subsItem, subsIndex) => (
                            <ListItemButton 
                              key={subsIndex}
                              sx={{
                                borderBottom: "1px solid #F1F1F1",
                                paddingLeft: "40px!important",
                                padding: "unset",
                                marginBottom: "15px!important",
                              }}
                            >
                              <ListItemText 
                                key={subsIndex}
                                primary={subsItem.subscategory_name} 
                                onClick={() => {
                                  onClickCategory({
                                    category_id: item.id,
                                    sub_category_id: subItem.id,
                                    subs_category_id: subsItem.id,
                                  })
                                }}
                              />
                            </ListItemButton>
                          ))
                        }
                        </List>
                      </Collapse>
                      </>
                    ))
                  }
                  </Collapse>
                </List>
              ))
            }
          </TabPanelUnstyled>

          {/* Brand */}
          <TabPanelUnstyled value={1}>
            <List
              sx={{ width: '100%', bgcolor: 'background.paper' }}
              component="nav"
            >
            
            {brands &&
              brands.length &&
              brands.filter((category) => category.is_active === true)
              .filter((data) => data.total_product !== 0 )
              .map((item, index) => (
                <ListItemButton 
                  key={index}
                  sx={{
                    borderBottom: "1px solid #F1F1F1",
                    padding: "unset",
                    marginBottom: "15px!important",
                  }}
                >
                  <ListItemText 
                    key={index} 
                    primary={item.brand_name} 
                    onClick={()=> {
                      onClickBrand({
                        category_id: 0,
                        sub_category_id: 0,
                        subs_category_id: 0,
                        brand_id: item.id ,
                      })
                    }}
                  />
                </ListItemButton>
              ))
            }
            </List>
          </TabPanelUnstyled>
        </TabsUnstyled>
      </Box>
  );
};

export default CategoryFilter;
