import React from "react";
import styled from "styled-components";
import { SpacerLine } from "components/styled/layout.styled";
import { NormalText } from "components/styled/text.styled";
import { Grid, Stack } from "@mui/material";
import { ProductAdditional } from "redux/cart/cart.reducer";
import { calculatePrice, calculatePriceMultipliedQty } from "views/Cart/cart.product.item";
import NoPhoto from 'asset/images/no-product-photo.svg'

interface CheckoutItemProps {
  name: string;
  price: number;
  additionals?: ProductAdditional[];
  image: string;
  qty: number;
  background?: any;
}

const CheckoutItem = ({
  name,
  price,
  additionals = [],
  image,
  qty,
  background,
}: CheckoutItemProps) => {
  const [imageError, setImageError] = React.useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <>
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={1} md={1} lg={1}>
            <NormalText
              fontFamily="Roboto Condensed"
              fontWeight="bold"
              fontSize={"27px"}
              color={"black"}
              margin={"35px 0 0 0"}
            >
              {qty}X
            </NormalText>
          </Grid>
          <Grid item xs={9} md={9} lg={9}>
            <Stack direction={"row"} spacing={3}>
              {imageError ? (
                <ProductImage src={NoPhoto} alt={`image_${name}`} />
              ) : (
                <ProductImage src={image} alt={`image_${name}`} onError={handleImageError} />
              )}
              <Stack direction={"column"}>
                <NormalText
                  fontFamily="Roboto Condensed"
                  fontWeight="bold"
                  fontSize={"28px"}
                  lineHeight={"33px"}
                  letterSpacing={"1px"}
                  style={{ textTransform: "uppercase" }}
                  margin={"0 0 10px 0"}
                >
                  {name}
                </NormalText>
                {additionals &&
                  additionals.length > 0 &&
                  additionals.map((addons, index) => (
                    <NormalText
                      key={"addons-" + index}
                      fontFamily="Roboto Condensed"
                      fontWeight="bold"
                      fontSize={"18px"}
                      color={"#777777"}
                      letterSpacing={"1px"}
                      style={{ textTransform: "uppercase" }}
                    >
                      + {addons.name}
                    </NormalText>
                  ))}
                <NormalText
                  fontFamily="D-DIN Exp"
                  fontWeight="bold"
                  fontSize={"24px"}
                  color={background ?? "#B92362"}
                  style={{ textTransform: "uppercase" }}
                  margin={"10px 0 0 0"}
                >
                  {calculatePrice({ price, additional: additionals })}
                </NormalText>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={2} md={2} lg={2}>
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"end"}
            >
              <NormalText
                fontFamily="Roboto Condensed"
                fontWeight="700"
                fontSize="32px"
                color={"black"}
                style={{ textTransform: "uppercase" }}
                margin={"25px 0 0 0"}
              >
                {calculatePriceMultipliedQty({ price, additional: additionals, qty })}
              </NormalText>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <SpacerLine 
        height="2px"
        style={{ 
          margin: "30px 0px"
        }}
      />
    </>
  );
};

const Container = styled.div`
  background: white;
  min-height: 120px;
  width: 100%;
  margin: 20px 0 10px 0;
`;

const ProductImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 8px;
`;

export default CheckoutItem;
