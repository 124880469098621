import { createAsyncThunk } from "@reduxjs/toolkit";
import { BrandState, BrandDetailState } from "./types";
import axios from "axios";

interface getAllBrand {
    merchant: number
    offset: number
    store_id: number
}

export const listAllBrand = createAsyncThunk('brand/list', async ({ merchant, offset, store_id}: getAllBrand) => {
    const config = {
        headers: {
            api_key: `${process.env.REACT_APP_API_KEY}`,
        }
    };
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/brand/${merchant}?offset=${offset}&store_id=${store_id}`,
            config
        );
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as BrandState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as BrandState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as BrandState;

    }
})


interface detailBrand {
    merchant_id: number
    store_id: number
    id: any
}

export const getDetailBrand = createAsyncThunk('brand/detail', async ({ merchant_id, store_id, id }: detailBrand) => {
    const config = {
        headers: {
            api_key: `${process.env.REACT_APP_API_KEY}`,
        }
    };
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/brand/${id}/detail?merchant_id=${merchant_id}&store_id=${store_id}`,
            config
        );
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as BrandDetailState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as BrandDetailState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as BrandDetailState;

    }
})

