import React from "react";
import { Box, Stack } from "@mui/material";
import Modal from "@mui/material/Modal";
import { NormalText } from "../styled/text.styled";
import Countdown from "react-countdown";
import { Button } from "../styled/button.styled";


interface Props {
  open: boolean;
  handleClose?: () => void;
  onShowModal?: () => void;
  description?: string;
  title?: string;
  confirmText?: string;
  cancelText?: string;
  titleStyle?: React.CSSProperties;
  descriptionStyle?: React.CSSProperties;
  handleConfirm: () => void;
  backgroundButton?: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  minHeight: 200,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 8,
};

const TimeoutModal = ({
  open,
  handleClose,
  confirmText,
  cancelText,
  backgroundButton,
  handleConfirm,
  onShowModal,
}: Props) => {

  const renderer = ({ seconds, completed }: { seconds: number, completed: boolean }) => {
    if (!completed) {
      return <span style={{ margin: '0 5px' }}>({seconds})</span>;
    } else {
      return <span></span>
    }
  };


  return (
    <Modal
      open={open}
      onClose={() => null}
      aria-labelledby="modal-modal-description"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <NormalText
          fontSize={"45px"}
          fontFamily={"Roboto Condensed"}
          textAlign="center"
        >
          APAKAH ANDA INGIN MELANJUTKAN PESANAN
        </NormalText>
        <Stack
          direction={"row"}
          spacing={2}
          margin={"40px 0 0 0"}
          justifyContent={"center"}
        >
          <Button
            background={backgroundButton ?? "#B92362"}
            style={{
              color: "white",
              fontSize: "12px",
              width: "300px",
              height: "100px",
            }}
            onClick={handleConfirm}
          >
            <NormalText
              fontSize="30px"
              fontFamily="Roboto Condensed"
              fontWeight="500"
            >
              {confirmText ?? "Lanjutkan"}
            </NormalText>
          </Button>
          <Button
            variant="secondary"
            style={{
              color: "white",
              fontSize: "12px",
              width: "300px",
              height: "100px",
            }}
            onClick={handleClose}
          >
            <NormalText
              fontSize="30px"
              fontFamily="Roboto Condensed"
              fontWeight="500"
            >
              {cancelText ?? "Batalkan"}

              <Countdown
                date={(Number(localStorage.getItem('COUNTDOWN_START')) ?? Date.now()) + Number(process.env.REACT_APP_TIMEOUT_AUTO_CANCEL ?? 0)}
                renderer={renderer}
              />
            </NormalText>
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default TimeoutModal;
