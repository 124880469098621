import { useEffect, useState } from "react";
import TimeoutModal from "../Modal/timeout";
import { addTimeout, removeTimeouts } from "redux/timeout/timeout.reducers";
import useRedux from "redux/useRedux";
import { useNavigate } from "react-router-dom";
import { resetCart } from "redux/cart/cart.reducer";
import { cancelOrder, resetCheckoutData } from "redux/checkout";

interface IIdleComponent {
  load?: boolean;
  paymentView?: boolean;
}

const IdleComponent = ({ load, paymentView }: IIdleComponent) => {
  const delayTimeOut = process.env.REACT_APP_TIMEOUT_MILLS
    ? Number(process.env.REACT_APP_TIMEOUT_MILLS)
    : 40000;
  const autoBatalTime = process.env.REACT_APP_TIMEOUT_AUTO_CANCEL
    ? Number(process.env.REACT_APP_TIMEOUT_AUTO_CANCEL)
    : 20000;

  const navigate = useNavigate();
  const {
    thunkDispatch,
    storeState: {
      Timeout: { data },
      Checkout,
      AppSetting,
    },
  } = useRedux();
  const [triggerShow, setTriggerShow] = useState(false);

  useEffect(() => {
    if (load) {
      handleTimeOut();
      // console.log("Trigerred");
    }
    removeAllState();
    // console.log("IdleComponent");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load]);

  if (load) {
    window.ontouchstart = async () => {
      await startTriggerTouch();
    };

    window.ontouchend = async () => {
      await startTriggerTouch();
    };

    window.onclick = async (event) => {
      event.stopPropagation();
      await startTriggerTouch();
    };
  }

  const setTimeoutToState = async (id: any) => {
    thunkDispatch(addTimeout(id))
      .unwrap()
      .then(() => null);
  };

  const removeAllState = async () => {
    thunkDispatch(removeTimeouts())
      .unwrap()
      .then(() => null);
  };

  const startTriggerTouch = async () => {
    data.forEach((item) => {
      clearTimeout(item);
    });
    await removeAllState();
    handleTimeOut();
  };

  const handleTimeOut = async () => {
    let timeout = setTimeout(() => {
      localStorage.setItem('COUNTDOWN_START', String(Date.now()))
      setTriggerShow(true);
      handleAutoBatal();
    }, delayTimeOut);
    await setTimeoutToState(timeout);
  };

  const handleLanjut = () => {
    startTriggerTouch();
    setTriggerShow(false);
  };

  const handleBatal = () => {
    if (
      paymentView &&
      Checkout &&
      Checkout.data &&
      Checkout.data.data &&
      Checkout.data.data.order_number
    ) {
      thunkDispatch(cancelOrder(Checkout.data.data.order_number))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            // OK
          }
        })
        .catch((err) => {
          console.log("ERR", err);
        });
    }
    thunkDispatch(resetCart())
      .unwrap()
      .then(() => {
        thunkDispatch(resetCheckoutData())
          .unwrap()
          .then(() => {
            navigate("/");
          });
      });
  };

  const handleAutoBatal = () => {
    let batal = setTimeout(() => {
      if (load) handleBatal();
    }, autoBatalTime);
    setTimeoutToState(batal);
  };

  return (
    <TimeoutModal
      handleConfirm={() => handleLanjut()}
      handleClose={() => handleBatal()}
      backgroundButton={
        AppSetting &&
          AppSetting.data &&
          AppSetting.data.data &&
          AppSetting.data.data.primary_color
          ? AppSetting.data.data.primary_color
          : "#FFC535"
      }
      open={triggerShow}
    ></TimeoutModal>
  );
};

export default IdleComponent;
