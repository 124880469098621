import MainLayout from "components/Layouts/MainLayout";
import { Box } from "@mui/material";
import { NormalText } from "components/styled/text.styled";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LogoPagiiOrdering from "asset/logo/pagii-ordering-logomark-default.svg"


const KiosNeedSetPage = () => {

  let navigate = useNavigate();
  return (
    <MainLayout noTimeout background="#FFE39D" title="Setting App | Kiosk Sandbox">
      <Box width={"100%"}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <img
            src={LogoPagiiOrdering}
            alt="logo"
            style={{
              width: "224px",
              height: "224px",
              marginTop: "50px",
              fill: "#ff0000",
              stroke: "#000000",
            }}
          />
          <NormalText
            fontSize="46px"
            fontWeight="bold"
            letterSpacing="3px"
            lineHeight="32px"
            color={"black"}
            textAlign="center"
            margin="100px 0 0 0"
          >
            YOUR KIOSK NEEDS TO BE SET
          </NormalText>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            marginTop: "150px",
          }}
        >
          <Button
            size="large"
            variant="contained"
            sx={{
              fontSize: "24px",
              fontWeight: "500",
            }}
            onClick={() => navigate("/config")}
          >
            Setting Now
          </Button>
        </Grid>
      </Box>
    </MainLayout>
  );
};

export default KiosNeedSetPage;
