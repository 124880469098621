import React from "react";
import styled from "styled-components";
import { Box, Stack } from "@mui/material";
import Modal from "@mui/material/Modal";
import { NormalText } from "../styled/text.styled";
import { Button } from "../styled/button.styled";
import CustomizableIcon from "asset/icons/ic_customizable.svg";
import { formatCurrency } from "helper/general";
import CounterButton from "components/Counter/counterBordered";
import NoPhoto from 'asset/images/no-product-photo.svg'

interface Props {
  open: boolean;
  handleCustom?: () => void;
  handleClose?: () => void;
  handleIncrease: () => void;
  handleDecrease: () => void;
  product_image?: string;
  description?: string;
  title?: string;
  price?: string;
  customizable?: boolean;
  confirmText?: string;
  cancelText?: string;
  titleStyle?: React.CSSProperties;
  handleConfirm: () => void;
  backgroundColor?: string;
  qty?: number;

}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 654,
  minHeight: 200,
  bgcolor: "background.paper",
  borderRadius: "40px",
  boxShadow: 24,
  p: 8,
};

const DetailMenuModal = ({
  open,
  handleCustom,
  handleClose,
  product_image,
  title,
  price,
  qty,
  description,
  confirmText,
  cancelText,
  titleStyle,
  customizable,
  backgroundColor,
  handleConfirm,
  handleIncrease,
  handleDecrease,
}: Props) => {
  const [imageError, setImageError] = React.useState(false);

  const handleImageError = () => {
    setImageError(true);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-description"
      aria-describedby="modal-modal-description"
    >
      <Box 
        sx={style} 
        width={"654px"}
      >
        {product_image && imageError ? (
          <ProductImage src={NoPhoto} alt={`image produk`} />
        ) : (
          <ProductImage src={product_image} alt={`image produk`} onError={handleImageError} />
        )}
        {title && (
          <NormalText
            style={titleStyle}
            fontSize={"24px"}
            fontFamily={"Roboto Condensed"}
            textAlign="center"
            fontWeight="700"
            margin="10px 0 0 0"
          >
            {title.toUpperCase()}
          </NormalText>
        )}
        {price && (
          <NormalText
            fontSize={"24px"}
            fontFamily={"Roboto Condensed"}
            textAlign="center"
            color={backgroundColor ?? "#FFC535" }
            fontWeight="700"
          >
            {formatCurrency(Number(price))}
            
          </NormalText>
        )}
        {customizable && (
          <Button
            onClick={handleCustom}
            background={"#FFFFFF"}
          >
            <img src={CustomizableIcon} alt="Customizable" />
          </Button>

        )}  
        {description && (
          <NormalText 
            fontSize={"24px"}
            fontFamily={"Roboto Condensed"}
            textAlign="center"
            color="#939393"
            margin="0 0 0 0"

          >
            {description}
          </NormalText>
        )} 

        <Box
          width={"100%"}
          sx={{ placeContent: "left" }}
          display={"flex"}
          justifyContent={"center"}
        >
          <CounterButton
            value={qty}
            background={backgroundColor ?? "#FFC535"}
            onIncrease={handleIncrease}
            onDecrease={handleDecrease}
          />
        </Box>
              
        <Stack
          direction={"row"}
          spacing={2}
          margin={"10px 0 0 0"}
          justifyContent={"center"}
        >
          <Button
            background={backgroundColor ?? "#FFC535"}
            style={{
              color: "white",
              fontSize: "24px",
              width: "600px",
              height: "61px",
            }}
            shadow={"0px 8px 10px rgba(0, 0, 0, 0.12)"}
            onClick={handleConfirm}
          >
            <NormalText
              fontSize="30px"
              fontFamily="Roboto Condensed"
              fontWeight="500"
            >
              {confirmText ?? "TAMBAHKAN KE PESANAN"}
            </NormalText>
          </Button>
        </Stack>

        <Stack
          direction={"row"}
          spacing={2}
          margin={"20px 0 0 0"}
          justifyContent={"center"}
        >
          <Button
            background={"#FFFFFF"}
            // variant="secondary"
            style={{
              color: "black",
              fontSize: "24px",
              width: "600px",
              height: "61px",
              border: `3px solid ${backgroundColor}`,
            }}
            shadow={"0px 8px 10px rgba(0, 0, 0, 0.12)"}
            onClick={handleClose}
          >
            <NormalText
              fontSize="30px"
              fontFamily="Roboto Condensed"
              fontWeight="500"
            >
              {cancelText ?? "LIHAT MENU LAINNYA"}
            </NormalText>
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

const ProductImage = styled.img`
  width: 200px;
  height: 200px;
  margin: auto;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default DetailMenuModal;
