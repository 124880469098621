import { ResponseProps, StoreInfoState } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { storeInfo } from './store.info.services';

export const initialState: StoreInfoState = {
    data: {} as ResponseProps,
    status: "loading",
    error: {}
}

export const storeInfoSlice = createSlice({
    name: 'stores',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(storeInfo.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(storeInfo.fulfilled, (state, action) => {
            if (action.payload) {
                state.data = action.payload.data;
                state.status = action.payload.status;
                state.error = action.payload.error
            }
        })
        .addCase(storeInfo.rejected, (state, action) => {
            state.status = 'error';
            state.error = action.error
        })

    }
})

export const StoreInfoReducer = storeInfoSlice.reducer