import React from "react";
import { Box, Stack, Grid } from "@mui/material";
import Modal from "@mui/material/Modal";
import { NormalText } from "../styled/text.styled";
import { Button } from "../styled/button.styled";
import { formatCurrency } from "helper/general";
import { SpacerLine } from "components/styled/layout.styled";
import CounterButton from "components/Counter/counterBordered";
import NoPhoto from 'asset/images/no-product-photo.svg'



interface Props {
  open: boolean;
  title?: string;
  price?: number;
  confirmText?: string;
  titleStyle?: React.CSSProperties;
  handleConfirm: () => void;
  handleIncrease: () => void;
  handleDecrease: () => void;
  handleClick: (index:number) => void;
  backgroundColor?: string;
  itemsCustom?: object[];
  additional?: Array<any>;
  value?: number;
  priceAdditional?: number;
}


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 654,
  minHeight: 200,
  bgcolor: "background.paper",
  borderRadius: "40px",
  boxShadow: 24,
  p: 8,
};

const DetailMenuCustomModal = ({
  open,
  title,
  price,
  confirmText,
  titleStyle,
  backgroundColor,
  handleConfirm,
  handleIncrease,
  handleDecrease,
  handleClick,
  itemsCustom,
  additional,
  value,
  priceAdditional = 0,
}: Props) => {
  // to get total price additional
  additional?.map(
    (add) => priceAdditional += Number(add.price) 
  )
  
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-description"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} 
        width={"654px"} 
        height={"808px"}
      >
        {title && (
          <NormalText
            style={titleStyle}
            fontSize={"24px"}
            fontFamily={"Roboto Condensed"}
            fontWeight="700"
            margin="-10px 0 0 0"
            // font-weight="700px"
          >
            {title.toUpperCase()}
          </NormalText>
        )}
          <NormalText
            style={titleStyle}
            fontSize={"20px"}
            fontFamily={"Roboto Condensed"}
            fontWeight="600"
            margin="10px 0 0 0"
          > ADDITIONAL
          </NormalText>

        <Grid container spacing={1} 
          height={"450px"}
        >
          {itemsCustom && 
            itemsCustom.map((addon:any, index: number) => {
              let active = additional?.some(
                (item) => Number(item?.id) === addon.product_assign_id
              )
              return(
              <>              
                <Grid
                  item
                  xs={4}
                  lg={4}
                  key={"product-" + index}
                  margin={"10px 15px 20px -15px "}
                  border={`3px solid ${(active ? backgroundColor : "#FFFFFF")}`}
                  borderRadius={"10%"}
                  boxShadow={"0px 0px 4px rgba(0, 0, 0, 0.06)"}
                  onClick={()=> handleClick(index)}
                  height={"180px"}
                >
                  <img 
                    alt={addon.product_name}
                    src={addon.product_image_main ? addon.product_image_main : NoPhoto } 
                    style={{
                      width:"100px",
                      height:"100px",
                      margin:"0 0 0 30px",
                      alignItems:"center",
                      alignContent:"center",
                      // objectFit:"cover",
                    }}
                  />
                  <NormalText
                    fontSize={"18px"}
                    fontFamily={"Roboto Condensed"}
                    textAlign="center"
                    color="black"
                    fontWeight="700"
                  >
                    {addon.product_name}
                  </NormalText>
                  <NormalText
                    fontSize={"18px"}
                    fontFamily={"Roboto Condensed"}
                    textAlign="center"
                    color={backgroundColor ?? "#FFC535"}
                    fontWeight="700"
                  >
                    {formatCurrency(Number(addon.product_price_after_discount ?? addon.product_price))}
                  </NormalText>
                </Grid>
              </>
              )
            })
          }
        </Grid>

        <SpacerLine height="1px"  />
        <Box
          width={"100%"}
          sx={{ placeContent: "left" }}
          display={"flex"}
        >
          <CounterButton
            value={value}
            background={backgroundColor ?? "#FFC535"}
            onIncrease={handleIncrease}
            onDecrease={handleDecrease}
          />
          {price && value && (
            <NormalText
              fontSize={"30px"}
              fontFamily={"Roboto Condensed"}
              textAlign="center"
              color={backgroundColor ?? "#FFC535"}
              fontWeight="700"
              margin="20px 0 0 250px"
            >
              {formatCurrency(Number(price * value + priceAdditional ))}
            </NormalText>

          )}
        </Box>
              
        <Stack
          direction={"row"}
          spacing={2}
          margin={"20px 0 0 0"}
          justifyContent={"center"}
        >
          <Button
            background={backgroundColor ?? "#FFC535"}
            style={{
              color: "white",
              fontSize: "24px",
              width: "600px",
              height: "61px",
            }}
            shadow={"0px 8px 10px rgba(0, 0, 0, 0.12)"}
            onClick={handleConfirm}
          >
            <NormalText
              fontSize="30px"
              fontFamily="Roboto Condensed"
              fontWeight="500"
            >
              {confirmText ?? "TAMBAHKAN KE PESANAN"}
            </NormalText>
          </Button>


        </Stack>
      </Box>
    </Modal>
  );
};


export default DetailMenuCustomModal;
