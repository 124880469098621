import React from "react";
import MainLayout from "components/Layouts/MainLayout";
import { Box, Button, Grid, ToggleButton } from "@mui/material";
// @ts-ignore
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import useRedux from "redux/useRedux";
import BrandItem from "./brand.item";
import ProductItem from "./product.item";
import { BannerType, listAllBanner } from "redux/banner";
import { getProductList, ProductListTypes, addOnProduct } from "redux/product";
import {
  listAllBrand,
  BrandsType,
  getDetailBrand,
  BrandsDetailType,
} from "redux/brand";
import CartSheet from "views/Cart/cart.sheet";
import { useNavigate } from "react-router-dom";
import {
  addToCart,
  ProductsTypes,
  changeQuantity,
  ChangeQuantityProps,
  ProductAdditional,
} from "redux/cart/cart.reducer";
import BannerLoading from "./banner.loading";
import BrandLoading from "./brand.loading";
import ProductLoading from "./product.loading";
import { NormalText } from "components/styled/text.styled";
import SingleBrandPage from "./home.single";
import { AppSettingData } from "redux/appsetting";
import ConfirmationModal from "components/Modal/confirmation";
import DetailMenuModal from "components/Modal/detailmenu";
import DetailMenuCustomModal from "components/Modal/detailMenuCustom";
import DetailMenuSuksesModal from "components/Modal/detailMenuSukses";
import { SmallDefaultBannerSandbox } from "asset/images";
import InputSearch from "components/Forms/InputSearch";
import useDebounce from "hooks/useDebounce";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import {
  Cancel as CancelIcon
} from "@mui/icons-material";
import {red} from "@mui/material/colors";
import CategoryFilter from "./category.filter";
import { 
  getCategoryList, 
  getProductAllCategory, 
  getDetailCategory, 
  CategoryInterface, 
  ProductCategoryListTypes 
} from "redux/category";
import NoPhoto from "asset/images/no-product-photo.svg";
import FilterIcon from "asset/icons/ic_filter.svg";



const LandingPage = () => {
  const {
    thunkDispatch,
    storeState: { StoreInfo, AppSetting, Cart },
  } = useRedux();
  let navigate = useNavigate();
  const [pageType, setPageType] = React.useState<"SINGLE" | "MULTIPLE">(
    "MULTIPLE"
  );
  const merchantType = StoreInfo?.data?.data?.merchant_type;
  const [banners, setBanners] = React.useState<BannerType[]>();
  const data: AppSettingData = AppSetting?.data?.data ?? null;
  const [brands, setBrands] = React.useState<BrandsType[]>();
  const [activeBrand, setActiveBrand] = React.useState(0);
  const [activeBrandName, setActiveBrandName] = React.useState("");
  const [brandDetail, setBrandDetail] = React.useState<BrandsDetailType>();
  const [activeCategory, setActiveCategory] = React.useState(0);
  const [products, setProducts] = React.useState<ProductListTypes[]>();
  const [bannerLoading, setBannerLoading] = React.useState(true);
  const [brandLoading, setBrandLoading] = React.useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [categoryLoading, setCategoryLoading] = React.useState(true);
  const [productLoading, setProductLoading] = React.useState(true);
  const [confirmation, setConfirmation] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const searchText = useDebounce({ value: search, delay: 500 });
  const [listProduct, setListProduct] = React.useState<ProductCategoryListTypes[]>();
  const [idProduct, setIdProduct] = React.useState(0);
  const [addons, setAddons] = React.useState<any>();
  const [custom, setCustom] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalSukses, setModalSukses] = React.useState(false);
  const [qty, setQty] = React.useState(1);
  const [additional, setAdditional] = React.useState<ProductAdditional[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [addonsLoading, setAddonsLoading] = React.useState(false);
  const keyboard = React.useRef<any>(null);
  const [showKeyboard, setShowKeyboard] = React.useState(false);
  const [input, setInput] = React.useState("");
  const [layout, setLayout] = React.useState("default");
  const [selectCategory, setSelectCategory] = React.useState(false);
  const [categories, setCategories] = React.useState<CategoryInterface[]>();
  const [idCategory, setIdCategory] = React.useState(0);
  const [idSubCategory, setIdSubCategory] = React.useState(0);
  const [idSubsCategory, setIdSubsCategory] = React.useState(0);
  const [idBrand, setIdBrand] = React.useState(0);


  const slideProperties = {
    indicators: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    duration: 4000,
    canSwipe: true,
  };

  const productExistOnCart = (brand_id: number, product_id: number) => {
    let brand = Cart.items.find((b) => b.brand_id === brand_id);
    if (brand) {
      let product = brand.products.find((p) => p.product_id === product_id);

      if (product) {
        return {
          qty: product.qty,
          cart_id: product.cart_id,
        };
      }
    }
    
    return {
      qty: 0,
      cart_id: "",
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const reduceQuantity = (props: ChangeQuantityProps) => {
    thunkDispatch(changeQuantity(props))
      .unwrap()
      .then(() => null);
  };

  const fetchBrandDetail = (id: number) => {
    setCategoryLoading(true);
    setProductLoading(true);
    if (StoreInfo && StoreInfo.status === "success") {
      thunkDispatch(
        getDetailBrand({
          id: id,
          merchant_id: StoreInfo.data.data.merchant_id,
          store_id: StoreInfo.data.data.id,
        })
      )
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            let productCategory = res.data.data.category.find(
              (x: any) => x.products !== null
            );
            setCategoryLoading(false);
            if (productCategory) {
              setActiveCategory(productCategory.id);
              thunkDispatch(
                getProductList({
                  brand_id: id,
                  merchant_id: StoreInfo.data.data.merchant_id,
                  category_id: productCategory.id,
                  store_id: StoreInfo.data.data.id,
                })
              )
                .unwrap()
                .then((res) => {
                  if (res && res.status === "success") {
                    setProductLoading(false);
                    setCategoryLoading(false);
                    setProducts(res.data.data);
                  }
                });
              setActiveCategory(productCategory.id);
            } else {
              setProducts([]);
              setProductLoading(false);
            }
            setBrandDetail(res.data.data);
          } else {
            setProductLoading(false);
          }
        });
    } else {
      navigate("/set");
    }
  };

  const handleCategoryChange = (id: number) => {
    if (StoreInfo && StoreInfo.status === "success") {
      setActiveCategory(id);
      setProductLoading(true);
      thunkDispatch(
        getProductList({
          brand_id: activeBrand,
          merchant_id: StoreInfo.data.data.merchant_id,
          category_id: id,
          store_id: StoreInfo.data.data.id,
        })
      )
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            setProductLoading(false);
            setProducts(res.data.data);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      navigate("/set");
    }
  };

  const handleAddToCart = (params: ProductsTypes) => {
    thunkDispatch(addToCart(params))
      .unwrap()
      .then(() => null);
  };

  const handleAddToCarts = () => {
    if( listProduct && idProduct) {
        // eslint-disable-next-line array-callback-return
        listProduct.map((product) => {
          if(product.product_id === idProduct){
            let cartPayload: ProductsTypes = {
              price:
                (Number(product?.product_detail.product_price_after_discount) > 0
                  ? product?.product_detail.product_price_after_discount
                  : product?.product_detail.product_price) ?? "",
              additionals: additional,
              qty: qty,
              is_customizable: product.product_detail.is_customizable,
              product_id: product?.product_id,
              product_image: product.product_detail.product_image_main,
              product_name: product.product_name,
              brand_id: product.brand.brand_id,
              brand_name: product.brand.brand_name,
            };
            thunkDispatch(addToCart(cartPayload))
              .unwrap()
              .then(() => {
                // history.back();
              });
            }
        })
    }
  };

  React.useEffect(() => {
    setBannerLoading(true);
    if (StoreInfo && StoreInfo.status === "success") {
      thunkDispatch(listAllBanner(StoreInfo.data.data.merchant_id))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            setBannerLoading(false);
            setBanners(res.data.data);
          }
        });
      setBrandLoading(true);
      thunkDispatch(
        listAllBrand({ 
          merchant : StoreInfo.data.data.merchant_id,
          store_id : StoreInfo.data.data.id,
          offset: 0
         })
      )
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            if (res.data.data.length > 0) {
              setPageType(res.data.data.length === 1 ? "SINGLE" : "MULTIPLE");
              setBrandLoading(false);
              fetchBrandDetail(res.data.data[0].id);
              setActiveBrand(res.data.data[0].id);
              setActiveBrandName(res.data.data[0].brand_name);
            }
            setBrands(res.data.data);
          }
        });
    } else {
      navigate("/set");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (
      StoreInfo &&
      StoreInfo.data &&
      StoreInfo.data.data &&
      StoreInfo.data.data.merchant_id
    ){
      thunkDispatch(
        getCategoryList({
          merchant_id: StoreInfo.data.data.merchant_id,
          store_id: StoreInfo.data.data.id,
        })
      )
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            setCategories(res?.data?.data ?? []);
          }
      });
      thunkDispatch(
        getProductAllCategory({
          merchant_id: StoreInfo.data.data.merchant_id,
          store_id: StoreInfo.data.data.id,
          keyword: search.toLowerCase(),
        })
      )
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            setListProduct(res?.data?.data ?? []);
          }
      });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, thunkDispatch]);

  React.useEffect(() => {
    if (searchText) { 
      fetchProduct([]);
    }
  }, [searchText]); // eslint-disable-line

  const getAddOn = () => {
    if (
      (StoreInfo &&
        StoreInfo.data &&
        StoreInfo.data.data &&
        StoreInfo.data.data.id) ||
      StoreInfo.data.data.merchant_id
    ) {
      let data = {
        product_id: idProduct,
        merchant_id: StoreInfo.data.data.merchant_id,
        store_id: StoreInfo.data.data.id,
      };
      setAddonsLoading(true);
      thunkDispatch(addOnProduct(data))
        .unwrap()
        .then((res: any) => {
          if (res && res.status === "success") {
            if (
              res.data.data &&
              res.data.data.productAddOn &&
              res.data.data.productAddOn.length !== 0
            ) {
              setAddonsLoading(false);
              setAddons(res.data.data.productAddOn);
            } else {
              setAddonsLoading(false);
            }
          } else {
            setAddonsLoading(false);
          }
        });
    }
  };

  const showVirtualKeyboard = () => {
    setShowKeyboard(true)
  }
  const hideVirtualKeyboard = () => {
    setShowKeyboard(false)
  }

  const onChange = (input: any) => {
    setInput(input);
    setSearch(input);
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  const onKeyPress = (button: any) => { 
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const onChangeInput = (event:any) => {
    const input = event.target.value;
    setInput(input);
    setSearch(input);
    keyboard.current.setInput(input);
  };

  // get data product filter by category and filter by searching
  const fetchProduct = (props: any) => {
    setProductLoading(true);
    // reset or set new value Id Category 
    setIdCategory( props.category_id === 0 
      ? props.category_id
      : props.category_id ? props.category_id : idCategory
    )

    setIdBrand(props.brand_id === 0
      ? props.brand_id
      : props.brand_id ? props.brand_id : idBrand
    )
    thunkDispatch(
      getProductAllCategory({
        merchant_id     : StoreInfo.data.data.merchant_id,
        store_id        : StoreInfo.data.data.id,
        keyword         : props.keyword ?? search.toLowerCase(),
      })
    )
      .unwrap() 
      .then((res) => {
        if (res && res.status === "success") {
          setProductLoading(false); 
          setListProduct(res.data.data);
        }
    });
  }

  const ProductCategory = (props: any) => {
    setProductLoading(true);
    // reset or set new value Id Category, Id Sub Category, Id Subs Category 
    setIdCategory( props.category_id === 0 
      ? props.category_id
      : props.category_id ? props.category_id : idCategory
    )
    setIdSubCategory(props.sub_category_id === 0 
      ? props.sub_category_id
      : props.sub_category_id ? props.sub_category_id : idSubCategory
    )
    setIdSubsCategory(props.subs_category_id === 0
      ? props.subs_category_id 
      : props.subs_category_id ? props.subs_category_id : idSubsCategory
    )
    setIdBrand(props.brand_id === 0
      ? props.brand_id
      : props.brand_id ? props.brand_id : idBrand
    )
    thunkDispatch(
      getDetailCategory({
        merchant_id     : StoreInfo.data.data.merchant_id,
        store_id        : StoreInfo.data.data.id,
        category_id     : props.category_id ?? idCategory,
        sub_category_id : props.sub_category_id ?? idSubCategory,
        subs_category_id: props.subs_category_id ?? idSubsCategory,
        keyword         : props.keyword ?? search.toLowerCase(),
      })
    )
      .unwrap() 
      .then((res) => {
        if (res && res.status === "success") {
          setProductLoading(false); 
          setListProduct(res.data.data);
        }
    });
  }

  return (
    <MainLayout background="#fff" title="Home | Kiosk Sandbox">
      {/* show virtual keyboard */}
      { showKeyboard && (
        <div
          style={{
            top: "71%",
            position: "fixed",
            bottom: "0%",
            width: "100%",
            zIndex: 4,
          }}
        >
          <Box marginLeft={"91%"} marginBottom={"3%"} >
            <Button onClick={hideVirtualKeyboard}>
              <CancelIcon sx={{ color: red[500], fontSize: 70 }}/>
            </Button>
          </Box>
          <Keyboard
            keyboardRef={r => (keyboard.current = r)}
            layoutName={layout}
            onChange={onChange}
            onKeyPress={onKeyPress}
            onInit={()=> onChange("")}
          />        
        </div>
      )}
      {/* Display category to choose from */}
      { selectCategory && (
        <CategoryFilter
           background={data?.primary_color ? data.primary_color + "70" : "#FFEE8E"}
           brands={brands}
           categories={categories}
           storeInfo={StoreInfo}
           idActiveCategory={idCategory}
           onClickBrand={(props:any) => fetchProduct(props)}
           onClickCategory={(props:any) => ProductCategory(props)}
        />
      )}
      <ConfirmationModal
        handleClose={() => setConfirmation(false)}
        handleConfirm={() => setConfirmation(false)}
        backgroundButton={data?.primary_color ?? "#FFC535"}
        title={"Apakah anda yakin ingin menghapus item dari keranjang?"}
        open={confirmation}
      />

      {/* Show modal sukses ditambahkan */}
      { idProduct !== 0 &&
        modalSukses &&
        listProduct &&
        listProduct.map((product, index) => (
          product.product_id === idProduct 
          ?
            <DetailMenuSuksesModal
              title={product.product_name}
              // price={product.product_price}
              qty={qty}
              price={
                Number(product.product_detail.product_price_after_discount) > 0
                  ? Number(product.product_detail.product_price_after_discount)
                  : Number(product.product_detail.product_price)
              }
              customizable={product.product_detail.is_customizable}
              additional={additional}
              product_image={product.product_detail.product_image_main.length === 0 
                ? NoPhoto 
                : product.product_detail.product_image_main
              }
              handleConfirm={() => {
                getAddOn();
                setCustom(true);
                setModalSukses(false);
                setQty(1);
                setAdditional([]);
              } }
              backgroundColor={data?.primary_color ?? "#FFC535"}
              open={modalSukses}
              handleClose={() => {
                setIdProduct(0);
                setQty(1);
                setAdditional([]);
              } }           
            /> 
          : ""
        )) 
      }

      {/* Show modal detail menu */}
      { idProduct !== 0 &&
        listProduct && 
        listProduct.map((product, index) => (
          product.product_id === idProduct 
          ?
            <DetailMenuModal
              title={product.product_name}
              price={
                Number(product.product_detail.product_price_after_discount) > 0
                  ? product.product_detail.product_price_after_discount
                  : product.product_detail.product_price
              }
              qty={qty}
              description={product.short_description}
              customizable={product.product_detail.is_customizable}
              handleCustom={() => {
                  setCustom(true);
                  getAddOn();
                }
              } 
              product_image={product.product_detail.product_image_main.length === 0 
                ? NoPhoto 
                : product.product_detail.product_image_main
              }
              handleConfirm={() => {
                setOpen(false);
                handleAddToCarts();
                setModalSukses(true)
              } }
              backgroundColor={data?.primary_color ?? "#FFC535"}
              open={open}
              handleIncrease={() => setQty(qty + 1)}
              handleDecrease={() => {
                if (qty === 1) {
                  setQty(1);
                } else {
                  setQty(qty - 1);
                }
              }}
              handleClose={() => {
                setIdProduct(0);
                setOpen(false);
                setQty(1)
              } }           
            /> 
          : ""
        ))  
      }
      
      {/* Show modal customize detail */}
      { idProduct !==0 && 
        custom && 
        addons &&
        listProduct &&
        listProduct.map((product, index) => (
          product.product_id === idProduct 
          ?
            <DetailMenuCustomModal
              title={"Customize Your " + product.product_name}
              itemsCustom={addons}
              value={qty}
              backgroundColor={data?.primary_color ?? "#FFC535"}
              price={
                product.product_detail.product_price_after_discount
                  ? Number(product.product_detail.product_price_after_discount)
                  : Number(product.product_detail.product_price)
              }
              additional={additional}
              handleIncrease={() => setQty(qty + 1)}
              handleDecrease={() => {
                if (qty === 1) {
                  setQty(1);
                } else {
                  setQty(qty - 1);
                }
              }}
              handleConfirm={() => {
                setOpen(false);
                setCustom(false);
                handleAddToCarts();
                setModalSukses(true)

              } }
              open={custom} 
              handleClick={(index: number) => {
                // setting selected additional 
                let productResult = additional.find(
                  (item) => Number(item.id) === addons[index].product_assign_id
                );
                let temp = [...additional];
                if (!productResult) {
                  temp.push({
                    id: addons[index].product_assign_id,
                    name: addons[index].product_name,
                    price: addons[index].product_price_after_discount
                      ? addons[index].product_price_after_discount
                      : addons[index].product_price,
                    qty: 1,
                    product_id: addons[index].product_id,
                  });
                  setAdditional(temp);
                } else {
                  let t = [...additional];
                  let productIndex = additional.findIndex(
                    (item) =>
                      Number(item.id) === addons[index].product_assign_id
                  );
                  t.splice(productIndex, 1);
                  setAdditional(t);
                }
              }}         
            /> 
          : ""
        ))  
      }

      {!bannerLoading ? (
        banners && banners.length > 0 ? (
          <Slide {...slideProperties}>
            {banners.map((banner, index) => (
              <div key={"banner-" + index}>
                <img
                  key={index}
                  style={{ height: "300px", width: "100%", objectFit: "cover"}}
                  src={banner.banner_image_web}
                  alt={banner.banner_name}
                />
              </div>
            ))}
          </Slide>
        ) : (
          <Slide {...slideProperties}>
            <div>
              <img
                style={{ height: "300px", width: "100%", objectFit: "cover" }}
                src={SmallDefaultBannerSandbox}
                alt={"Small Default Banner"}
              />
            </div>
          </Slide>
        )
      ) : (
        <BannerLoading />
      )}
      {/* Start Content */}
      {pageType === "SINGLE" ? (
        <SingleBrandPage
          brandLoading={brandLoading}
          brands={brandDetail ?? ({} as unknown as BrandsDetailType)}
          products={products ?? []}
          activeCategory={activeCategory}
          activeBrand={activeBrand}
          activeBrandName={activeBrandName}
          productLoading={productLoading}
          primaryColor={data?.primary_color ?? "#FFC535"}
          secondaryColor={data?.secondary_color ?? "#000"}
          categoryClick={(id: number) => handleCategoryChange(id)}
          addToCartAction={(params: ProductsTypes) => {
            params.is_customizable
              ? navigate(`/product/${params.product_id}/detail`)
              : handleAddToCart(params);
          }}
        />
      ) : (
        <>
          <Box width={"100%"} overflow="none">
            <Grid container>
              <Grid item xs={3.5} md={3.5} lg={3.5}></Grid>
              <Grid item xs={8.5} md={8.5} lg={8.5}>
              </Grid>
            </Grid>
          </Box>
          <Grid container style={{ paddingBottom: "150px" , paddingTop: "20px"}}>
            <Grid item xs={3.5} md={3.5} lg={3.5}>
              <Box
                width={"100%"}
                sx={{ background: "#F9F9F9" }}
                overflow="auto"
                height={"calc(100vh - 360px)"}
                flexDirection="column"
                display="flex"
              >
                {/* {!brandLoading ? (
                  brands &&
                  brands.length &&
                  brands.map((item, index) => (
                    <BrandItem
                      active={activeBrand === item.id}
                      key={"brand" + index}
                      image={item.icon_web}
                      name={item.brand_name}
                      backgroundColor={"rgb(210 210 210)"}
                      onClick={() => {
                        setActiveBrand(item.id);
                        setActiveBrandName(item.brand_name);
                        fetchBrandDetail(item.id);
                      }}
                    />
                  ))
                ) : (
                  <BrandLoading />
                )} */}

                {/* show category level 1 */}
                {!brandLoading ? (
                  categories &&
                  categories.length &&
                  categories.filter((category) => category.is_active === true && category.total_product !== 0)
                  .map((item, index) => (
                    // show category when total product of category is not null 
                      <BrandItem
                        active={idCategory === item.id ? true : false}
                        backgroundActive={data?.primary_color ?? "#FFC535"} 
                        key={"category" + index}
                        image={item.image_mobile.length === 0
                          ? NoPhoto
                          : item.image_mobile
                        }
                        name={item.category_name}
                        backgroundColor={"rgb(210 210 210)"}
                        onClick={() => {
                          setIdCategory(item.id);
                          ProductCategory({
                            category_id: item.id,
                            sub_category_id: 0,
                            subs_category_id: 0,
                            brand_id: 0,
                          });
                        }}                      
                      />
                  ))
                ) : (
                  <BrandLoading />
                )}
              </Box>
            </Grid>

            <Grid item xs={8.5} md={8.5} lg={8.5}>
              {/* { merchantType === "RETAIL" &&
                search &&
                search.length > 0 &&
                <Box 
                  marginLeft={"3%"} 
                  marginTop={"7%"} 
                  borderRadius={"20px"}
                  bgcolor={data?.primary_color ? data.primary_color + "40" : "#FFEE8E"}
                  position={"absolute"}
                  padding={"9px 10px 9px 20px"}
                  boxShadow={"0px 8px 10px rgba(0, 0, 0, 0.12)"}
                  width={"300px"}
                >
                  {search}
                  <CancelIcon 
                    sx={{ color: red[500] }}
                    onClick={() => {
                      setInput("");
                      setSearch("");
                      if(idCategory !== 0){
                        ProductCategory({
                          category_id: idCategory,
                          keyword: "",
                        })
                      }else{
                        fetchProduct({
                          category_id: idCategory,
                          sub_category_id: 0,
                          subs_category_id: 0,
                          keyword: "",
                        });
                      }
                      hideVirtualKeyboard();
                    }}
                    style={{
                      marginLeft:"40px",
                    }}
                  />                        
                </Box>
              } */}

              <InputSearch
                  placeholder="Cari produk pilihanmu"
                  value={input}
                  backgroundColor={data?.primary_color ?? "#FFC535" }
                  onChange={onChangeInput}
                  onFocus={showVirtualKeyboard}
                  onClick={() => {
                    setInput("");
                    setSearch("");
                    fetchProduct({
                      category_id: 0,
                      sub_category_id: 0,
                      subs_category_id: 0,
                      brand_id: 0,
                      keyword: "",
                    });
                    hideVirtualKeyboard();
                  }}
              />

              { merchantType === "RETAIL" &&
                <Box 
                  marginLeft={"30px"} 
                  marginBottom={"20px"} 
                  width={"93%"} 
                  height={"60px"}
                  boxShadow={"0px 8px 10px rgba(0, 0, 0, 0.12)"}
                  borderRadius={"10px"}
                  bgcolor={data?.primary_color ? data.primary_color + "50" : "#FFEE8E"}
                >
                  <ToggleButton
                    value="check"
                    fullWidth={true} 
                    onChange={() => {
                      setSelectCategory(!selectCategory);
                    }}
                    style={{
                      border:"none",
                      borderRadius:"20px",
                      fontWeight: "400",
                      color: "black",
                      width: "100%",
                      fontSize: "23px",
                      textTransform: "capitalize"
                    }}
                  >
                    Filter
                      <img src={FilterIcon} alt="Filter" style={{marginLeft: "13px"}} />
                  </ToggleButton>
                </Box>
              }

              <Box
                width={"100%"}
                padding={"5px"}
                overflow="auto"
                sx={{ overflowX: "hidden" }}
                height={"calc(100vh - 500px)"}
                flexDirection="column"
                display="flex"
                marginBottom={"150px"}
              >
                
                <Grid container spacing={2}>
                  {!productLoading ? (
                    listProduct && listProduct.length > 0 ? (
                      listProduct
                        .filter((product) => idBrand ? product.brand.brand_id === idBrand : product.brand.brand_id !== 0 )
                        .sort((a,b) => b.product_id - a.product_id)
                        .sort((a,b) => {
                          if (a.productStore === null) {
                            return 1;
                          }
                        
                          if (b.productStore === null) {
                            return -1;
                          }
                        
                          if (a.productStore === b.productStore) {
                            return 0;
                          }
                        
                          return a.product_id < b.product_id ? 1 : -1;
                        })
                        .map((product, index) => (
                        <Grid
                          item
                          xs={4}
                          md={4}
                          sm={4}
                          lg={4}
                          key={"product-" + index}
                        >
                          <ProductItem
                            name={product.product_name ?? ""}
                            image={ product.product_detail.product_image_main.length === 0 
                                ? NoPhoto 
                                : product.product_detail.product_image_main
                            }
                            price={
                              Number(product.product_detail.product_price_after_discount)
                                ? Number(product.product_detail.product_price_after_discount)
                                : Number(product.product_detail.product_price)
                            }
                            backgroundColor={data?.primary_color ?? "#FFC535"}
                            addToCartAction={() => 
                              (
                                // eslint-disable-next-line no-sequences
                                setOpen(true),
                                setIdProduct(product.product_id)
                              )}
                              // product.product_detail.is_customizable
                              //   ? (
                              //     setOpen(true),
                              //     setIdProduct(product.product_id)
                              //     ) 
                              //   : handleAddToCart({
                              //     price: product.product_detail.product_price_after_discount
                              //       ? product.product_detail.product_price_after_discount
                              //       : product.product_detail.product_price,
                              //     product_id: product.product_id,
                              //     qty: 1,
                              //     is_customizable: product.product_detail.is_customizable,
                              //     product_name: product.product_name,
                              //     product_image: product.product_detail.product_image_main,
                              //     brand_name: product.brand.brand_name,
                              //     brand_id: product.brand.id,
                              //     additionals: [],
                              //   })
                            
                            onChangeDetail={() =>
                              (
                                // eslint-disable-next-line no-sequences
                                setOpen(true),
                                setIdProduct(product.product_id)
                              )
                            }
                            customizable={product.product_detail.is_customizable}
                            isCartExist={
                              productExistOnCart(
                                activeBrand,
                                product.product_id
                              ).cart_id !== ""
                                ? true
                                : false
                            }
                            // counterProps={{
                            //   value:
                            //     productExistOnCart(
                            //       activeBrand,
                            //       product.product_id
                            //     )?.qty ?? 0,
                            //   background: data?.primary_color ?? "#B92362",
                            //   onDecrease: () => {
                            //     let quantity = productExistOnCart(
                            //       activeBrand,
                            //       product.product_id
                            //     );

                            //     if (quantity && quantity.qty === 1) {
                            //       setReducePayload({
                            //         brand_id: activeBrand,
                            //         type: "decrease",
                            //         cart_id:
                            //           productExistOnCart(
                            //             activeBrand,
                            //             product.product_id
                            //           )?.cart_id ?? "",
                            //       });

                            //       setConfirmation(true);
                            //     } else {
                            //       reduceQuantity({
                            //         brand_id: activeBrand,
                            //         type: "decrease",
                            //         cart_id:
                            //           productExistOnCart(
                            //             activeBrand,
                            //             product.product_id
                            //           )?.cart_id ?? "",
                            //       });
                            //     }
                            //   },
                            //   onIncrease: () => {
                            //     reduceQuantity({
                            //       brand_id: activeBrand,
                            //       type: "increase",
                            //       cart_id:
                            //         productExistOnCart(
                            //           activeBrand,
                            //           product.product_id
                            //         )?.cart_id ?? "",
                            //     });
                            //   },
                            // }}
                          />
                        </Grid>
                        
                      ))
                    ) : (
                      <Box width={"100%"}>
                          <>
                            <NormalText
                              fontSize={"32px"}
                              textAlign={"center"}
                              margin={"100px 0 0 0"}
                            >
                              Oops, Belum ada produk
                            </NormalText>
                            <NormalText
                              fontSize={"25px"}
                              textAlign={"center"}
                            >
                              Tidak ada produk ditemukan{" "}
                              {searchText ? `dengan kata kunci "${searchText}"` : ""}
                            </NormalText>   
                          </>   
                      </Box>
                    )
                  ) : (
                    <ProductLoading />
                  )}
                  
                </Grid>
              </Box>
              
            </Grid>
          </Grid>
        </>
      )}
      {/* End Content */}
      <CartSheet
        summaries
        onClick={() => navigate("/cart")}
        backgroundColor={data?.primary_color ?? "#FFC535"}
      />
    </MainLayout>
  );
};


export default LandingPage;

