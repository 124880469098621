import React from "react";
import MainLayout from "components/Layouts/MainLayout";
import { NormalText } from "../../components/styled/text.styled";
import { Box, Grid, Skeleton, Stack } from "@mui/material";
import BackButton from "components/Button/back.button";
import { useParams } from "react-router-dom";
import useRedux from "../../../src/redux/useRedux";
import { detailProduct, ProductsDetail, addOnProduct } from "redux/product";
import BottomSheet, { ChekoutButton } from "components/BottomSheet";
import CounterButton from "components/Counter/counter";
import {
  addToCart,
  ProductAdditional,
  ProductsTypes,
} from "redux/cart/cart.reducer";
import history from "helper/history";
import ProductItem from "./product.item";
import { AppSettingData } from "redux/appsetting";

const ProductDetail = () => {
  const [addons, setAddons] = React.useState<any>();
  const [product, setProduct] = React.useState<ProductsDetail>();
  const [addonsLoading, setAddonsLoading] = React.useState(false);
  const { productId } = useParams();
  const [qty, setQty] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [additional, setAdditional] = React.useState<ProductAdditional[]>([]);

  const {
    thunkDispatch,
    storeState: { StoreInfo, AppSetting },
  } = useRedux();

  const data: AppSettingData = AppSetting?.data?.data ?? null;

  React.useState(() => {
    if (
      (StoreInfo &&
        StoreInfo.data &&
        StoreInfo.data.data &&
        StoreInfo.data.data.id) ||
      StoreInfo.data.data.merchant_id
    ) {
      let data = {
        product_id: productId,
        merchant_id: StoreInfo.data.data.merchant_id,
        store_id: StoreInfo.data.data.id,
      };
      setLoading(true);
      thunkDispatch(detailProduct(data))
        .unwrap()
        .then((res: any) => {
          if (res && res.status === "success") {
            setLoading(false);
            setProduct(res.data.data);
            if (res.data.data?.product_detail?.is_customizable) {
              getAddOn();
            }
          }
        });
    }
  });

  const handleAddToCart = () => {
    if (product) {
      let cartPayload: ProductsTypes = {
        price:
          (product?.product_detail?.product_price_after_discount
            ? product?.product_detail?.product_price_after_discount
            : product?.product_detail?.product_price) ?? "",
        additionals: additional,
        qty: qty,
        is_customizable: product.product_detail.is_customizable,
        product_id: product?.product_id,
        product_image: product.product_detail.product_image_main,
        product_name: product.product_name,
        brand_id: product.brand.id,
        brand_name: product.brand.brand_name,
      };
      thunkDispatch(addToCart(cartPayload))
        .unwrap()
        .then(() => {
          history.back();
        });
    }
  };

  const getAddOn = () => {
    if (
      (StoreInfo &&
        StoreInfo.data &&
        StoreInfo.data.data &&
        StoreInfo.data.data.id) ||
      StoreInfo.data.data.merchant_id
    ) {
      let data = {
        product_id: productId,
        merchant_id: StoreInfo.data.data.merchant_id,
        store_id: StoreInfo.data.data.id,
      };
      setLoading(true);
      setAddonsLoading(true);
      thunkDispatch(addOnProduct(data))
        .unwrap()
        .then((res: any) => {
          if (res && res.status === "success") {
            setLoading(false);
            if (
              res.data.data &&
              res.data.data.productAddOn &&
              res.data.data.productAddOn.length !== 0
            ) {
              setAddonsLoading(false);
              setAddons(res.data.data.productAddOn);
            } else {
              setAddonsLoading(false);
            }
          } else {
            setAddonsLoading(false);
          }
        });
    }
  };
  return (
    <MainLayout>
      <Box
        style={{
          background: data?.primary_color ?? "#FFC535",
        }}
        padding={"45px"}
      >
        <BackButton />
        <Box
          style={{
            height: "656px",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Box
            padding={"10px 20px 10px 20px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              {!loading ? (
                <>
                  <img
                    style={{
                      height: "352px",
                      width: "352px",
                      borderRadius: "10px",
                    }}
                    src={product?.product_detail?.product_image_main ?? ""}
                    alt={"logo product"}
                  />
                  <NormalText
                    fontSize={"48px"}
                    fontFamily="D-DIN Exp"
                    fontWeight="bold"
                    textAlign={"center"}
                    color={"#fff"}
                    margin={"20px 0 0 0"}
                    style={{ textTransform: "uppercase" }}
                  >
                    {product?.product_name ?? ""}
                  </NormalText>
                  <NormalText
                    fontSize={"24px"}
                    fontFamily="D-DIN Exp"
                    textAlign={"center"}
                    color={"#fff"}
                  >
                    {product?.product_description?.short_description ?? ""}
                  </NormalText>
                </>
              ) : (
                <>
                  <Skeleton
                    variant="rectangular"
                    width={352}
                    height={352}
                    sx={{ borderRadius: "20px", margin: "0 0 20px 0" }}
                  />
                  <Skeleton variant="text" height={50} width={400} />
                  <Skeleton variant="text" height={50} width={600} />
                </>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box padding={"10px 50px"} width={"100%"}>
        {product &&
          product.product_detail &&
          product.product_detail.is_customizable && (
            <NormalText
              fontSize={"32px"}
              color={"#000"}
              fontFamily="Roboto Condensed"
              fontWeight="bold"
              margin="20px 0"
              style={{ textTransform: "uppercase" }}
            >
              CUSTOMIZE YOUR {product.product_name ?? ""}
            </NormalText>
          )}
        <Box margin={"10px 0 50px 0"}>
          {!addonsLoading ? (
            <Grid container spacing={1}>
              {product &&
                product.product_detail &&
                product.product_detail.is_customizable &&
                addons &&
                addons.length !== 0 &&
                addons.map((addon: any, index: number) => (
                  <Grid
                    item
                    xs={4}
                    md={4}
                    sm={4}
                    lg={4}
                    key={"product-" + index}
                  >
                    <ProductItem
                      name={addon.product_name ?? ""}
                      image={addon.product_image_main}
                      price={
                        addon.product_price_after_discount
                          ? Number(addon.product_price_after_discount)
                          : Number(addon.product_price)
                      }
                      active={
                        additional &&
                        additional.some(
                          (item) => Number(item.id) === addon.product_assign_id
                        )
                      }
                      backgroundColor={data?.primary_color ?? "#FFC535"}
                      textColor={data?.secondary_color ?? "#000"}
                      onClick={() => {
                        let productResult = additional.find(
                          (item) => Number(item.id) === addon.product_assign_id
                        );
                        let temp = [...additional];
                        if (!productResult) {
                          temp.push({
                            id: addon.product_assign_id,
                            name: addon.product_name,
                            price: addon.product_price_after_discount
                              ? addon.product_price_after_discount
                              : addon.product_price,
                            qty: 1,
                            product_id: addon.product_id,
                          });
                          setAdditional(temp);
                        } else {
                          let t = [...additional];
                          let productIndex = additional.findIndex(
                            (item) =>
                              Number(item.id) === addon.product_assign_id
                          );
                          t.splice(productIndex, 1);
                          setAdditional(t);
                        }
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Stack direction={"row"} spacing={2}>
              <Skeleton
                width={"100%"}
                height={350}
                sx={{ borderRadius: "20px" }}
              />
              <Skeleton
                width={"100%"}
                height={350}
                sx={{ borderRadius: "20px" }}
              />
              <Skeleton
                width={"100%"}
                height={350}
                sx={{ borderRadius: "20px" }}
              />
              <Skeleton
                width={"100%"}
                height={350}
                sx={{ borderRadius: "20px" }}
              />
            </Stack>
          )}
        </Box>
      </Box>
      <BottomSheet padding="20px 30px" height="252px">
        {!loading && (
          <>
            <Box
              width={"100%"}
              sx={{ placeContent: "center" }}
              display={"flex"}
            >
              <CounterButton
                value={qty}
                background={data?.primary_color ?? "#FFC535"}
                onIncrease={() => setQty(qty + 1)}
                onDecrease={() => {
                  if (qty === 1) {
                    setQty(1);
                  } else {
                    setQty(qty - 1);
                  }
                }}
              />
            </Box>
            <Box padding={"20px"}>
              <ChekoutButton
                background={data?.primary_color ?? "#FFC535"}
                onClick={() => handleAddToCart()}
              >
                <NormalText fontFamily="D-DIN Exp" fontSize="36px">
                  TAMBAHKAN KE KERANJANG
                </NormalText>
              </ChekoutButton>
            </Box>
          </>
        )}
      </BottomSheet>
    </MainLayout>
  );
};

export default ProductDetail;
