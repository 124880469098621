import React from "react";
import MainLayout from "components/Layouts/MainLayout";
import { Box, Stack, Grid, Switch } from "@mui/material";
import { NormalText } from "components/styled/text.styled";
import { SpacerLine } from "components/styled/layout.styled";
import CartSheet from "./../Cart//cart.sheet";
import CheckoutItem from "./checkout.product.item";
import { ArrowIcon, QrisIcon, CashIcon, CreditCardIcon } from "asset/icons";
import { Button } from "components/styled/button.styled";
import PaymentMethod from "./payment.method";
import history from "helper/history";
import styled from "styled-components";
import useRedux from "redux/useRedux";
import { getPickupPoints, PickupPointTypes } from "redux/pickuppoint";
import { STORAGE_SERVICE_KEY, LOYALTY_POINT } from "helper/constants";
import {
  getPaymentMethods,
  PaymentMethodProps,
  getAvailableBanks,
  BankProps,
} from "redux/payment";
import { createOrder } from "redux/checkout";
import moment from "moment";
import "moment/locale/id";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "helper/general";
import PaymentView from "./Payment/view";
import { getFeeSetting } from "redux/storeInfo/store.info.services";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dialog from "components/Modal/modal";
import BankItem, { logos } from "./bank.item";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import SelectedPickService, { ListPickServiceModal } from "./pick.service.item";
import PaymentMethodShimmer from "./Shimmer/paymentMethod";
// import PickupItem from "./pickup.item";
// import PickupShimmer, { PupImageLoading } from "./Shimmer/pickUpPoint";
import { AppSettingData } from "redux/appsetting";
import { countdownSetter } from "redux/preferences";
import NoPhoto from "asset/images/no-product-photo.svg";

interface ServicesProps {
  payment_fee: number;
  tax: number;
  service: number;
}

const CheckoutPage = () => {
  const navigate = useNavigate();
  const {
    thunkDispatch,
    storeState: { Cart, StoreInfo, AppSetting, LoyaltyPoint },
  } = useRedux();

  const merchantType = StoreInfo?.data?.data?.merchant_type;
  const [paymentMethod, setPaymentMethod] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [paymentView, setPaymentView] = React.useState(false);
  const [paymentMethodLoading, setPaymentMethodLoading] = React.useState(true);
  const [pickupLoading, setPickupLoading] = React.useState(true); // eslint-disable-line
  const [selectBankModal, setSelectBankModal] = React.useState(false);
  const [selectPickServiceModal, setSelectPickServiceModal] =
    React.useState(false);
  const [availableBanks, setAvailableBanks] = React.useState<BankProps[]>();

  const [useLoyalty, setUseLoyalty] = React.useState(false);
  const [feeSetting, setFeeSetting] = React.useState<{
    tax?: number;
    service?: number;
    payment_fee_type?: string;
    platform_fee_type?: string;
  }>({});
  const [services, setServices] = React.useState<ServicesProps>({
    payment_fee: 0,
    tax: 0,
    service: 0,
  });
  const [paymentDetail, setPaymentDetail] = React.useState({
    payment_type: "",
    payment_channel: "",
    tax_percentage: 0,
    service_percentage: 0,
    tax_price: 0,
    service_price: 0,
    payment_fee_type: "owner",
    payment_fee_customer: 0,
    payment_fee_owner: 0,
    total_amount: 0,
    bank_code: "",
  });

  const [payload, setPayload] = React.useState({
    store_code: "",
    order_type: "TAKEAWAY",
    device_type: "KIOSK",
    customer_name:
      LoyaltyPoint &&
      LoyaltyPoint.data &&
      LoyaltyPoint.data.data &&
      LoyaltyPoint.data.data.full_name
        ? LoyaltyPoint.data.data.full_name
        : "",
    customer_email:
      LoyaltyPoint &&
      LoyaltyPoint.data &&
      LoyaltyPoint.data.data &&
      LoyaltyPoint.data.data.email
        ? LoyaltyPoint.data.data.email
        : "",
    customer_phone_number:
      LoyaltyPoint &&
      LoyaltyPoint.data &&
      LoyaltyPoint.data.data &&
      LoyaltyPoint.data.data.phone
        ? LoyaltyPoint.data.data.phone
        : localStorage.getItem(LOYALTY_POINT) ?? "",
    sub_total: 0,
    table_id: "",
    total_price: 0,
    pickup_point_id: 0,
  });

  const [pickupPoint, setPickupPoint] = React.useState(0); // eslint-disable-line
  const [pickupPoints, setPickupPoints] = React.useState<PickupPointTypes>(); // eslint-disable-line
  const [grandTotal, setGrandTotal] = React.useState(0);
  const [paymentMethods, setPaymentMethods] =
    React.useState<PaymentMethodProps[]>();
  const [dates, setDates] = React.useState("");

  const LoyaltyPointUsage =
    LoyaltyPoint && LoyaltyPoint.data && LoyaltyPoint.data.data
      ? LoyaltyPoint.data.data
      : null;
  const phoneNumberLoyalty =
    LoyaltyPoint &&
    LoyaltyPoint.data &&
    LoyaltyPoint.data.data &&
    LoyaltyPoint.data.data.phone
      ? LoyaltyPoint.data.data.phone
      : localStorage.getItem(LOYALTY_POINT);

  const data: AppSettingData = AppSetting?.data?.data ?? null;

  const handleCheckout = () => {
    setLoading(true);
    setPaymentView(false);
    let products: any = [];
    if (paymentDetail && paymentDetail.payment_type) {
      if (Cart && Cart.items && Cart.items.length > 0) {
        if (StoreInfo && StoreInfo.status === "success") {
          /* eslint-disable */
          Cart.items.map((item) => {
            item.products.map((product) => {
              let addons: any = [];
              if (product.additionals && product.additionals.length > 0) {
                product.additionals.map((addon) => {
                  addons.push({
                    product_id: addon.product_id,
                    product_assign_id: Number(addon.id),
                    product_price: Number(addon.price),
                    product_qty: addon.qty,
                    sub_total: Number(addon.price) * addon.qty,
                    notes: "",
                  });
                });
              }
              products.push({
                product_id: product.product_id,
                product_price: Number(product.price),
                product_qty: product.qty,
                sub_total: Number(product.price) * product.qty,
                is_customizable: product.is_customizable,
                notes: "",
                product_add_on: addons,
              });
            });
          });

          let orderType = localStorage.getItem(STORAGE_SERVICE_KEY);

          let checkout = {
            ...payload,
            pickup_point_id: pickupPoint,
            store_code: StoreInfo.data.data.store_code,
            payment_detail: paymentDetail,
            product_order_detail: products,
            order_type: orderType ?? "TAKEAWAY",
            use_loyalty: useLoyalty,
            loyalty_usage: useLoyalty
              ? LoyaltyPointUsage && LoyaltyPointUsage.loyalty_point
                ? Number(LoyaltyPointUsage.loyalty_point) > grandTotal
                  ? grandTotal
                  : grandTotal - parseInt(LoyaltyPointUsage.loyalty_point)
                : 0
              : 0,
            use_voucher: false,
            voucher_id: [],
          };

          thunkDispatch(createOrder(checkout))
            .unwrap()
            .then((res) => {
              if (res && res.status === "success") {
                if (res.data.data) {
                  if (
                    res.data.data.payment_method === "cash" ||
                    res.data.data.payment_method === "debit" ||
                    res.data.data.payment_method === "credit"
                  ) {
                    thunkDispatch(countdownSetter(Date.now()))
                      .unwrap()
                      .then(() => {
                        setTimeout(() => {
                          navigate("/payment/success");
                        }, 2000);
                      });
                  } else {
                    setLoading(false);
                    setPaymentView(true);
                  }
                  localStorage.setItem(LOYALTY_POINT, "");
                } else {
                  setLoading(false);
                  toast.dark(res.data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    closeButton: false,
                    style: {
                      textAlign: "center",
                      margin: "50% 0 0 0",
                      width: "80% !important",
                    },
                  });
                }
              } else {
                if (res?.error) {
                  setLoading(false);
                  toast.dark(res.error.response.data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    closeButton: false,
                    style: {
                      textAlign: "center",
                      margin: "50% 0 0 0",
                      width: "80% !important",
                    },
                  });
                }
              }
            })
            .catch((e) => {
              if (e) {
                console.log("Response ERR", e.response);
              }
            });
        } else {
          navigate("/set");
        }
      } else {
        alert("Belum ada items");
      }
    } else {
      setLoading(false);
      toast.dark("Silahkan pilih metode pembayaran terlebih dahulu", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        closeButton: false,
        style: {
          textAlign: "center",
          margin: "50% 0 0 0",
          width: "80% !important",
        },
      });
    }
  };

  const [service, setService] = React.useState<
    | "TAKE AWAY"
    | "DINE IN"
    | "DELIVERY"
    | "PICKUP POINT"
    | "IN STORE PICKUP"
    | any
  >(localStorage.getItem(STORAGE_SERVICE_KEY)! ?? "");

  React.useEffect(() => {
    setService(localStorage.getItem(STORAGE_SERVICE_KEY));
  }, [localStorage.getItem(STORAGE_SERVICE_KEY)]);

  React.useEffect(() => {
    setInterval(() => {
      setDates(moment().format("dddd, Do MMMM YYYY, HH:mm ss"));
    }, 1000);
  }, [dates]);

  React.useEffect(() => {
    setPaymentMethodLoading(true);
    getAvailableBanks().then((res) => {
      if (res && res.length > 0) {
        setPaymentMethodLoading(false);
        setAvailableBanks(res);
      } else {
        setPaymentMethodLoading(false);
        console.log("Bank Not Found", []);
      }
    });
  }, []);

  React.useEffect(() => {
    if (
      StoreInfo &&
      StoreInfo.data &&
      StoreInfo.data.data &&
      StoreInfo.data.data.id
    ) {
      setPaymentMethodLoading(true);
      getFeeSetting(StoreInfo.data.data.merchant_id).then((res) => {
        if (res && res.data) {
          setFeeSetting(res.data);
        }
      });

      thunkDispatch(getPaymentMethods(StoreInfo.data.data.merchant_id))
        .unwrap()
        .then((res) => {
          if (res) {
            setPaymentMethodLoading(false);
            setPaymentMethods(res.data.data);
          }
        });

      setPickupLoading(true);
      thunkDispatch(getPickupPoints(StoreInfo.data.data.id))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            setPickupPoints(res.data.data);
            setPickupLoading(false);
          } else {
            setPickupLoading(false);
          }
        });
    } else {
      navigate("/set");
    }
  }, [thunkDispatch, StoreInfo, navigate]);

  React.useEffect(() => {
    // if (feeSetting.tax && feeSetting.service) {
    let cartTotal = Cart.total_price;
    let paymentFee = services.payment_fee;
    let tax = 0;
    let service = 0;

    if (feeSetting.tax) {
      tax = cartTotal * (feeSetting.tax / 100);
    }

    if (feeSetting.service) {
      service = cartTotal * (feeSetting.service / 100);
    }
    let total = cartTotal + tax + service;
    let customerFee: number = 0;
    let ownerFee: number = 0;

    if (feeSetting.payment_fee_type === "SPLIT") {
      customerFee = paymentFee / 2;
      ownerFee = paymentFee / 2;
    } else if (feeSetting.payment_fee_type === "CUSTOMER") {
      customerFee = paymentFee;
    } else {
      ownerFee = paymentFee;
    }

    let final = Math.ceil(total + customerFee);
    setPaymentDetail({
      ...paymentDetail,
      total_amount: final,
      service_price: Math.ceil(service),
      tax_price: Math.ceil(tax),
      tax_percentage: feeSetting.tax ?? 0,
      payment_fee_type: feeSetting.payment_fee_type ?? "",
      payment_fee_customer: customerFee,
      payment_fee_owner: ownerFee,
      service_percentage: feeSetting.service ?? 0,
    });
    setPayload({
      ...payload,
      sub_total: cartTotal,
      total_price: final,
    });
    setGrandTotal(final);
    // }
  }, [services, paymentMethod, Cart, feeSetting]);

  return (
    <MainLayout
      background="#fff"
      title={`Checkout | ${data?.merchant_name ?? "Kiosk"}`}
      noTimeout={paymentView}
    >
      <ToastContainer />
      {paymentView ? (
        <PaymentView type={paymentDetail.payment_type} />
      ) : (
        <>
          <Dialog
            styles={{
              width: "654px",
              borderRadius: "40px",
              padding: "48px 62px",
            }}
            open={selectBankModal}
            handleClose={() => setSelectBankModal(false)}
          >
            <NormalText
              fontFamily="Roboto Condensed"
              fontSize="36px"
              fontWeight="bold"
              textAlign="center"
            >
              PILIH VIRTUAL ACCOUNT
            </NormalText>
            <NormalText
              fontFamily="Roboto Condensed"
              fontSize="24px"
              textAlign="center"
            >
              Pilih jenis virtual account untuk melanjutkan transaksi
            </NormalText>
            <Box margin={"30px 0 0 0"}>
              {availableBanks &&
                availableBanks.length > 0 &&
                availableBanks.map(
                  (bank, index) =>
                    bank.is_activated && (
                      <BankItem
                        key={index}
                        code={bank.code}
                        name={bank.name}
                        background={data?.secondary_color ?? "#FFC535"}
                        active={paymentDetail.bank_code === bank.code}
                        onClick={() => {
                          setPaymentDetail({
                            ...paymentDetail,
                            bank_code: bank.code,
                          });
                        }}
                      />
                    )
                )}
            </Box>
            <Button
              margin="20px 0 0 0"
              background={data?.primary_color ?? "#FFC535"}
              onClick={() => setSelectBankModal(false)}
            >
              <NormalText fontSize="25px" color={"white"}>
                PILIH
              </NormalText>
            </Button>
          </Dialog>
          <ListPickServiceModal
            open={selectPickServiceModal}
            onClose={() => setSelectPickServiceModal(false)}
            merchantType={merchantType}
            selectedService={service}
          />

          <Box
            width={"100%"}
            padding={"25px 52px"}
            margin={"0 0 200px 0"}
            sx={{ background: "#fff" }}
          >
            <Stack
              onClick={() => history.back()}
              direction={"row"}
              alignItems={"center"}
              margin={"50px 0 30px 0"}
              spacing={2}
              sx={{ cursor: "pointer" }}
            >
              <img src={ArrowIcon} alt={"arrow_icon"} />
              <NormalText
                fontFamily="D-DIN Exp"
                fontSize="26px"
                color="#666666"
                fontWeight="bold"
              >
                Kembali
              </NormalText>
            </Stack>

            {/* <NormalText
              fontFamily="D-DIN Exp"
              fontSize="24px"
              fontWeight="bold"
              margin="10px 0 10px 0"
            >
              Tipe Pemesanan
            </NormalText>
            <SelectedPickService
              service={service}
              onClick={() => setSelectPickServiceModal(true)}
              buttonColor={data}
            /> */}
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <NormalText
                fontFamily="Roboto Condensed"
                fontSize="36px"
                fontWeight="bold"
              >
                PESANAN SAYA
              </NormalText>
            </Stack>
            {/* <SpacerLine /> */}
            <Box
              width={"100%"}
              height={"100px"}
              margin={"20px 0 50px 0"}
              bgcolor={
                data?.primary_color ? data.primary_color + "60" : "#FFEE8E"
              }
              borderRadius={"9px 9px 0 0"}
              padding={"33px 30px"}
            >
              <NormalText
                fontFamily="Roboto Condensed"
                fontSize="24px"
                fontWeight="400"
                style={{ textTransform: "capitalize" }}
              >
                {dates}
              </NormalText>
            </Box>
            {Cart && Cart.items && Cart.items.length > 0 ? (
              Cart.items.map((brand, index) => (
                <div key={"brand-" + index}>
                  {/* {Brand &&
                    Brand.data &&
                    Brand.data &&
                    Brand.data.meta &&
                    Brand.data.meta.total &&
                    Brand.data.meta.total > 1 && (
                      <NormalText
                        fontFamily="Roboto Condensed"
                        fontSize="30px"
                        fontWeight="bold"
                        margin={"20px 0 15px 0"}
                        style={{ textTransform: "uppercase" }}
                      >
                        {brand.brand_name}
                      </NormalText>
                    )}
                  <SpacerLine height={"2px"} /> */}
                  {brand.products.map((product, key) => (
                    <CheckoutItem
                      key={"product-" + key}
                      qty={product.qty}
                      name={product.product_name}
                      price={Number(product.price)}
                      additionals={product.additionals ?? []}
                      image={
                        product.product_image.length === 0
                          ? NoPhoto
                          : product.product_image
                      }
                      background={data?.primary_color ?? "#FFC535"}
                    />
                  ))}
                </div>
              ))
            ) : (
              <NormalText
                fontFamily="Roboto Condensed"
                fontSize="30px"
                fontWeight="bold"
                textAlign={"center"}
              >
                Belum ada item
              </NormalText>
            )}
            <Stack direction={"row"} justifyContent={"space-between"}>
              <NormalText
                fontFamily="Roboto Condensed"
                fontSize="36px"
                fontWeight="bold"
                margin="20px 0 10px 0"
              >
                SUB TOTAL
              </NormalText>
              <NormalText
                fontFamily="Roboto Condensed"
                fontSize="36px"
                fontWeight="bold"
                margin="20px 0 10px 0"
              >
                {formatCurrency(Cart.total_price)}
              </NormalText>
            </Stack>
            <Box margin={"20px 0 0 0"}>
              {/* <Stack direction={"row"} justifyContent={"space-between"}>
                <NormalText
                  fontFamily="Roboto Condensed"
                  fontSize="28px"
                  fontWeight="400"
                >
                  Payment Fee
                </NormalText>
                <NormalText
                  fontFamily="D-DIN Exp"
                  fontSize="24px"
                  fontWeight="bold"
                >
                  {formatCurrency(Number(paymentDetail.payment_fee_customer))}
                </NormalText>
              </Stack> */}
              <Stack direction={"row"} justifyContent={"space-between"}>
                <NormalText
                  fontFamily="Roboto Condensed"
                  fontSize="28px"
                  fontWeight="400"
                >
                  Tax
                </NormalText>
                <NormalText
                  fontFamily="D-DIN Exp"
                  fontSize="30px"
                  fontWeight="bold"
                >
                  {formatCurrency(paymentDetail.tax_price)}
                </NormalText>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                margin={"0 0 10px 0"}
              >
                <NormalText
                  fontFamily="Roboto Condensed"
                  fontSize="28px"
                  fontWeight="400"
                >
                  Services
                </NormalText>
                <NormalText
                  fontFamily="D-DIN Exp"
                  fontSize="30px"
                  fontWeight="bold"
                >
                  {formatCurrency(
                    paymentDetail.service_price +
                      paymentDetail.payment_fee_customer
                  )}
                </NormalText>
              </Stack>
              {/* <SpacerLine /> */}
              <Stack direction={"row"} justifyContent={"space-between"}>
                <NormalText
                  fontFamily="Roboto Condensed"
                  fontSize="36px"
                  fontWeight="bold"
                  color={data?.primary_color ?? "#FFC535"}
                  margin="20px 0 10px 0"
                >
                  GRAND TOTAL
                </NormalText>
                <NormalText
                  fontFamily="Roboto Condensed"
                  fontSize="36px"
                  fontWeight="bold"
                  margin="20px 0 10px 0"
                  color={data?.primary_color ?? "#FFC535"}
                >
                  {formatCurrency(grandTotal)}
                </NormalText>
              </Stack>
            </Box>

            {/* END ORDER DETAILS */}
            {/* HIDE DULU */}
            {/* {pickupPoints &&
              pickupPoints.pickUpList &&
              pickupPoints.pickUpList.length > 0 && (
                <NormalText
                  fontFamily="Roboto Condensed"
                  fontSize="36px"
                  fontWeight="bold"
                  margin="20px 0 20px 0"
                >
                  PICKUP POINT
                </NormalText>
              )}

            {!pickupLoading ? (
              <>
                <StyledBox sx={{ overflowX: "auto" }}>
                  <Stack direction={"row"}>
                    {pickupPoints &&
                      pickupPoints.pickUpList &&
                      pickupPoints.pickUpList.length > 0 &&
                      pickupPoints.pickUpList.map((pup, key) => (
                        <PickupItem
                          key={"pup-" + key}
                          checked={pickupPoint === pup.id}
                          label={pup.pupoint_name}
                          name={"pickup"}
                          background={data?.primary_color ?? "#FFC535"}
                          selectedBackground={data?.secondary_color ?? "#000"}
                          onChange={() => setPickupPoint(pup.id)}
                        />
                      ))}
                  </Stack>
                </StyledBox>
                {pickupPoints &&
                  pickupPoints.image_pup &&
                  pickupPoints.pickUpList &&
                  pickupPoints.pickUpList.length > 0 && (
                    <PickupPoint
                      src={pickupPoints.image_pup}
                      alt={"pickup_image"}
                    />
                  )}
              </>
            ) : (
              <>
                <PickupShimmer />
                <PupImageLoading />
              </>
            )} */}
            <NormalText
              fontFamily="Roboto Condensed"
              fontSize="36px"
              fontWeight="bold"
              margin="20px 0 10px 0"
            >
              METODE PEMBAYARAN
            </NormalText>
            <SpacerLine />
            {!paymentMethodLoading ? (
              <Box width={"100%"} margin={"20px 0 35px 0"}>
                <Grid container spacing={2}>
                  {paymentMethods &&
                    paymentMethods.length > 0 &&
                    paymentMethods.map((method, index) => (
                      <Grid item sm={6} md={6} lg={6}>
                        <PaymentMethod
                          background={data?.primary_color ?? "#FFC535"}
                          key={"methods-" + index}
                          active={paymentMethod === method.payment_code}
                          imgStyle={{
                            filter: "drop-shadow(0px 0px 2px white)",
                          }}
                          img={
                            method.payment_code === "virtual_account"
                              ? paymentDetail.bank_code
                                ? logos.find(
                                    (x) => x.code === paymentDetail.bank_code
                                  )?.logo
                                : CreditCardIcon
                              : method.payment_code === "cash"
                              ? CashIcon
                              : method.payment_code === "qr_code"
                              ? QrisIcon
                              : CreditCardIcon
                          }
                          text={
                            method.payment_code === "virtual_account"
                              ? paymentDetail.bank_code +
                                " " +
                                method.payment_name
                              : method.payment_name
                          }
                          onClick={() => {
                            if (method.payment_code === "virtual_account") {
                              setSelectBankModal(true);
                            }
                            let payFee: number = 0;
                            payFee = parseInt(String(method.payment_fee_price));
                            if (Number(method.payment_fee_percentage) > 0) {
                              let percent: any = method.payment_fee_percentage;
                              let subTotal = Cart.total_price;
                              let result: number = subTotal * (percent / 100);
                              payFee += Math.ceil(result);
                            }

                            setServices({
                              ...services,
                              payment_fee: payFee,
                            });
                            setPaymentDetail({
                              ...paymentDetail,
                              payment_type: method.payment_code,
                              payment_channel: method.payment_code,
                              payment_fee_owner: parseInt(
                                String(method.payment_fee_price)
                              ),
                            });
                            setPaymentMethod(method.payment_code);
                          }}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Box>
            ) : (
              // <Stack direction={"row"} spacing={2} margin={"20px 0 0 0"}>
              // </Stack>
              <PaymentMethodShimmer />
            )}
            {phoneNumberLoyalty && (
              <Grid
                container
                spacing={2}
                sx={{
                  width: "100%",
                  height: "85px",
                  backgroundColor: data?.secondary_color ?? "#000",
                  padding: "10px",
                  marginTop: "20px",
                  marginLeft: "3px",
                }}
              >
                <Grid item xs={10}>
                  <NormalText
                    fontFamily="Roboto Condensed"
                    fontSize="24px"
                    fontWeight="bold"
                  >
                    {LoyaltyPointUsage && LoyaltyPointUsage.loyalty_point
                      ? `Loyalty Poin (${LoyaltyPointUsage.loyalty_point} Point)`
                      : "Loyalty Point"}
                  </NormalText>
                </Grid>
                <Grid
                  item
                  xs={2}
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Switch
                    checked={useLoyalty}
                    onChange={(e: any) => setUseLoyalty(e.target.checked)}
                    disabled={
                      grandTotal >= 10000 &&
                      LoyaltyPointUsage &&
                      LoyaltyPointUsage.loyalty_point
                        ? false
                        : true
                    }
                  />
                </Grid>
              </Grid>
            )}
          </Box>
          <br />
          <br />
          {/* Bottom Sheet For Cart Information Or Summaries */}
          <CartSheet
            onClick={() => (!loading ? handleCheckout() : null)}
            total={
              useLoyalty && LoyaltyPointUsage && LoyaltyPointUsage.loyalty_point
                ? parseInt(LoyaltyPointUsage.loyalty_point) > grandTotal
                  ? 0
                  : grandTotal - parseInt(LoyaltyPointUsage.loyalty_point)
                : grandTotal
            }
            loading={loading}
            backgroundColor={data?.primary_color ?? "#FFC535"}
          />
        </>
      )}
    </MainLayout>
  );
};

const PickupPoint = styled.img`
  height: 430px;
  width: 100%;
  border-radius: 10px;
`;

const StyledBox = styled(Box)`
  overflow-y: hidden;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const PickupPointPlaceholder = styled.div`
  height: 430px;
  width: 100%;
  border-radius: 10px;
  background: #f1f1f1;
`;

export default CheckoutPage;
