import React from "react";
import { Skeleton, Box } from "@mui/material";

const BannerLoading = () => {
  return (
    <Box>
      <Skeleton sx={{ height: 300 }} animation="wave" variant="rectangular" />
    </Box>
  );
};

export default BannerLoading;
