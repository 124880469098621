import MainLayout from "components/Layouts/MainLayout";
import { Box } from "@mui/material";
import { NormalText } from "components/styled/text.styled";
import styled from "styled-components";
import useRedux from "redux/useRedux";
import { CheckedImage, FoodsFooter } from "asset/images";
import React from "react";
import Loader from "components/Loading/loader";
import { resetCart } from "redux/cart/cart.reducer";
import { resetCheckoutData } from "redux/checkout";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { useNavigate } from "react-router-dom";
import isElectron from "is-electron";
import { getInvoiceInfo, InvoiceData } from "redux/checkout";

const PaymentSuccessPage = () => {
  const {
    thunkDispatch,
    storeState: { Checkout, Preference },
  } = useRedux();
  const navigate = useNavigate();
  const [redirect, setRedirect] = React.useState(false);
  const [invoice, setInvoice] = React.useState<InvoiceData>();

  React.useEffect(() => {
    if (Checkout.status !== "success") {
      navigate("/");
    }
  }, [Checkout, navigate]);

  const handleResetState = () => {
    setRedirect(true);
    setTimeout(() => {
      thunkDispatch(resetCart())
        .unwrap()
        .then(() => {
          thunkDispatch(resetCheckoutData())
            .unwrap()
            .then(() => {
              setRedirect(false);
            });
        });
    }, 1000);
  };

  const renderer = ({ seconds, completed }: CountdownRenderProps) => {
    if (completed) {
      handleResetState();
      return null;
    } else {
      // Render a countdown
      return (
        <ButtonStyled onClick={() => handleResetState()}>
          <NormalText
            fontSize={"25px"}
            fontStyle={"italic"}
            fontWeight={"300"}
            color={"white"}
            textDecoration={"underline"}
          >
            Ke halaman utama ({seconds})
          </NormalText>
        </ButtonStyled>
      );
    }
  };

  React.useEffect(() => {
    if (
      Checkout &&
      Checkout.data &&
      Checkout.data.data &&
      Checkout.data.data.order_number
    ) {
      thunkDispatch(getInvoiceInfo(Checkout.data.data.order_number))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            setInvoice(res.data.data);
            if (isElectron()) {
              window.require("extra-fs");
              const { ipcRenderer } = window.require("electron");
              ipcRenderer.send("PRINT_PAGE", Checkout.data.data.order_number);
            }
          }
        })
        .catch((err) => console.log("ERR", err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout noTimeout background="#FFC535" title="Checkout | Kiosk Sandbox">
      <Box
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        textAlign={"center"}
      >
        <img
          style={{ margin: "120px 0 0 0" }}
          src={CheckedImage}
          alt={"checked_imagse"}
        />
        <NormalText
          fontFamily={"D-DIN Exp"}
          fontSize={"48px"}
          fontWeight={"bold"}
          color={"white"}
          margin={"86px 0 0 0"}
        >
          {invoice && invoice.payment_status === "PAID"
            ? "PEMBAYARAN "
            : "ORDER "}
          BERHASIL
        </NormalText>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div style={{ width: "70%" }}>
            <NormalText
              fontFamily="Roboto Condensed"
              fontSize="42px"
              color={"white"}
              letterSpacing={"1px"}
              margin={"32px 0 0 0"}
            >
              {invoice && invoice.payment_status === "PAID"
                ? "Pesanan Anda sedang kami proses. Mohon tunggu hingga receipt tercetak"
                : "Silahkan untuk melanjutkan pembayaran di kasir"}
            </NormalText>
          </div>
        </div>
        {invoice && invoice.payment_status === "PAID" && (
          <div>
            <NormalText
              fontFamily={"D-DIN Exp"}
              fontSize={"48px"}
              fontWeight={"bold"}
              letterSpacing={"4px"}
              color={"white"}
              margin={"64px 0 0 0"}
            >
              NOMOR ANTRIAN
            </NormalText>
            <NormalText
              fontFamily={"D-DIN Exp"}
              fontSize={"72px"}
              fontWeight={"bold"}
              letterSpacing={"4px"}
              color={"white"}
              margin={"32px 0 0 0"}
            >
              {Checkout && Checkout.status === "success"
                ? Checkout.data.data.queue_number
                : ""}
            </NormalText>
          </div>
        )}
        {!redirect && (
          <Countdown
            date={Preference.data.countdown_start + 60000}
            intervalDelay={0}
            precision={3}
            renderer={renderer}
          />
        )}
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div style={{ width: "70%" }}>
            <NormalText
              fontFamily="Roboto Condensed"
              fontSize="42px"
              color={"white"}
              letterSpacing={"1px"}
              margin={"0px 0 0 0"}
            >
              {invoice && invoice.payment_status === "PAID"
                ? "Pesanan bisa langsung diambil di Counter saat nomor anda telah diumumkan"
                : "Ambil receipt yang tercetak dimesin untuk melakukan transaksi di kasir"}
            </NormalText>
          </div>
        </div>
      </Box>
      {redirect && (
        <div
          style={{
            top: "0",
            position: "absolute",
            background: "rgb(0 0 0 / 51%)",
            height: "1920px",
            width: "100%",
            zIndex: 4,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "40%",
              left: "43%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Loader size={"100px"} />
            <NormalText
              color={"white"}
              fontFamily={"Roboto Condensed"}
              fontSize={"26px"}
              margin={"10px 0 0 0"}
            >
              Mohon Tunggu
            </NormalText>
          </div>
        </div>
      )}
      <Footer>
        <img src={FoodsFooter} alt={"checked_imagse"} />
      </Footer>
    </MainLayout>
  );
};

const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const ButtonStyled = styled.button`
  margin: 40px;
  cursor: pointer;
  border: none;
  background: transparent;
  border-radius: 10px;
  padding: 10px 20px;
`;

export default PaymentSuccessPage;
