import React from "react";
import { NormalText } from "components/styled/text.styled";
import styled from "styled-components";
import { Stack } from "@mui/material";
import { formatCurrency } from "helper/general";

interface ProductItemProps {
  name?: string;
  image?: string;
  price?: number;
  active?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement> & (() => void);
  backgroundColor?: string;
  textColor?: string;
}

const ProductItem = ({
  name,
  image,
  price,
  active,
  onClick,
  textColor,
  backgroundColor,
}: ProductItemProps) => {
  return (
    <Container
      onClick={onClick}
      active={active}
      backgroundColor={backgroundColor}
    >
      <Stack
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <ProductImage src={image} alt={`image_${name}`} />
        <NormalText
          fontFamily="Roboto Condensed"
          textAlign={"center"}
          fontSize={"24px"}
          fontWeight={"bold"}
          style={{ textTransform: "uppercase" }}
          margin={"5px 0 0 0"}
        >
          {name}
        </NormalText>
        <NormalText
          fontFamily="D-DIN Exp"
          textAlign={"center"}
          fontSize={"24px"}
          color={"#000"}
          fontWeight={"bold"}
        >
          {formatCurrency(price ?? 0)}
        </NormalText>
      </Stack>
    </Container>
  );
};

const Container = styled.div<ProductItemProps>`
  padding: 10px;
  width: 200px;
  height: 250px;
  border: 1px solid #dedede;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  border: 4px solid
    ${(props) => (props.active ? props.backgroundColor ?? "#B92362" : "dedede")};
  transition: ease-in-out 0.3s;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ProductImage = styled.img`
  width: 100px;
  height: 100px;
`;

export default ProductItem;
