import React from "react";
import { Box, Stack } from "@mui/material";
import styled from "styled-components";
import { NormalText } from "components/styled/text.styled";
import {
  BCALogo,
  MandiriLogo,
  BRILogo,
  SahabatSampoernaLogo,
  BNILogo,
  PermataLogo,
  CimbLogo,
  BSILogo,
  DBSLogo,
  BJBLogo,
} from "asset/images/bank_logo";

interface BankItemProps {
  code: string;
  name: string;
  onClick?: any;
  active?: boolean;
  background?: string;
}

export const logos = [
  {
    code: "BCA",
    logo: BCALogo,
  },
  {
    code: "MANDIRI",
    logo: MandiriLogo,
  },
  {
    code: "BRI",
    logo: BRILogo,
  },
  {
    code: "SAHABAT_SAMPOERNA",
    logo: SahabatSampoernaLogo,
  },
  {
    code: "BNI",
    logo: BNILogo,
  },
  {
    code: "PERMATA",
    logo: PermataLogo,
  },
  {
    code: "CIMB",
    logo: CimbLogo,
  },
  {
    code: "BSI",
    logo: BSILogo,
  },
  {
    code: "DBS",
    logo: DBSLogo,
  },
  {
    code: "BJB",
    logo: BJBLogo,
  },
];

const BankItem = ({
  code,
  name,
  onClick,
  active,
  background,
}: BankItemProps) => {
  return (
    <StyledBox onClick={onClick}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={8}
        margin={"0 0 20px 0"}
      >
        <BankLogo
          src={logos.find((x) => x.code === code)?.logo ?? ""}
          alt={"LOGO"}
        />
        <NormalText
          fontFamily="Roboto Condensed"
          fontSize="24px"
          lineHeight="28.13px"
          color={"black"}
          fontWeight={active ? "bold" : ""}
        >
          Virtual Account {name}
        </NormalText>
      </Stack>
      <Spacer />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  border-radius: 20px;
  padding: 0 10px;
  margin: 30px 0 0 0;
  align-items: center;
  cursor: pointer;
`;

const Spacer = styled.div`
  height: 1px;
  background: grey;
`;

const BankLogo = styled.img`
  width: 120px;
  max-height: 50px;
`;

export default BankItem;
