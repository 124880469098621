import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProductState, ProductDetailState, ProductAddOnState, ProductListState, ProductListParamsTypes } from "./types";
import axios from "axios";

export const listRecommendation = createAsyncThunk(
  "productRecommendation/list",
  async (merchant: any) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/recommendation?merchant_id=${merchant}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as ProductState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as ProductState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as ProductState;
    }
  }
);

export interface DetailProps {
  product_id?: any;
  merchant_id?: any;
  store_id?: any;
}

export const detailProduct = createAsyncThunk(
  "product/detail",
  async (props: DetailProps) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/detail?product_id=${props.product_id}&merchant_id=${props.merchant_id}&store_id=${props.store_id}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as ProductDetailState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as ProductDetailState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as ProductDetailState;
    }
  }
);

export const addOnProduct = createAsyncThunk(
  "product/addOn",
  async (props: DetailProps) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/additional?product_id=${props.product_id}&merchant_id=${props.merchant_id}&store_id=${props.store_id}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as ProductAddOnState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as ProductAddOnState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as ProductAddOnState;
    }
  }
);

export const getProductList = createAsyncThunk(
  "product/list",
  async (props: ProductListParamsTypes) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/product/kiosk/list?brand_id=${props.brand_id}&merchant_id=${props.merchant_id}&store_id=${props.store_id}&category_id=${props.category_id}`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        } as unknown as ProductListState;
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        } as unknown as ProductListState;
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        } as unknown as ProductListState;
    }
  }
);

export const listSearchProductResult = createAsyncThunk(
  "productSearchResult/list",
  async ({ merchant_id, keyword, store_id }: any) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/product/kiosk/list?merchant_id=${merchant_id}${
          keyword ? `&keyword=${keyword}` : ""
        }`,
        config
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: "success",
          error: null,
        };
      } else {
        return {
          data: response.data?.message,
          status: "empty",
          error: null,
        };
      }
    } catch (err) {
      if (err)
        return {
          data: "Something went wrong!",
          status: "error",
          error: err,
        };
    }
  }
);

