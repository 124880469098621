import React from "react";
import styled from "styled-components";
import { Box, Stack, Grid } from "@mui/material";
import Modal from "@mui/material/Modal";
import { NormalText } from "../styled/text.styled";
import { Button } from "../styled/button.styled";
import { formatCurrency } from "helper/general";
import NoPhoto from 'asset/images/no-product-photo.svg'

interface Props {
  open: boolean;
  handleClose?: () => void;
  product_image?: string;
  title?: string;
  price?: number;
  customizable?: boolean;
  confirmText?: string;
  cancelText?: string;
  titleStyle?: React.CSSProperties;
  handleConfirm: () => void;
  backgroundColor?: string;
  additional?: Array<any>;
  qty?: number;
  priceAdditional?: number;

}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 521,
  minHeight: 200,
  bgcolor: "background.paper",
  borderRadius: "40px",
  boxShadow: 24,
  p: 8,
};

const DetailMenuSuksesModal = ({
  open,
  handleClose,
  product_image,
  title,
  price,
  confirmText,
  cancelText,
  titleStyle,
  customizable,
  backgroundColor,
  handleConfirm,
  additional,
  qty,
  priceAdditional = 0,
}: Props) => {
  //handle image error
  const [imageError, setImageError] = React.useState(false);

  const handleImageError = () => {
    setImageError(true);
  };
  // to get total price additional
  additional?.map(
    (add) => priceAdditional += Number(add.price) 
  )
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-description"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} width={"521px"}>
        <NormalText
          style={titleStyle}
          fontSize={"24px"}
          fontFamily={"Roboto Condensed"}
          textAlign="center"
          fontWeight="700"
          margin="-20px 0 10px 0"
        >
          PESANAN BERHASIL DITAMBAHKAN
        </NormalText>
        {product_image && imageError ? (
          <ProductImage src={NoPhoto} alt={title} />
        ) : (
          <ProductImage src={product_image} alt={title} onError={handleImageError} />
        )}
        {title && (
          <NormalText
            style={titleStyle}
            fontSize={"24px"}
            fontFamily={"Roboto Condensed"}
            textAlign="center"
            fontWeight="700"
            margin="10px 0 5px 0"
          >
            {title.toUpperCase()}
          </NormalText>
        )}
        
        <Grid container spacing={1} alignItems="center" justifyContent="center" >
          { additional && (
            additional.map((add) => (
              <Grid
                item
                xs={12}
                lg={12}
              >
                <NormalText
                  fontSize={"24px"}
                  fontFamily={"Roboto Condensed"}
                  textAlign="center"
                  color="black"
                  fontWeight="400"
                >
                  + {add.name} 
                </NormalText>
              </Grid>
            ))
          )}
        </Grid>
        <NormalText
          fontSize={"28px"}
          fontFamily={"Roboto Condensed"}
          textAlign="center"
          color={backgroundColor ?? "#FFC535"}
          fontWeight="700"
        >
          {price && qty ? (
            formatCurrency(Number(price * qty + priceAdditional)) 
          ):(
            "Rp 0"
          )}   
        </NormalText>
        { additional?.length === 0 && (
          <NormalText margin="0 0 70px 0 "/>
        )} 
        <Stack
          direction={"row"}
          spacing={2}
          margin={"25px 0 0 0"}
          justifyContent={"center"}
        >
          { customizable && (
            <Button
              background={backgroundColor ?? "#B92362"}
              style={{
                color: "white",
                fontSize: "24px",
                width: "600px",
                height: "61px",
              }}
              shadow={"0px 8px 10px rgba(0, 0, 0, 0.12)"}
              onClick={handleConfirm}
            >
              <NormalText
                fontSize="30px"
                fontFamily="Roboto Condensed"
                fontWeight="500"
              >
                {confirmText ?? "CUSTOMIZE ANOTHER"}
              </NormalText>
            </Button>
          )}
        </Stack>

        <Stack
          direction={"row"}
          spacing={2}
          margin={"20px 0 0 0"}
          justifyContent={"center"}
        >
          <Button
            background={"#FFFFFF"}
            style={{
              color: "black",
              fontSize: "24px",
              width: "600px",
              height: "61px",
              border: `3px solid ${backgroundColor}`,
            }}
            shadow={"0px 8px 10px rgba(0, 0, 0, 0.12)"}
            onClick={handleClose}
          >
            <NormalText
              fontSize="30px"
              fontFamily="Roboto Condensed"
              fontWeight="500"
            >
              {cancelText ?? "LIHAT MENU LAINNYA"}
            </NormalText>
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

const ProductImage = styled.img`
  width: 170px;
  height: 170px;
  margin: auto;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default DetailMenuSuksesModal;
