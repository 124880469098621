import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Stack } from '@mui/material';
import { NormalText } from 'components/styled/text.styled';
import { useParams } from 'react-router-dom';
import useRedux from 'redux/useRedux';
import { InvoiceData } from 'redux/checkout';
import QRCode from 'react-qr-code';
import './inv.css';

const InvoicePage = () => {
  const { orderId } = useParams();
  const {
    thunkDispatch,
    storeState: { Invoice },
  } = useRedux();
  const [invoice, setInvoice] = React.useState<InvoiceData>();

  function numberWithCommas(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  React.useEffect(() => {
    if (Invoice.status === 'success') setInvoice(Invoice.data.data);
  }, [Invoice, thunkDispatch]);
  return (
    <>
      <Helmet>
        <title>Your Invoices</title>
      </Helmet>
      {invoice && invoice.store_name && (
        <Box
          className="printarea"
          width={'100%'}
          sx={{
            fontFamily: 'Ropa Sans !important',
            fontSize: '12px',
            'html::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {invoice.image_store_url && (
            <Box width={'100%'} sx={{ textAlign: 'center' }}>
              <img
                src={invoice.image_store_url}
                style={{ width: '400px' }}
                alt={'Store Logo'}
              />
            </Box>
          )}
          <NormalText
            textAlign={'center'}
            fontWeight={'bold'}
            margin={'15px 0'}
            fontSize={'80px'}
            fontFamily={'Ropa Sans'}
          >
            {invoice.store_name}
          </NormalText>
          <NormalText textAlign={'center'} fontFamily={'Ropa Sans'}>
            {invoice.store_address}
          </NormalText>
          <NormalText textAlign={'center'} fontFamily={'Ropa Sans'}>
            No Telp. {invoice.store_phone_number}
          </NormalText>
          <Box margin={'20px 0'}>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Box>
                <NormalText fontFamily={'Ropa Sans'}>No Trx.</NormalText>
                <NormalText fontFamily={'Ropa Sans'}>{orderId}</NormalText>
              </Box>

              <Box>
                <NormalText fontFamily={'Ropa Sans'}>Tanggal</NormalText>
                <NormalText fontFamily={'Ropa Sans'}>
                  {invoice.order_date}
                </NormalText>
              </Box>
            </Stack>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              margin={'0.8rem 0 0 0'}
            >
              <NormalText fontFamily={'Ropa Sans'}>
                Tipe {invoice.order_type}
              </NormalText>
              <NormalText fontFamily={'Ropa Sans'}>
                Jam {invoice.order_time}
              </NormalText>
            </Stack>
          </Box>

          <Box
            margin={'20px 0 0 0'}
            padding={'0 100px 0 0 !important'}
            width={'100%'}
          >
            <table style={{ width: '100%' }}>
              <tr>
                <th style={{ textAlign: 'center' }}>QTY</th>
                <th>Item</th>
                <th style={{ textAlign: 'right' }}>Total</th>
              </tr>
              {invoice.order_detail &&
                invoice.order_detail.length &&
                invoice.order_detail.map((item, index) => (
                  <tr key={'product-' + index}>
                    <td
                      style={{ textAlign: 'center', verticalAlign: 'baseline' }}
                    >
                      {item.qty}
                    </td>
                    <td>
                      {item.product_name}
                      {item.order_detail_add_on &&
                        item.order_detail_add_on.length > 0 && (
                          <>
                            <br />
                            Tambahan:
                            <ul
                              style={{
                                listStyle: 'none',
                                padding: '0 0 0 10px',
                              }}
                            >
                              {item.order_detail_add_on.map((addon, key) => (
                                <li key={key}>{addon.product_name}</li>
                              ))}
                            </ul>
                          </>
                        )}
                    </td>
                    <td
                      style={{ textAlign: 'right', verticalAlign: 'baseline' }}
                    >
                      {numberWithCommas(Number(item.total))}
                    </td>
                  </tr>
                ))}
            </table>
          </Box>
          <Box
            margin={'20px 20px 0 0'}
            padding={'0 100px 0 0 !important'}
            width={'100%'}
          >
            <table width={'100%'}>
              <tr>
                <td>Sub Total</td>
                <td style={{ textAlign: 'right' }}>
                  {numberWithCommas(Number(invoice.sub_total))}
                </td>
              </tr>
              <tr>
                <td>Pajak {`(${invoice.tax_percentage}%)`}</td>
                <td style={{ textAlign: 'right' }}>
                  {numberWithCommas(Number(invoice.tax))}
                </td>
              </tr>
              <tr>
                <td>Biaya Layanan {`(${invoice.service_percentage}%)`}</td>
                <td style={{ textAlign: 'right' }}>
                  {numberWithCommas(Number(invoice.service_fee))}
                </td>
              </tr>
              <tr style={{ margin: '10px 0 0 0' }}>
                <td style={{ fontWeight: 'bold' }}>TOTAL</td>
                <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {numberWithCommas(Number(invoice.total))}
                </td>
              </tr>
            </table>
          </Box>
          {invoice.payment_status === 'PAID' ? (
            <Box margin={'30px 0 0 0'}>
              <NormalText textAlign={'center'} fontFamily={'Ropa Sans'}>
                Nomor Antrian
              </NormalText>
              <NormalText
                textAlign={'center'}
                fontSize={'100px'}
                fontWeight={'bold'}
                fontFamily={'Ropa Sans'}
              >
                {invoice.order_queue}
              </NormalText>
              <NormalText textAlign={'center'} fontFamily={'Ropa Sans'}>
                Untuk cek informasi pesanan kamu, bisa melakukan scan disini.
              </NormalText>
              <Box width={'100%'} sx={{ textAlign: 'center' }} margin={'8px 0'}>
                <QRCode value={invoice.order_number} size={200} level={'L'} />
              </Box>
              <NormalText textAlign={'center'} fontFamily={'Ropa Sans'}>
                Terima Kasih
              </NormalText>
            </Box>
          ) : (
            <Box margin={'30px 0 30px 0'}>
              <NormalText textAlign={'center'} fontFamily={'Ropa Sans'}>
                Silahkan lakukan pembayaran dikasir
              </NormalText>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default InvoicePage;
