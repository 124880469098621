import React from "react";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";

interface Props {
  open: boolean;
  handleClose?: () => void;
  children: React.ReactNode;
  styles?: React.CSSProperties;
}

const Dialog = ({ open, handleClose, children, styles }: Props) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    minHeight: 200,
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 8,
    ...styles,
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>{children}</Box>
    </Modal>
  );
};

export default Dialog;
