import React from "react";
import { Box, Stack } from "@mui/material";
import Modal from "@mui/material/Modal";
import { NormalText } from "../styled/text.styled";
import { Button } from "../styled/button.styled";

interface Props {
  open: boolean;
  handleClose?: () => void;
  description?: string;
  title?: string;
  confirmText?: string;
  cancelText?: string;
  titleStyle?: React.CSSProperties;
  descriptionStyle?: React.CSSProperties;
  handleConfirm: () => void;
  backgroundButton?: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  minHeight: 200,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 8,
};

const ConfirmationModal = ({
  open,
  handleClose,
  title,
  description,
  confirmText,
  cancelText,
  titleStyle,
  descriptionStyle,
  backgroundButton,
  handleConfirm,
}: Props) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-description"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {title && (
          <NormalText
            style={titleStyle}
            fontSize={"45px"}
            fontFamily={"Roboto Condensed"}
            textAlign="center"
          >
            {title}
          </NormalText>
        )}
        {description && (
          <NormalText style={descriptionStyle}>{description}</NormalText>
        )}
        <Stack
          direction={"row"}
          spacing={2}
          margin={"40px 0 0 0"}
          justifyContent={"center"}
        >
          <Button
            background={backgroundButton ?? "#B92362"}
            style={{
              color: "white",
              fontSize: "12px",
              width: "300px",
              height: "100px",
            }}
            onClick={handleConfirm}
          >
            <NormalText
              fontSize="30px"
              fontFamily="Roboto Condensed"
              fontWeight="500"
            >
              {confirmText ?? "Ya"}
            </NormalText>
          </Button>
          <Button
            variant="secondary"
            style={{
              color: "white",
              fontSize: "12px",
              width: "300px",
              height: "100px",
            }}
            onClick={handleClose}
          >
            <NormalText
              fontSize="30px"
              fontFamily="Roboto Condensed"
              fontWeight="500"
            >
              {cancelText ?? "Batal"}
            </NormalText>
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
