import React from "react";
import { NormalText } from "components/styled/text.styled";
import styled from "styled-components";
import { Stack } from "@mui/material";
import CounterButton, { CounterProps } from "components/Counter/counter";
import { formatCurrency } from "helper/general";
import NoPhoto from "asset/images/no-product-photo.svg";



interface ProductItemProps {
  name?: string;
  image?: string;
  price?: number;
  customizable?: boolean;
  onChangeDetail?: React.MouseEventHandler<HTMLDivElement> & (() => void);
  addToCartAction?: React.MouseEventHandler<HTMLDivElement> & (() => void);
  backgroundColor?: any;
  isCartExist?: boolean;
  counterProps?: CounterProps;
}

const ProductItem = ({
  name,
  image,
  price,
  customizable,
  isCartExist,
  onChangeDetail,
  addToCartAction,
  backgroundColor,
  counterProps,
}: ProductItemProps) => {
  const [imageError, setImageError] = React.useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <Container>
      <Stack
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <div onClick={onChangeDetail}>
          {imageError ? (
            <ProductImage src={NoPhoto} alt={`image_${name}`} />
          ) : (
            <ProductImage src={image} alt={`image_${name}`} onError={handleImageError} />
          )}
          <NormalText
            fontFamily="Roboto Condensed"
            textAlign={"center"}
            fontSize={"16px"}
            fontWeight={"bold"}
            style={{ textTransform: "uppercase" }}
            // margin={"5px 0 0 0"}
            margin={name && name.length < 28 ? "5px 0 23px 0" : "5px 0 0 0"}
          >
            {name}
          </NormalText>
          <NormalText
            fontFamily="D-DIN Exp"
            textAlign={"center"}
            fontSize={"20px"}
            color={backgroundColor ?? "#FFC535"}
            fontWeight={"bold"}
          >
             {price ? formatCurrency(price) : "Rp 0"}
          </NormalText>
        </div>
        {isCartExist && counterProps ? (
          customizable ? (
            <ButtonAction
              onClick={addToCartAction}
              backgroundColor={backgroundColor}
            >
              Customize Another
            </ButtonAction>
          ) : (
            <CounterButton
              value={counterProps.value}
              background={backgroundColor ?? ""}
              onDecrease={counterProps.onDecrease}
              onIncrease={counterProps.onIncrease}
            />
          )
        ) : (
          <ButtonAction
            onClick={addToCartAction}
            backgroundColor={backgroundColor}
          >
            Add
          </ButtonAction>
        )}
      </Stack>
    </Container>
  );
};

const Container = styled.div`
  width: 248px;
  height: 248px;
  padding: 10px;
  border-radius: 10px;
  transition: ease-in-out 0.3s;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;

  :hover {
    background: #dfdfdf4a;
  }
`;

const ProductImage = styled.img`
  width: 100px;
  height: 100px;
`;

const ButtonAction = styled.button<ProductItemProps>`
  transition: ease-in-out 0.2s;
  border: 2px solid
    ${(props) => (props.backgroundColor ? props.backgroundColor : "#FFC535")};
  color: ${(props) => props.backgroundColor} ;
  background: transparent;
  border-radius: 100px;
  min-width: 180px;
  height: 40px;
  align-items: center;
  position: relative;
  z-index: 2;
  margin: 5px 0 0 0;
  font-size: 16px;

  
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.12);
  border-radius: 100px;

  :hover {
    background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#FFC535"};
    color: white;
  }
`;

export default ProductItem;
