import React from "react";
import BottomSheet from "components/BottomSheet";
import { Button } from "components/styled/button.styled";
import { NormalText } from "components/styled/text.styled";
import { Stack, Box } from "@mui/material";
import { ArrowRightCircle } from "asset/icons";
import { ReactComponent as ListIcon } from "asset/icons/ic_list.svg";
import styled from "styled-components";
import useRedux from "redux/useRedux";
import { getTotalPrice } from "redux/cart/cart.reducer";
import { formatCurrency } from "helper/general";
import Loader from "components/Loading/loader";
import LogoPagiiOrdering from "asset/logo/pagii-ordering-logomark-default.svg"

import { useNavigate } from "react-router-dom";
import { persistor } from "redux/store";
import ConfirmationModal from "components/Modal/confirmation";

interface CartSheetProps {
  summaries?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement> & (() => void);
  total?: number;
  loading?: boolean;
  backgroundColor?: any;
}

const CartSheet = ({
  summaries,
  onClick,
  total,
  loading,
  backgroundColor,
}: CartSheetProps) => {
  const {
    thunkDispatch,
    storeState: { Cart, AppSetting },
  } = useRedux();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const data: any = AppSetting?.data?.data ?? null;

  React.useEffect(() => {
    thunkDispatch(getTotalPrice())
      .unwrap()
      .then(() => null);
  }, [Cart, thunkDispatch]);

  const HandleKeluar = () => {
    persistor.pause();
    persistor.flush().then(() => {
      return persistor.purge();
    });
    navigate("/config");
  };

  return (
    <BottomSheet padding="32px 30px" height={summaries ? "299px" : "220px"}>
      <ConfirmationModal
        title="Apakah anda yakin ingin keluar?"
        handleConfirm={() => {
          HandleKeluar();
          setOpen(false);
        }}
        backgroundButton={data?.primary_color ?? "#FFC535"}
        open={open}
        handleClose={() => setOpen(false)}
      />
      {summaries ? (
        <Stack direction={"row"} spacing={1}>
          <SummariesBox backgroundColor={backgroundColor ?? "#FFC535"}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <ListIcon fill={backgroundColor ?? "#FFC535"} />
                <Stack direction={"column"} justifyContent={"left"}>
                  <Box
                    onClick={() => {
                      navigate("/cart");
                    }}
                  >
                    <NormalText
                      fontFamily="D-DIN Exp"
                      color={backgroundColor ?? "#FFC535"}
                      fontSize={"24px"}
                    >
                      Lihat Pesanan
                    </NormalText>
                  </Box>

                  <NormalText
                    fontFamily="D-DIN Exp"
                    color={"black"}
                    textAlign={"left"}
                    fontSize={"21px"}
                    margin={"-10px 0 0 0"}
                  >
                    {Cart.total_product} Pesanan
                  </NormalText>
                </Stack>
              </Stack>
              <NormalText
                fontFamily="D-DIN Exp"
                color={backgroundColor ?? "#FFC535"}
                fontSize={"32px"}
                fontWeight={"bold"}
              >
                {formatCurrency(Cart.total_price ?? 0)}
              </NormalText>
            </Stack>
          </SummariesBox>
          <Button
            height="100px"
            width={"300px"}
            radius={"10px"}
            onClick={onClick}
            background={backgroundColor ?? "#FFC535"}
          >
            <NormalText
              fontFamily="D-DIN Exp"
              color={"white"}
              fontSize={"36px"}
            >
              BAYAR
            </NormalText>
          </Button>
        </Stack>
      ) : (
        <Button
          variant={loading ? "disabled" : undefined}
          background={backgroundColor ?? "#FFC535"}
          height={"100px"}
          onClick={onClick}
        >
          <Box width={"100%"} padding={"0 10px"}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <NormalText
                fontFamily="D-DIN Exp"
                color={"white"}
                fontSize={"36px"}
              >
                BAYAR
              </NormalText>
              <Stack direction={"row"} spacing={3} alignItems={"center"}>
                <NormalText
                  fontFamily="D-DIN Exp"
                  color={"white"}
                  fontSize={"36px"}
                >
                  {formatCurrency(total ?? Cart.total_price)}
                </NormalText>
                {loading ? (
                  <Loader />
                ) : (
                  <img src={ArrowRightCircle} alt={"next_icon"} />
                )}
              </Stack>
            </Stack>
          </Box>
        </Button>
      )}

      <NormalText
        fontFamily={"Roboto"}
        fontSize={"16px"}
        textAlign={"left"}
        margin={"20px 0 0 0"}
      >
        *Swipe bagian kiri kebawah untuk melihat menu selanjutnya
      </NormalText>
      <NormalText fontFamily={"Roboto"} fontSize={"16px"} textAlign={"left"}>
        **Tekan Button Harga untuk melihat keranjang anda dan button bayar untuk
        melanjutkan ke pembayaran
      </NormalText>

      {summaries ? (
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          {/* <ThemeProvider theme={theme}>
            <Chip
              label="Keluar"
              onClick={() => setOpen(true)}
              color="warning"
              icon={<LogoutIcon />}
              sx={{borderRadius:'5px', padding:'1rem', height:'55px'}}
            />
          </ThemeProvider> */}
          <img
            src={LogoPagiiOrdering}
            alt="logo"
            style={{ width: "200px", height: "100px" }}
            onClick={() => setOpen(true)}
          />
        </Stack>
      ) : null}
    </BottomSheet>
  );
};

const SummariesBox = styled.div<CartSheetProps>`
  box-sizing: border-box;
  width: 720px;
  height: 100px;
  background: #f9f9f9;
  border: 3px solid
    ${(props) => (props.backgroundColor ? props.backgroundColor : "#B92362")};
  border-radius: 10px;
  padding: 17px 20px 12px 20px;
`;

export default CartSheet;
