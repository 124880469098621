import { createAsyncThunk } from "@reduxjs/toolkit";
import { StoreInfoState } from "./types";
import axios from "axios";

export const storeInfo = createAsyncThunk('store/info', async (code: string) => {
    const config = {
        headers: {
            api_key: `${process.env.REACT_APP_API_KEY}`,
        }
    };
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/merchant/store/${code}/detail`,
            config
        );
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as StoreInfoState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as StoreInfoState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as StoreInfoState;

    }
})

export const getFeeSetting = async (merchantId: number) => {
    const config = {
        headers: {
            api_key: `${process.env.REACT_APP_API_KEY}`,
        }
    };

    let response = await axios.get(`${process.env.REACT_APP_API_URL}/users/feesetting/${merchantId}`, config);
    return response.data;
}