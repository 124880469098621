import React from "react";
import styled from "styled-components";
import { SpacerLine } from "components/styled/layout.styled";
import { NormalText } from "components/styled/text.styled";
import { Grid, Stack } from "@mui/material";
import CounterButton from "components/Counter/counter";
import { ProductAdditional } from "redux/cart/cart.reducer";
import { formatCurrency } from "helper/general";
import NoPhoto from 'asset/images/no-product-photo.svg'


interface CartItemProps {
  name: string;
  price: number;
  additionals: ProductAdditional[];
  quantity: number;
  image: string;
  onIncrease?: any;
  onDecrease?: any;
  backgroundColor?: string;
}

export const calculatePrice = ({
  price,
  additional,
}: {
  price: number;
  additional: ProductAdditional[];
}) => {
  let prices = price;
  if (additional.length > 0) {
    // eslint-disable-next-line array-callback-return
    additional.map((item) => {
      prices += Number(item.price);
    });
  }

  return formatCurrency(prices);
};

export const calculatePriceMultipliedQty = ({
  price,
  additional,
  qty,
}: {
  price: number;
  additional: ProductAdditional[];
  qty: number;
}) => {
  let prices = price;
  if (additional.length > 0) {
    // eslint-disable-next-line array-callback-return
    additional.map((item) => {
      prices += Number(item.price);
    });
  }

  return formatCurrency(prices * qty);
};

const CartItem = ({
  name,
  price,
  quantity,
  additionals,
  image,
  onIncrease,
  onDecrease,
  backgroundColor
}: CartItemProps) => {
  const [imageError, setImageError] = React.useState(false);

  const handleImageError = () => {
    setImageError(true);
  };
  
  return (
    <>
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={9} md={9} lg={9}>
            <Stack direction={"column"}>
              <NormalText
                fontFamily="Roboto Condensed"
                fontWeight="bold"
                fontSize={"32px"}
                style={{ textTransform: "uppercase" }}
              >
                {name}
              </NormalText>

              <Grid container spacing={1} alignItems="center" justifyContent="left" >
              {additionals &&
                additionals.length > 0 &&
                additionals.map((addon, index) => (
                  <NormalText
                    key={"addons-" + index}
                    fontFamily="Roboto Condensed"
                    fontWeight="bold"
                    fontSize={"24px"}
                    color={"#777777"}
                    letterSpacing={"1px"}
                    style={{ textTransform: "uppercase" }}
                    margin={"0 10px 0 10px"}
                  >
                    + { addon.name } 
                  </NormalText>
                ))}
              </Grid> 
              <NormalText
                fontFamily="D-DIN Exp"
                fontWeight="bold"
                fontSize={"32px"}
                color={backgroundColor}
                margin={"15px 0 10px 0"}
                style={{ textTransform: "uppercase" }}
              >
                {calculatePrice({ price: price, additional: additionals })}
              </NormalText>

              {/* <EditButton>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  spacing={1}
                >
                  <img src={PencilIcon} alt={"pencil_icon"} />
                  <NormalText
                    fontFamily="D-DIN Exp"
                    fontWeight="bold"
                    fontSize={"24px"}
                    color={"#B92362"}
                  >
                    Edit
                  </NormalText>
                </Stack>
              </EditButton> */}
            </Stack>
          </Grid>
          <Grid item xs={3} md={3} lg={3}>
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"end"}
            >
              {imageError ? (
                <ProductImage src={NoPhoto} alt={`image_${name}`} />
              ) : (
                <ProductImage src={image} alt={`image_${name}`} onError={handleImageError} />
              )}
              <CounterButton
                value={quantity}
                background={backgroundColor ?? "#b92362"}
                onDecrease={onDecrease}
                onIncrease={onIncrease}
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <SpacerLine height="1px" style={{margin:"0 0 30px 0"}}/>
    </>
  );
};

const Container = styled.div`
  background: white;
  min-height: 240px;
  width: 100%;
  margin: 10px 0;
`;

const ProductImage = styled.img`
  width: 150px;
  height: 150px;
  margin: 0 0 10px 0;
  border-radius: 5px;
`;


// const EditButton = styled.button`
//   width: 155px;
//   height: 48px;
//   background: transparent;
//   color: #b92362;
//   border: 2px solid #b92362;
//   border-radius: 100px;
// `;

export default CartItem;
