import { Stack, Box } from "@mui/material";
import { NormalText } from "components/styled/text.styled";
import styled from "styled-components";

interface PaymentMethodProps {
  active?: boolean;
  img?: string;
  text?: string;
  onClick?: any;
  background?: string;
  imgStyle?: React.CSSProperties;
}

const PaymentMethod = ({
  active,
  img,
  text,
  onClick,
  background,
  imgStyle,
}: PaymentMethodProps) => {
  return (
    <Box width={"100%"}>
      <Container active={active} onClick={onClick} background={background}>
        <Box width={"100%"} margin={"20px"}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            spacing={1}
          >
            <PaymentIcon
              style={{ ...imgStyle, filter: "drop-shadow(0px 0px 10px white)" }}
              src={img}
              alt={"payment_method_icon"}
            />
            <NormalText
              margin={"15px 0 0 0"}
              fontSize={"20px"}
              color={active ? "white" : "black"}
              fontFamily={"Roboto Condensed"}
              fontWeight={"bold"}
              style={{ textTransform: "uppercase" }}
            >
              {text}
            </NormalText>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

const Container = styled.div<PaymentMethodProps>`
  width: 100%;
  height: 114px;
  transition: ease-in-out 0.3s;
  box-sizing: border-box;
  border: 1px solid #b92362;
  border: 1px solid ${({ background }) => background ?? "#b92362"};
  background: ${({ active, background }) =>
    active ? background ?? "#B92362" : "transparent"};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const PaymentIcon = styled.img`
  max-width: 120px;
  max-height: 100px;
`;

export default PaymentMethod;
