import { NormalText } from "components/styled/text.styled";
import React from "react";
import { Box, Stack } from "@mui/material";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import LogoPagiiOrderingGram from "asset/logo/pagii-ordering-logogram-default.svg"
import FooterImage from "asset/images/footer_queue.png";

const dummy: {
  queue: string;
  time: string;
  status: "PREPARE" | "COOKING" | "SERVING";
}[] = [
  {
    queue: "13434",
    time: "09:45",
    status: "COOKING",
  },
  {
    queue: "22341",
    time: "09:50",
    status: "COOKING",
  },
  {
    queue: "38958",
    time: "10:03",
    status: "SERVING",
  },
  {
    queue: "46432",
    time: "12:45",
    status: "PREPARE",
  },
  {
    queue: "53543",
    time: "12:45",
    status: "COOKING",
  },
  {
    queue: "65665",
    time: "12:45",
    status: "COOKING",
  },
  {
    queue: "77738",
    time: "11:45",
    status: "PREPARE",
  },
];

const QueuePage = () => {
  const [page, setPage] = React.useState(0);
  const [show, setShow] = React.useState<
    {
      queue: string;
      time: string;
      status: "PREPARE" | "COOKING" | "SERVING";
    }[]
  >();

  React.useEffect(() => {
    setTimeout(() => {
      if (page * 5 < dummy.length) {
        setShow(dummy.slice(page * 5, page * 5 + 5));
        setPage(page + 1);
      } else {
        setPage(0);
      }
    }, 3000);
  }, [page]);

  return (
    <Container image={FooterImage}>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Queue Display</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.cdnfonts.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700"
          rel="stylesheet"
        />
        <link
          href="https://fonts.cdnfonts.com/css/d-din?styles=37959,37958,37957,37961,37964,37963,37960,37962"
          rel="stylesheet"
        />
      </Helmet>
      <Box padding={"40px"}>
        <Stack direction={"row"} alignItems={"center"} spacing={3}>
          <img src={LogoPagiiOrderingGram} alt={"store_logo"} />
          <NormalText
            fontSize="48px"
            fontWeight="bold"
            fontFamily="D-Din Exp"
            color={"#B92362"}
          >
            Got Beef
          </NormalText>
        </Stack>
        <NormalText
          fontSize="64px"
          fontWeight="bold"
          fontFamily="D-Din Exp"
          textAlign={"center"}
        >
          QUEUE
        </NormalText>
        <Box sx={{ background: "rgba(255, 255, 255, 0.8)" }}>
          <Table>
            <thead>
              <th>No Antrian</th>
              <th>Jam Order</th>
              <th>Status</th>
            </thead>
            <tbody>
              {show &&
                show.map((item, index) => (
                  <tr>
                    <td>{item.queue}</td>
                    <td>{item.time}</td>
                    <td>
                      <Badge variant={item.status}>
                        {item.status === "PREPARE"
                          ? "Preparing"
                          : item.status === "COOKING"
                          ? "On Cooking"
                          : "Serving"}
                      </Badge>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Box>
      </Box>
    </Container>
  );
};

interface ContainerProps {
  image: any;
}

const Container = styled.div<ContainerProps>`
  margin: 0;
  padding: 0;
  background: #fff4f8;
  height: 100vh;
  overflow: hidden;
  background-size: 100%;
  background-image: ${(props) => (props.image ? `url(${props.image})` : "")};
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom;
`;

const Table = styled.table`
  font-family: "Open Sans";
  width: 100%;
  text-align: center;
  border-collapse: collapse;

  td {
    padding: 15px 0;
    font-size: 24px;
  }

  td,
  th {
    border: 1px solid black;
  }
  :first-child th {
    border-top: 0;
  }
  tr:last-child td {
    border-bottom: 0;
  }
  tr td:first-child,
  th:first-child {
    border-left: 0;
  }
  tr td:last-child,
  th:last-child {
    border-right: 0;
  }

  th {
    padding: 10px 0;
    font-size: 32px;
    font-weight: 600;
  }
`;

interface BadgeProps {
  variant: "PREPARE" | "COOKING" | "SERVING";
}

const Badge = styled.div<BadgeProps>`
  background: ${({ variant }) =>
    variant === "PREPARE"
      ? "#FF6B00"
      : variant === "COOKING"
      ? "#FFAD08"
      : "#039912"};
  color: white;
  border-radius: 50px;
  margin: 0 20%;
  padding: 10px 10px;
  text-align: center;
  font-family: "Open sans";
  font-size: 24px;
  font-weight: 600;
`;

export default QueuePage;
