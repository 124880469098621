import { createAsyncThunk } from '@reduxjs/toolkit';
import { RegisterCustomerState } from './types';
import axios from 'axios';

interface getApp {
  data: {
    merchant_id: any;
    phone: any;
  };
}

export const postRegisterCustomer = createAsyncThunk(
  'appSetting/list',
  async ({ data }: getApp) => {
    const config = {
      headers: {
        api_key: `${process.env.REACT_APP_API_KEY}`,
      },
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/registration`,
        data,
        config,
      );
      if (response && response.data) {
        return {
          data: response.data,
          status: 'success',
          error: null,
        } as unknown as RegisterCustomerState;
      } else {
        return {
          data: response.data?.message,
          status: 'empty',
          error: null,
        } as unknown as RegisterCustomerState;
      }
    } catch (err) {
      if (err)
        return {
          data: 'Something went wrong!',
          status: 'error',
          error: err,
        } as unknown as RegisterCustomerState;
    }
  },
);
