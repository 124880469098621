import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';

export interface ConfigState {
  selected_services: {
    enable: boolean;
  };
}

export const initialState: ConfigState = {
  selected_services: {} as {
    enable: boolean;
  },
};

export const ConfigSlice = createSlice({
  name: 'ConfigService',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(selectServices.fulfilled, (state, action) => {
        if (action.payload) {
          state.selected_services = action.payload.selected_services;
        }
      })
      .addCase(selectServices.rejected, (state, action) => {
        state.selected_services = {
          enable: true,
        };
      });
  },
});

interface SelectServicesProps {
  enable: boolean;
}

export const selectServices = createAsyncThunk(
  'Config/select/services',
  async ({ enable }: SelectServicesProps) => {
    return {
      selected_services: {
        enable: enable,
      },
    } as ConfigState;
  },
);

export const ConfigReducer = ConfigSlice.reducer;
