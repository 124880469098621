import { createAsyncThunk } from "@reduxjs/toolkit";
import { CheckoutState, InvoiceState, CancelOrderState } from "./types";
import axios from "axios";


export const createOrder = createAsyncThunk('checkout/create', async (props: any) => {
    const config = {
        headers: {
            api_key: `${process.env.REACT_APP_API_KEY}`,
        }
    };
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/users/order/checkout`, props,
            config
        );
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as CheckoutState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as CheckoutState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as CheckoutState;

    }
})


export const resetCheckoutData = createAsyncThunk('checkout/reset', async () => {
    return {
        data: 'Berhasil mereset checkout data!',
        status: "reset",
        error: null
    } as unknown as CheckoutState;
})

export const getInvoiceInfo = createAsyncThunk('checkout/invoice', async (order_code: string) => {
    const config = {
        headers: {
            api_key: `${process.env.REACT_APP_API_KEY}`,
        }
    };
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/merchant/order/print-invoice/${order_code}`,
            config
        );
        if (response && response.data) {
            if (response.data.data && response.data.data.image_store_url) {
                await getBase64FromUrl(response.data.data.image_store_url).then((res) => {
                    if (res) {
                        response.data.data.image_store_url = res
                    }
                });
            }
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as InvoiceState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as InvoiceState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as InvoiceState;

    }
})

export const cancelOrder = createAsyncThunk('checkout/cancel', async (order_code: string) => {
    const config = {
        headers: {
            api_key: `${process.env.REACT_APP_API_KEY}`,
        }
    };
    try {
        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/users/order/cancel`, {
            order_number: order_code
        },
            config
        );
        if (response && response.data) {
            return {
                data: response.data,
                status: "success",
                error: null
            } as unknown as CancelOrderState;
        } else {
            return {
                data: response.data?.message,
                status: "empty",
                error: null
            } as unknown as CancelOrderState;
        }
    } catch (err) {
        if (err)
            return {
                data: 'Something went wrong!',
                status: "error",
                error: err
            } as unknown as CancelOrderState;

    }
})


const getBase64FromUrl = async (url: string) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
        }
    });
}
