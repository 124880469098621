import React from "react";
import MainLayout from "components/Layouts/MainLayout";
import { Box, Stack } from "@mui/material";
// @ts-ignore
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import useRedux from "redux/useRedux";
import { BannerType, listAllBanner } from "redux/banner";
import { NormalText } from "components/styled/text.styled";
import { SpacerLine } from "components/styled/layout.styled";
import CartItem from "./cart.product.item";
import CartSheet from "./cart.sheet";
import { Button } from "components/styled/button.styled";
import { useNavigate } from "react-router-dom";
import { changeQuantity, ChangeQuantityProps } from "redux/cart/cart.reducer";
import ConfirmationModal from "components/Modal/confirmation";
import { toast, ToastContainer } from "react-toastify";
import BannerLoading from "views/Home/banner.loading";
import { AppSettingData } from "redux/appsetting";
import { SmallDefaultBannerSandbox } from "asset/images";
import NoPhoto from 'asset/images/no-product-photo.svg'


const CartPage = () => {
  const {
    thunkDispatch,
    storeState: { Cart, StoreInfo, AppSetting },
  } = useRedux();
  const navigate = useNavigate();
  const data: AppSettingData = AppSetting?.data?.data ?? null;
  const [banners, setBanners] = React.useState<BannerType[]>();
  const [bannerLoading, setBannerLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [cartPayload, setCartPayload] = React.useState<ChangeQuantityProps>({
    brand_id: -1,
    cart_id: "",
    type: "decrease",
  });

  const slideProperties = {
    indicators: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    duration: 4000,
    canSwipe: true,
  };

  const handleChangeQuantity = (param: ChangeQuantityProps) => {
    thunkDispatch(changeQuantity(param))
      .unwrap()
      .then(() => null);
  };

  React.useEffect(() => {
    if (StoreInfo && StoreInfo.status === "success") {
      setBannerLoading(true);
      thunkDispatch(listAllBanner(StoreInfo.data.data.merchant_id))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            setBanners(res.data.data);
            setBannerLoading(false);
          }
        });
    }
  }, [thunkDispatch, StoreInfo]);

  return (
    <MainLayout background="#fff" title="Keranjang | Kiosk Sandbox">
      <ToastContainer />
      <ConfirmationModal
        title="Apakah anda yakin ingin menghapus item ini?"
        handleConfirm={() => {
          handleChangeQuantity(cartPayload);
          setOpen(false);
        }}
        backgroundButton={data?.primary_color ?? "#FFC535"}
        open={open}
        handleClose={() => setOpen(false)}
      />
      {!bannerLoading ? (
        <>
          {banners && banners.length > 0 ? (
            <Slide {...slideProperties}>
              {banners.map((banner, index) => (
                <div key={"banner-" + index}>
                  <img
                    key={index}
                    style={{ height: "300px", width: "100%", objectFit: "cover" }}
                    src={banner.banner_image_web}
                    alt={banner.banner_name}
                  />
                </div>
              ))}
            </Slide>
          ) : (
            <Slide {...slideProperties}>
              <div>
                <img
                  style={{ height: "300px", width: "100%", objectFit: "cover" }}
                  src={SmallDefaultBannerSandbox}
                  alt={"small_default Banner"}
                />
              </div>
            </Slide>
          )}
        </>
      ) : (
        <BannerLoading />
      )}
      <Box
        width={"100%"}
        padding={"25px 52px"}
        sx={{ background: "#fff", height: "calc(100vh - 280px)" }}
      >
        <NormalText
          fontFamily="Roboto Condensed"
          fontSize="36px"
          fontWeight="bold"
          margin={"0 0 20px 0"}
        >
          PESANAN SAYA
        </NormalText>
        <SpacerLine style={{margin:"0 0 30px 0"}} />
        {Cart && Cart.items && Cart.items.length > 0 ? (
          Cart.items.map((brand, index) => (
            <div key={"product-item-" + index}>
              {brand.products.map((product, idx) => (
                <CartItem
                  key={"item-" + idx}
                  price={Number(product.price)}
                  quantity={product.qty}
                  image={product.product_image.length === 0
                    ? NoPhoto
                    : product.product_image
                  }
                  name={product.product_name ?? ""}
                  additionals={product.additionals ?? []}
                  backgroundColor={data?.primary_color ?? "#FFC535"}
                  onIncrease={() =>
                    handleChangeQuantity({
                      cart_id: product.cart_id ?? "",
                      brand_id: brand.brand_id,
                      type: "increase",
                    })
                  }
                  onDecrease={() => {
                    if (product.qty > 1) {
                      handleChangeQuantity({
                        cart_id: product.cart_id ?? "",
                        brand_id: brand.brand_id,
                        type: "decrease",
                      });
                    } else {
                      setCartPayload({
                        cart_id: product.cart_id ?? "",
                        brand_id: brand.brand_id,
                        type: "decrease",
                      });
                      setOpen(true);
                    }
                  }}
                />
              ))}
            </div>
          ))
        ) : (
          <NormalText
            fontFamily="Roboto Condensed"
            fontSize="30px"
            textAlign="center"
            margin={"100px 0"}
          >
            Belum ada produk
          </NormalText>
        )}

        {/* Add More Information */}
        <Box margin={"10px 0 300px 0"}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <div>
              <NormalText
                fontFamily="D-DIN Exp"
                fontSize={"32px"}
                fontWeight={"bold"}
              >
                Butuh Tambahan Lagi?
              </NormalText>
              <NormalText fontFamily="D-DIN Exp" fontSize={"22px"}>
                Kamu bisa tambah orderan dengan klik button disamping
              </NormalText>
            </div>
            <Button
              width="281px"
              height={"66px"}
              onClick={() => navigate("/home")}
              background={data?.primary_color ?? "#FFC535"}
            >
              <NormalText
                fontFamily="D-DIN Exp"
                fontSize={"24px"}
                color={"white"}
                fontWeight="700"
              >
                Tambah Menu Lain
              </NormalText>
            </Button>
          </Stack>
        </Box>
        <br />
      </Box>
      {/* Bottom Sheet For Cart Information Or Summaries */}
      <CartSheet
        backgroundColor={data?.primary_color ?? "#FFC535"}
        onClick={() =>
          Cart && Cart.items && Cart.items.length > 0
            ? navigate("/checkout")
            : toast.dark("Silahkan pilih produk terlebih dahulu!", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                closeButton: false,
                style: {
                  textAlign: "center",
                  margin: "50% 0 0 0",
                  width: "80% !important",
                },
              })
        }
      />
    </MainLayout>
  );
};

export default CartPage;
