import React from "react";
import { Box, Stack } from "@mui/material";
import styled from "styled-components";
import { NormalText } from "components/styled/text.styled";
import { QrisImage, CardImage, VaImage } from "asset/images";
import QRCode from "react-qr-code";
import { checkOrderStatus } from "redux/payment/payment.services";
import { cancelOrder } from "redux/checkout/checkout.services";
import useRedux from "redux/useRedux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ConfirmationModal from "components/Modal/confirmation";
import Loader from "components/Loading/loader";
import { resetCart } from "redux/cart/cart.reducer";
import { resetCheckoutData } from "redux/checkout";
import { formatCurrency } from "helper/general";
import IdleComponent from "components/Idle/IdleComponent";
import { countdownSetter } from "redux/preferences";

interface PaymentViewProps {
  type: string;
  metadata?: {
    account_number?: string;
    qr_code?: string;
    reference_id: string;
  };
}

const PaymentView = ({ type }: PaymentViewProps) => {
  const {
    storeState: {
      AppSetting,
      Checkout: { data, status },
    },
    thunkDispatch,
  } = useRedux();
  const navigate = useNavigate();
  const [orderPaid, setOrderPaid] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [openCancelModal, setOpenCancelModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const notify = () => {
    toast.dark("Pembayaran Behasil!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      closeButton: false,
      style: {
        textAlign: "center",
        margin: "50% 0 0 0",
        width: "80% !important",
      },
    });
  };

  React.useEffect(() => {
    if (status && status !== "success") {
      navigate("/");
    }
  }, [status, navigate]);

  React.useEffect(() => {
    if (!orderPaid && !loading) {
      if (data && data.data && data.data.order_number) {
        checkOrderStatus(data.data.order_number)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.data.status === "PAID") {
                setOrderPaid(true);
                notify();
                setRedirect(true);
                thunkDispatch(countdownSetter(Date.now())).unwrap().then(() => {
                  setTimeout(() => {
                    navigate("/payment/success");
                  }, 2000);
                })
              }
            }
          })
          .catch((e) => {
            console.log("Got Error: PYMNTCK", e);
          });
      }
    }
  });

  const handleResetState = () => {
    setLoading(true);
    setTimeout(() => {
      thunkDispatch(resetCart())
        .unwrap()
        .then(() => {
          thunkDispatch(resetCheckoutData())
            .unwrap()
            .then(() => {
              navigate("/");
            });
        });
    }, 3000);
  };

  const handleCancel = () => {
    if (data && data.data && data.data.order_number) {
      setOpenCancelModal(false);
      setLoading(true);
      thunkDispatch(cancelOrder(data.data.order_number))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            toast.dark("Transaksi dibatalkan", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              closeButton: false,
              style: {
                textAlign: "center",
                margin: "50% 0 0 0",
                width: "80% !important",
              },
            });
            handleResetState();
          } else if (res && res.status === "error") {
            toast.dark(res.error.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              closeButton: false,
              style: {
                textAlign: "center",
                margin: "50% 0 0 0",
                width: "80% !important",
              },
            });
          }
        })
        .catch((err) => {
          console.log("ERR", err);
        });
    }
  };

  return (
    <Box width={"100%"} padding={"120px 0 0 0"}>
      <IdleComponent load paymentView />
      <ToastContainer />
      <ConfirmationModal
        open={openCancelModal}
        backgroundButton={AppSetting?.data?.data?.primary_color ?? 'black'}
        title="Apakah kamu yakin ingin membatalkan transaksi"
        handleConfirm={() => handleCancel()}
        handleClose={() => setOpenCancelModal(false)}
      />
      <Box
        width={"100%"}
        justifyContent={"center"}
        sx={{ textAlign: "-webkit-center" }}
      >
        <PaymenImageContainer>
          <PaymentIcon
            src={type === "qr_code" ? QrisImage : CardImage}
            style={{ height: type === "qr_code" ? "250px" : "210px" }}
          />
        </PaymenImageContainer>
      </Box>
      <NormalText
        fontFamily={"Roboto Condensed"}
        fontSize={"36px"}
        fontWeight={"bold"}
        textAlign={"center"}
        margin={"36px 0 0 0"}
      >
        {type === "qr_code"
          ? "PEMBAYARAN DENGAN DIGITAL PAYMENT"
          : "PEMBAYARAN DENGAN VIRTUAL ACCOUNT"}
      </NormalText>
      <Box
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        display={"flex"}
      >
        <Box width={"80%"}>
          <NormalText
            fontSize="24px"
            fontFamily={"Roboto Condensed"}
            textAlign={"center"}
            margin={"36px 0 0 0"}
          >
            {type === "qr_code"
              ? "Silahkan Scan QR Code dibawah untuk pembayaran menggunakan Bank & e-Wallet"
              : "Masukkan kode virtual account yang anda dapatkan untuk melakukan pembayaran transaksi menggunakan m-banking atau atm terdekat. "}
          </NormalText>
        </Box>
      </Box>
      <Box>
        <NormalText
          color={"black"}
          fontWeight={"bold"}
          textAlign={"center"}
          fontSize={"40px"}
          margin={"40px 0 0 0"}
        >
          {formatCurrency(Number(data.data.total_amount))}
        </NormalText>
      </Box>
      <Box
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        display={"flex"}
        margin={type === "qr_code" ? "65px 0 0 0" : "45px 0 0 0"}
      >
        {type === "qr_code" ? (
          <QRCode
            value={data.data.metadata.qr_code ?? ""}
            size={500}
            level={"Q"}
          />
        ) : (
          <Stack
            direction={"column"}
            spacing={3}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <img
              src={VaImage}
              style={{ width: 668, height: 650 }}
              alt={"va_image"}
            />
            <AccountNumberBox>
              <NormalText
                fontSize="55px"
                fontFamily={"Roboto Condensed"}
                textAlign={"center"}
                fontWeight={"bold"}
                letterSpacing={".2rem"}
              >
                {data.data.metadata.account_number ?? ""}
              </NormalText>
            </AccountNumberBox>
            <NormalText
              fontSize="24px"
              fontFamily={"Roboto Condensed"}
              textAlign={"center"}
              margin={"36px 0 0 0"}
            >
              Gunakan nomor virtual account diatas untuk melanjutkan transaksi
              anda.
            </NormalText>
          </Stack>
        )}
      </Box>
      <NormalText margin="80px 0 0 0" fontSize="24px" textAlign="center">
        Atau
      </NormalText>
      <Box width={"100%"} textAlign={"center"}>
        <CancelOrderButton onClick={() => setOpenCancelModal(true)}>
          <NormalText
            fontFamily="Roboto Condensed"
            fontSize="30px"
            textAlign="center"
            isPressable
          >
            Batalkan Transaksi
          </NormalText>
        </CancelOrderButton>
      </Box>
      {redirect && (
        <NormalText
          fontStyle="italic"
          margin="40px 0"
          fontSize="24px"
          textAlign="center"
        >
          Mengalihkan...
        </NormalText>
      )}
      {loading && (
        <div
          style={{
            top: "0",
            position: "absolute",
            background: "rgb(0 0 0 / 51%)",
            height: "1920px",
            width: "100%",
            zIndex: 4,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "40%",
              left: "43%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Loader size={"100px"} />
            <NormalText
              color={"white"}
              fontFamily={"Roboto Condensed"}
              fontSize={"26px"}
              margin={"10px 0 0 0"}
            >
              Mohon Tunggu
            </NormalText>
          </div>
        </div>
      )}
    </Box>
  );
};

const PaymenImageContainer = styled.div`
  width: 350px;
  height: 350px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PaymentIcon = styled.img`
  width: 250px;
  height: 250px;
`;

const AccountNumberBox = styled.div`
  width: 843px;
  height: 118px;
  background: #e5e5e5;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CancelOrderButton = styled.button`
  background: transparent;
  padding: 10px 150px;
  border: 1px solid grey;
  align-self: center;
  margin: 50px 0 0 0;
  border-radius: 15px;
  color: grey !important;
`;

export default PaymentView;
