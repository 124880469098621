import styled from "styled-components";
import { ArrowWhiteIcon } from "asset/icons";
import history from "helper/history";
import { NormalText } from "components/styled/text.styled";

const BackButton = () => {
  return (
    <ButtonStyle onClick={() => history.back()}>
      <div style={{ flexDirection: "row", display: "flex" }}>
        <Icon
          style={{ cursor: "pointer" }}
          src={ArrowWhiteIcon}
          alt={"arrow_icon"}
        />
        <NormalText
          fontSize={"30px"}
          color={"#FFF4F8"}
          fontFamily="D-DIN Exp"
          margin="0px 0 0 24px"
        >
          Back To Menu
        </NormalText>
      </div>
    </ButtonStyle>
  );
};

const ButtonStyle = styled.button`
  background: transparent;
  border: none;
`;

const Icon = styled.img`
  user-select: none;
  -webkit-user-drag: none;
`;

export default BackButton;
