// import { encryptTransform } from "redux-persist-transform-encrypt";
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { persistCombineReducers } from 'redux-persist';
import StoreState from '../state';
import { StoreInfoReducer } from '../storeInfo';
import { BannerReducer } from '../banner';
import { ProductReducer } from '../product';
import { CartReducer } from 'redux/cart/cart.reducer';
import { CheckoutReducer } from 'redux/checkout';
import { BrandReducer } from 'redux/brand';
import { AppSettingReducer } from '../appsetting';
import { RegisterCustomerReducer } from '../registerCustomer';
import { InvoiceReducer } from 'redux/checkout/invoice.reducer';
import { TimeoutReducer } from 'redux/timeout/timeout.reducers';
import { LoyaltyPointReducer } from '../loyaltyPoint';
import { ConfigReducer } from 'redux/config/config.reducer';
import { PreferenceReducer } from 'redux/preferences';

const AppReducer = persistCombineReducers<StoreState>(
  {
    keyPrefix: '@',
    key: 'kiosksandbox',
    stateReconciler: autoMergeLevel2,
    storage,
    timeout: 0,
    serialize: true,
  },
  {
    StoreInfo: StoreInfoReducer,
    Banner: BannerReducer,
    Product: ProductReducer,
    Cart: CartReducer,
    Checkout: CheckoutReducer,
    Brand: BrandReducer,
    Preference: PreferenceReducer,
    AppSetting: AppSettingReducer,
    Invoice: InvoiceReducer,
    Timeout: TimeoutReducer,
    RegisterCustomer: RegisterCustomerReducer,
    LoyaltyPoint: LoyaltyPointReducer,
    ConfigService: ConfigReducer,
  },
);

export default AppReducer;
