import React from "react";
import MainLayout from "components/Layouts/MainLayout";
// @ts-ignore
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { Grid, Stack, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useRedux from "../../redux/useRedux";
import { BannerType, listAllBanner } from "redux/banner";
import LogoPagiiOrdering from "asset/logo/pagii-ordering-logomark-default.svg"
import { getAppSetting, AppSettingData } from "redux/appsetting";
import { DefaultBannerSandbox } from "asset/images";

const PromoPage = () => {
  let navigate = useNavigate();
  const {
    thunkDispatch,
    storeState: { StoreInfo },
  } = useRedux();
  const [banners, setBanners] = React.useState<BannerType[]>();
  const [data, setData] = React.useState<AppSettingData>();
  const [loading, setLoading] = React.useState(true);
  const slideProperties = {
    indicators: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    duration: 4000,
    canSwipe: true,
  };

  const handleNavigate = () => {
    navigate("/home")
    // if (ConfigService && ConfigService.selected_services && ConfigService.selected_services.enable) {
    // navigate("/pickservice")
    // } else {
    //   navigate("/home")
    // }
  }

  React.useEffect(() => {
    let storecode = "";
    setLoading(true);
    // Get store code
    if (
      StoreInfo &&
      StoreInfo.data &&
      StoreInfo.data.data &&
      StoreInfo.data.data.store_qr_code &&
      StoreInfo.data.data.store_qr_code.includes(storecode)
    ) {
      let url = StoreInfo.data.data.store_qr_code;
      let code = url.split("/");
      storecode = code[3];
      thunkDispatch(listAllBanner(StoreInfo.data.data.merchant_id))
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            setBanners(res.data.data);
            setLoading(false);
          }
        })
        .catch((e) => {
          console.log("ERR Banner", e);
        });
      thunkDispatch(
        getAppSetting({
          merchant_id: StoreInfo.data.data.merchant_id,
        })
      )
        .unwrap()
        .then((res) => {
          if (res && res.status === "success") {
            setData(res.data.data);
          }
        });
    } else {
      navigate("/set");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [StoreInfo]);
  return (
    <MainLayout
      noTimeout
      background={data?.primary_color ?? "#FFF4F8"}
      title={data?.merchant_name ?? ""}
    >
      <div onClick={() => handleNavigate()} style={{ cursor: 'pointer' }}>
        {loading ? (
          <Skeleton variant="rectangular" height={"90vh"} width={"100%"} />
        ) : banners && banners.length > 0 ? (
          <Slide {...slideProperties}>
            {banners.map(
              (banner, index) =>
                banner.banner_image_kiosk && (
                  <div
                    onClick={() => handleNavigate()}
                    key={index}
                  >
                    <img
                      key={index}
                      style={{ height: "100%", width: "100%", objectFit: "cover", objectPosition: "center" }}
                      src={banner.banner_image_kiosk}
                      alt={banner.banner_name}
                    />
                  </div>
                )
            )}
          </Slide>
        ) : (
          <Slide {...slideProperties}>
            <div
              onClick={() => handleNavigate()}
            >
              <img
                style={{ height: "90vh", width: "100%" }}
                src={DefaultBannerSandbox}
                alt={"default_banner"}
              />
            </div>
          </Slide>
        )}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            cursor: 'pointer',
            backgroundColor: "white",
            display: "flex",
            position: 'fixed',
            bottom: '0px',
            background: data?.primary_color ?? "#FFF4F8",
            padding: "40px 0px",
          }}
        >
          <Stack
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <div
              style={{
                fontSize: "44px",
                fontWeight: "bold",
                fontFamily: "D-DIN Exp",
                color: data?.secondary_color ?? "#FFC535",
              }}
              onClick={() => handleNavigate()}
            >
              SENTUH LAYAR UNTUK MEMESAN
            </div>
            <div
              style={{
                borderRadius:"50px",
                background:"white",
                padding:"15px 30px",
                margin:"2rem 0 0 0"
              }}
            >
              <img src={LogoPagiiOrdering} alt="powerdBy"/>
            </div>
          </Stack>
        </Grid>
      </div>
    </MainLayout>
  );
};

export default PromoPage;
