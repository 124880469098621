import { InvoiceState, InvoiceResponse } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { getInvoiceInfo } from './checkout.services';

export const initialState: InvoiceState = {
    data: {} as InvoiceResponse,
    status: "loading",
    error: {}
}

export const invoiceSlice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getInvoiceInfo.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getInvoiceInfo.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(getInvoiceInfo.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
    }
})

export const InvoiceReducer = invoiceSlice.reducer