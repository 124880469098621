import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export interface TimeoutState {
    data: any[]
}

export const initialState: TimeoutState = {
    data: [] as unknown[]
}

export const addTimeout = createAsyncThunk(
    "timeout/add",
    async (timeout: any) => {
        return timeout
    }
);

export const removeTimeouts = createAsyncThunk(
    "timeout/remove",
    async () => {
        return 'Remove'
    }
);

export const TimeoutSlice = createSlice({
    name: 'timeouts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addTimeout.fulfilled, (state, action) => {
                if (action) {
                    state.data = [...state.data, action.payload];
                }
            })
            .addCase(removeTimeouts.fulfilled, (state, action) => {
                state.data.forEach(item => {
                    clearTimeout(item)
                })
                state.data = []
            })
    }
})

export const TimeoutReducer = TimeoutSlice.reducer