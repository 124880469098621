import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

interface BottomSheetProps {
  children?: React.ReactNode;
  borderradius?: string;
  height?: string;
  padding?: string;
  zindex?: string;
  minheight?: string;
  shadow?: string;
}

const BottomSheet = ({
  children,
  borderradius,
  height,
  padding,
  zindex,
  minheight,
  shadow,
}: BottomSheetProps) => {
  return (
    <>
      <BottomModal
        shadow={shadow}
        animate={{ y: [0, -5, 0] }}
        transition={{ ease: [0.04, 0.62, 0.23, 0.98], duration: 1 }}
        borderradius={borderradius}
        height={height}
        padding={padding}
        zindex={zindex}
        minheight={minheight}
      >
        <Content padding={padding}>{children}</Content>
      </BottomModal>
    </>
  );
};

export const BottomModal = styled(motion.div)<BottomSheetProps>`
  position: fixed;
  height: ${(props) => props.height ?? "204px"};
  min-height: ${(props) => props.minheight ?? ""};
  z-index: ${(props) => props.zindex ?? "2"};
  width: 100%;
  max-width: 1080px;
  background: white;
  border: 1px solid #c4c4c4;
  border-width: 1px 0 0 0;
  bottom: 0;
  border-radius: ${(props) => props.borderradius ?? "none"};
  box-shadow: ${(props) => props.shadow ?? "0px 0px 20px rgba(0, 0, 0, 0.25)"};
`;

interface ChekoutButtonProps {
  background?: string;
  height?: string;
}

export const ChekoutButton = styled.button<ChekoutButtonProps>`
  background: ${(props) => props.background ?? "rgba(185, 35, 98, 1)"};
  border: none;
  width: 100%;
  color: white;
  height: ${(props) => props.height ?? "100px"};

  :focus,
  :hover {
    opacity: 0.8;
  }
`;

export const Content = styled.div<BottomSheetProps>`
  justify-content: center;
  text-align: center;
  padding: ${(props) => props.padding ?? "20px"};
`;

export default BottomSheet;
