import React from "react";
import styled from "styled-components";
import { Stack } from "@mui/material";
import { NormalText } from "components/styled/text.styled";

interface BrandItemProps {
  active?: boolean;
  image?: string;
  name?: string;
  id?: number;
  onClick?: any;
  backgroundColor?: any;
  backgroundActive?: any;
}

const BrandItem = ({
  active,
  image,
  name,
  onClick,
  backgroundColor,
  backgroundActive,
}: BrandItemProps) => {
  return (
    <Container
      active={active}
      onClick={onClick}
      backgroundColor={backgroundColor}
      backgroundActive={backgroundActive}
    >
      <Stack
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ padding: 10 }}
      >
        <BrandLogo src={image} alt={`icon_${name}`}  />
        <NormalText
          fontFamily="D-DIN Exp"
          fontSize={"16px"}
          textAlign={"center"}
          fontWeight={"bold"}
          color={active ? "white" : "black"}
          margin={"5px 0 0 0"}
          style={{ textTransform: "uppercase" }}
        >
          {name}
        </NormalText>
      </Stack>
    </Container>
  );
};

const Container = styled.div<BrandItemProps>`
  width: 100%;
  height: 264px;
  border-radius: 0 12% 12% 0;
  transition: ease-in-out 0.3s;
  background: ${(props) =>
    props.active
      ? props.backgroundColor
        ? props.backgroundActive
        : "#FFC535"
      : "transparent"};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }
  :not(:hover) {
    opacity: 1;
  }
`;

const BrandLogo = styled.img`
  width: 124px;
  height: 124px;
  color: white;
`;

export default BrandItem;
