import React from "react";
import { Box } from "@mui/material";
import styled from "styled-components";
import SearchIcon from "asset/icons/ic_search.svg";
import { Close } from '@mui/icons-material';

interface InputSearchType {
  placeholder?: string;
  value?: string;
  onChange?: any;
  onFocus?: any;
  onClick?: any;
  border?: any;
  backgroundColor?: any;
}
 
const InputSearch = ({ placeholder, value, onChange, onFocus, onClick, backgroundColor }: InputSearchType) => {
  return (
    <Box>
      <Icon
        backgroundColor={backgroundColor}
      >
        <img src={SearchIcon} alt={"search_icon"}
          style={{
            width: "25.06px",
            height: "25.06px",
            fill: "red",
          }}
        /> 
      </Icon>
      <Search placeholder={placeholder} value={value} onChange={onChange} onFocus={onFocus} backgroundColor={backgroundColor} />
      <Icon
        onClick={onClick}
        style={{
          marginLeft: "-50px",
          background: "none",
          width: "25.06px",
          height: "25.06px",
        }}
      >
        <Close/> 
      </Icon>
    </Box>
  );
};

const Icon = styled.i<InputSearchType>`
  position: absolute;
  margin: 0 10px 10px 30px;
  padding: -50px;
  height: 55px;
  width: 56px;
  background: ${(props) => 
    props.backgroundColor 
      ? props.backgroundColor
      : "#FFC535"
  };
  text-align: center;
  padding: 15px;
  border-radius: 10px 0px 0px 10px;
`;

const Search = styled.input<InputSearchType>`
  width: 93%;
  border: 1px solid ${(props) => props.backgroundColor};
  padding: 0 10px 0 76px;
  margin: 0 0 20px 30px;

  /* border: 1px solid #bababa; */
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  height: 55px ;
  font-family: "D-DIN";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  outline: none;
  letter-spacing: 0.5px;


  :focus,
  :hover {
    border-color: ${(props) => 
      props.backgroundColor 
        ? props.backgroundColor
        : "#FFC535"
    };
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: #bababa;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #bababa;
  }

  ::placeholder {
    color: #bababa;
  }
`;

export default InputSearch;
