import React from "react";
import { Box, Stack } from "@mui/material";
import styled from "styled-components";
import { NormalText } from "components/styled/text.styled";
import Dialog from "components/Modal/modal";
import { STORAGE_SERVICE_KEY } from "helper/constants";

import DineInIcon from "asset/icons/ic_dine_in.svg";
import DeliveryIcon from "asset/icons/ic_delivery.svg";
import TakeAwayIcon from "asset/icons/ic_take_away.svg";
import PickupIcon from "asset/icons/ic_pickup_point.svg";
import StorePickupIcon from "asset/icons/ic_store_pickup.svg";


export const listPickService = [
  {
    service: "DINE IN",
    detail: "Pesanan anda akan diantarkan ke meja anda",
    logo: DineInIcon,
    typeKiosk: "FOOD",
  },
  {
    service: "TAKEAWAY",
    detail: "Silahkan ambil pesanan anda",
    logo: DeliveryIcon,
    typeKiosk: "FOOD",
  },
  {
    service: "DELIVERY",
    detail: "Pesanan anda akan dikirim ke alamat anda",
    logo: TakeAwayIcon,
    typeKiosk: "FOOD/RETAIL",
  },
  {
    service: "PICKUP POINT",
    detail: "Silahkan ambil pesanan anda di titik pengambilan",
    logo: PickupIcon,
    typeKiosk: "FOOD/RETAIL",
  },
  {
    service: "IN STORE PICKUP",
    detail: "Silahkan ambil pesanan anda di toko",
    logo: StorePickupIcon,
    typeKiosk: "RETAIL",
  }
];

interface PickServiceTypeProps {
    service:
      | "DINE IN"
      | "TAKEAWAY"
      | "DELIVERY"
      | "PICKUP POINT"
      | "IN STORE PICKUP";
    onClick?: any;
    buttonColor?: any; 
}

const SelectedPickService = ({
    service,
    onClick,
    buttonColor
}: PickServiceTypeProps) => {
    return (
    <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
    >
        <img 
          src={
            service === "TAKEAWAY"
              ? TakeAwayIcon
              : service === "DINE IN"
              ? DineInIcon
              : service === "DELIVERY"
              ? DeliveryIcon
              : service === "IN STORE PICKUP"
              ? StorePickupIcon
              : service === "PICKUP POINT"
              ? PickupIcon
              : DineInIcon
          }
          alt={
            service === "TAKEAWAY"
              ? "Bawa Pulang Ikon"
              : service === "DINE IN"
              ? "Santap di Tempat Ikon"
              : service === "DELIVERY"
              ? "Pesan Antar Ikon"
              : service === "IN STORE PICKUP"
              ? "Ambil di Toko Ikon"
              : service === "PICKUP POINT"
              ? "Ambil di Titik Pengambilan Ikon"
              : "Pilih tipe pesanan Ikon"
          }
          style={{
            width:"80px",
            height:"80px",
            margin:"5px 30px 5px 10px"
          }}
        />
        <Stack
          direction={"column"}
          marginRight={"calc(100vh - 90vh)"}
          width={"60%"}
        >
          <NormalText
            fontFamily="Roboto Condensed"
            fontSize="24px"
            fontWeight="bold"
            style={{ textTransform: "capitalize" }}
          >
            {service.toLowerCase()} 
          </NormalText>
          <NormalText
            fontFamily="Roboto Condensed"
            fontSize="21px"
            fontWeight="bold"
            color={"#CCCCCC"}
          >
            {listPickService.find(data => data.service === service)?.detail} 
          </NormalText>

        </Stack>
        <Box
          width={"120px"}
          height={"50px"}
          boxShadow={"0px 6px 10px rgba(0, 0, 0, 0.08)"}
          borderRadius={"10px"}
          bgcolor={buttonColor?.primary_color ? buttonColor.primary_color + "60" : "#FFEE8E"}
          onClick={() => onClick()}
          sx={{ cursor: "pointer" }}
        >
          <NormalText
            fontFamily="Roboto Condensed"
            fontSize="20px"
            fontWeight="700"
            margin="10px 0 0 16px"
          >
            Ganti Tipe
          </NormalText>
        </Box>
    </Stack>
    );
};


interface ListPickServiceProps {
    open: boolean;
    onClose:() => void;
    merchantType: string;
    selectedService: string;
}

export const ListPickServiceModal = ({
    open,
    onClose,
    merchantType,
    selectedService
}: ListPickServiceProps) => {
    return (
    <Dialog
        styles={{
          width: "654px",
          borderRadius: "40px",
          padding: "48px 52px",
        }}
        open={open}
        handleClose={onClose}
    >
        <NormalText
          fontFamily="Roboto Condensed"
          fontSize="36px"
          fontWeight="700"
          textAlign="center"
        >
          Pilih Tipe Pesanan
        </NormalText>
        <Spacer/>
        <Box margin={"30px 0 0 0"}>
          { listPickService && 
            listPickService.filter((data) => data.typeKiosk.includes(merchantType))
              .map((list, index) => (
                <PickServiceItem
                  service={list.service}
                  detail={list.detail}
                  selectedService={selectedService}
                  onClose={onClose}
                />
              )
            )
          }
        </Box>
    </Dialog>
    );
};
interface PickServiceItemProps {
    service: string;
    detail: string;
    selectedService: string;
    onClose:() => void;
}
const PickServiceItem = ({
    service,
    detail,
    selectedService,
    onClose
}: PickServiceItemProps) => {
    return (
      <Box
        width={"100%"}
        height={"110px"}
        boxShadow={service === selectedService ? "0px 8px 10px rgba(0, 0, 0, 0.12)" : "0px 8px 10px rgba(0, 0, 0, 0.12)"}
        borderRadius={"10px"}
        // border={service === selectedService ? "solid 3px rgba(0, 0, 0, 0.12)" : ""}
        margin={"0 0 30px 0"}
        padding={"5px"}
        onClick={() => {
          localStorage.setItem(STORAGE_SERVICE_KEY, service );
          onClose();
        }}
      >   
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          // width={"100%"}
        >
          <Box
            sx={{
              height:"110px",
              width:"110px",
              justifyContent:"center",
              alignItems:"center",
            }}
          >
            <OrderTypeImage 
            src={
                service === "TAKEAWAY"
                  ? TakeAwayIcon
                  : service === "DINE IN"
                  ? DineInIcon
                  : service === "DELIVERY"
                  ? DeliveryIcon
                  : service === "IN STORE PICKUP"
                  ? StorePickupIcon
                  : service === "PICKUP POINT"
                  ? PickupIcon
                  : DineInIcon
              }
              alt={
                service === "TAKEAWAY"
                  ? "Bawa Pulang Ikon"
                  : service === "DINE IN"
                  ? "Santap di Tempat Ikon"
                  : service === "DELIVERY"
                  ? "Pesan Antar Ikon"
                  : service === "IN STORE PICKUP"
                  ? "Ambil di Toko Ikon"
                  : service === "PICKUP POINT"
                  ? "Ambil di Titik Pengambilan Ikon"
                  : "Pilih tipe pesanan Ikon"
              }
            />
          </Box>
            <Stack
              direction={"column"}
              // marginRight={"calc(100vh - 90vh)"}
              width={"100%"}
            >
              <NormalText
                fontFamily="Roboto Condensed"
                fontSize="24px"
                fontWeight="bold"
                style={{ textTransform: "capitalize" }}
              >
                {service.toLowerCase()} 
              </NormalText>
              <NormalText
                fontFamily="Roboto Condensed"
                fontSize="21px"
                fontWeight="bold"
                color={"#CCCCCC"}
              >
                {detail} 
              </NormalText>

            </Stack>
        </Stack>
      </Box>
    )
};

const Spacer = styled.div`
  height: 1px;
  background: #CCCCCC;
  margin: 20px 0 0 0;
`;

// const BankLogo = styled.img`
//   width: 120px;
//   max-height: 50px;
// `;

const OrderTypeImage = styled.img`
  width: 80px;
  height: 80px;
  margin: 10px 50px 0 10px;
`;

export default SelectedPickService;
