import React from "react";
import styled from "styled-components";
import { Stack } from "@mui/material";

export interface CounterProps {
  onIncrease?: React.MouseEventHandler<HTMLButtonElement>;
  onDecrease?: React.MouseEventHandler<HTMLButtonElement>;
  value: string | number | any;
  background?: string;
}

const CounterButton = ({
  onIncrease,
  onDecrease,
  value,
  background,
}: CounterProps) => {
  return (
    <Stack direction={"row"} alignItems={"center"} marginTop={"10px"}>
      <ButtonAction background={background ?? ""} onClick={onDecrease}>
        -
      </ButtonAction>
      <InputCount value={value} readOnly />
      <ButtonAction background={background ?? ""} onClick={onIncrease}>
        +
      </ButtonAction>
    </Stack>
  );
};

interface ButtonActionProps {
  background?: string;
}

const ButtonAction = styled.button<ButtonActionProps>`
  border-radius: 50%;
  color: ${(props) => props.background ?? "#b92362"};
  width: 46px;
  font-family: D-DIN;
  height: 46px;
  border: 3px solid ${(props) => props.background ?? "#b92362"};
  background: none;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  line-height: 3px;
`;

const InputCount = styled.input`
  width: 40px;
  margin: 0 10px;
  border: none;
  color: black;
  font-weight: bold;
  text-align: center;
  font-family: "Roboto Condensed";
  font-weight: 700;
  font-size: 36px;
  background: transparent;

  :focus {
    border: unset;
    outline: none;
  }

  :active {
    border: unset;
  }
`;

export default CounterButton;
