import IdleComponent from "components/Idle/IdleComponent";
import React from "react";
import { ReactNode } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Wrapper } from "./styles";

export interface Props {
  title?: string;
  meta?: string;
  description?: string;
  noTimeout?: boolean;
  children: ReactNode;
  background?: string;
  padding?: string;
  containerStyle?: any;
}

const MainLayout = ({
  title,
  children,
  background,
  padding,
  containerStyle,
  noTimeout,
}: Props) => {
  return (
    <Container style={containerStyle}>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{title ?? "Pagii Kiosk"}</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />

        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
          integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
          crossOrigin="anonymous"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.cdnfonts.com/css/d-din?styles=37959,37958,37957,37961,37964,37963,37960,37962"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700"
          rel="stylesheet"
        />
      </Helmet>
      <Wrapper background={background} padding={padding}>
        {children}
        <IdleComponent load={noTimeout ? false : true} />
      </Wrapper>
    </Container>
  );
};

export const Container = styled.div`
  position: relative;
  display: block;
  height: 100vh;
`;

export default MainLayout;
