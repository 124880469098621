import { Skeleton, Grid } from "@mui/material";
import styled from "styled-components";

const ProductLoading = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4} md={4} sm={4} lg={4}>
        <BrandBox>
          <Skeleton
            sx={{ height: 140, width: 140, borderRadius: "20px" }}
            animation="wave"
            variant="rectangular"
          />
          <Skeleton height={30} width={140} sx={{ borderRadius: "10px" }} />
          <Skeleton height={30} width={100} sx={{ borderRadius: "10px" }} />
        </BrandBox>
      </Grid>
      <Grid item xs={4} md={4} sm={4} lg={4}>
        <BrandBox>
          <Skeleton
            sx={{ height: 140, width: 140, borderRadius: "20px" }}
            animation="wave"
            variant="rectangular"
          />
          <Skeleton height={30} width={140} sx={{ borderRadius: "10px" }} />
          <Skeleton height={30} width={100} sx={{ borderRadius: "10px" }} />
        </BrandBox>
      </Grid>
      <Grid item xs={4} md={4} sm={4} lg={4}>
        <BrandBox>
          <Skeleton
            sx={{ height: 140, width: 140, borderRadius: "20px" }}
            animation="wave"
            variant="rectangular"
          />
          <Skeleton height={30} width={140} sx={{ borderRadius: "10px" }} />
          <Skeleton height={30} width={100} sx={{ borderRadius: "10px" }} />
        </BrandBox>
      </Grid>
      <Grid item xs={4} md={4} sm={4} lg={4}>
        <BrandBox>
          <Skeleton
            sx={{ height: 140, width: 140, borderRadius: "20px" }}
            animation="wave"
            variant="rectangular"
          />
          <Skeleton height={30} width={140} sx={{ borderRadius: "10px" }} />
          <Skeleton height={30} width={100} sx={{ borderRadius: "10px" }} />
        </BrandBox>
      </Grid>
      <Grid item xs={4} md={4} sm={4} lg={4}>
        <BrandBox>
          <Skeleton
            sx={{ height: 140, width: 140, borderRadius: "20px" }}
            animation="wave"
            variant="rectangular"
          />
          <Skeleton height={30} width={140} sx={{ borderRadius: "10px" }} />
          <Skeleton height={30} width={100} sx={{ borderRadius: "10px" }} />
        </BrandBox>
      </Grid>
      <Grid item xs={4} md={4} sm={4} lg={4}>
        <BrandBox>
          <Skeleton
            sx={{ height: 140, width: 140, borderRadius: "20px" }}
            animation="wave"
            variant="rectangular"
          />
          <Skeleton height={30} width={140} sx={{ borderRadius: "10px" }} />
          <Skeleton height={30} width={100} sx={{ borderRadius: "10px" }} />
        </BrandBox>
      </Grid>
      <Grid item xs={4} md={4} sm={4} lg={4}>
        <BrandBox>
          <Skeleton
            sx={{ height: 140, width: 140, borderRadius: "20px" }}
            animation="wave"
            variant="rectangular"
          />
          <Skeleton height={30} width={140} sx={{ borderRadius: "10px" }} />
          <Skeleton height={30} width={100} sx={{ borderRadius: "10px" }} />
        </BrandBox>
      </Grid>
    </Grid>
  );
};

const BrandBox = styled.div`
  width: 100%;
  margin: 30px 0 0 0;
  height: 264px;
  border-radius: 0 0 0 10px;
  text-align: -webkit-center;
`;

export default ProductLoading;
