import "./App.css";
import { Routes, Route } from "react-router-dom";
import LandingPage from "./views/Landing/home";
import Homepage from "./views/Home/home.page";
import ConfigPage from "./views/Config/config.page";
import PickServicePage from "./views/Landing/main.page";
import PromoPage from "views/Promo/promo.page";
import CartPage from "views/Cart/cart.page";
import CheckoutPage from "views/Checkout/checkout.page";
import ProductDetail from "views/Product/product.detail";
import PaymentSuccessPage from "views/Checkout/Payment/success";
import QueuePage from "views/Queue/queue.page";
import InvoicePage from "views/Checkout/Invoices/inv.page";

//loyalty setting
// import LoyaltySetting from 'views/Loyalty/loyaltySetting';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<PromoPage />} />
      <Route path="/set" element={<LandingPage />} />
      <Route path="/home" element={<Homepage />} />
      <Route path="/config" element={<ConfigPage />} />
      <Route path="/pickservice" element={<PickServicePage />} />
      <Route path="/cart" element={<CartPage />} />
      <Route path="/product/:productId/detail" element={<ProductDetail />} />
      <Route path="/checkout" element={<CheckoutPage />} />
      <Route path="/payment/success" element={<PaymentSuccessPage />} />
      <Route path="/invoice/print/:orderId" element={<InvoicePage />} />
      <Route path="/:merchant/queue" element={<QueuePage />} />
      {/* loyalty */}
      {/* <Route path="/loyaltysetting" element={<LoyaltySetting />} /> */}
    </Routes>
  );
};

export default App;
