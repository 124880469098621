import { Grid, Box } from "@mui/material";
import { NormalText } from "components/styled/text.styled";
import BrandItem from "./brand.item";
import ProductItem from "./product.item";
import { ProductListTypes } from "redux/product";
import { BrandsDetailType } from "redux/brand";
import { useNavigate } from "react-router-dom";
import BrandLoading from "./brand.loading";
import ProductLoading from "./product.loading";
import { ProductsTypes } from "redux/cart/cart.reducer";

interface SingleBrandHomeProps {
  brands: BrandsDetailType;
  products: ProductListTypes[];
  activeCategory: number;
  categoryClick: any;
  addToCartAction: any;
  brandLoading: boolean;
  productLoading: boolean;
  activeBrandName: string;
  activeBrand: number;
  primaryColor?: string;
  secondaryColor?: string;
}

const SingleBrandPage = ({
  brandLoading,
  brands,
  products,
  activeCategory,
  activeBrandName,
  activeBrand,
  categoryClick,
  productLoading,
  addToCartAction,
  primaryColor,
  secondaryColor,
}: SingleBrandHomeProps) => {
  const navigate = useNavigate();
  return (
    <Grid container style={{ paddingBottom: "150px" }}>
      <Grid item xs={3.5} md={3.5} lg={3.5}>
        <Box
          width={"100%"}
          sx={{ background: "#F9F9F9" }}
          overflow="auto"
          height={"calc(100vh - 400px)"}
          flexDirection="column"
          display="flex"
        >
          {!brandLoading ? (
            brands &&
            brands.category &&
            brands.category.map((item, index) => (
              <BrandItem
                active={activeCategory === item.id}
                key={"brand" + index}
                image={item.image_mobile}
                backgroundColor={"rgb(210 210 210)"}
                name={item.subscategory_name}
                onClick={(): number => {
                  return categoryClick(item.id);
                }}
              />
            ))
          ) : (
            <BrandLoading />
          )}
        </Box>
      </Grid>
      <Grid item xs={8.5} md={8.5} lg={8.5}>
        <Box
          width={"100%"}
          padding={"5px"}
          overflow="auto"
          height={"calc(100vh - 500px)"}
          flexDirection="column"
          display="flex"
        >
          <Grid container spacing={2}>
            {!productLoading ? (
              products && products?.length > 0 ? (
                products &&
                products.map((product, index) => (
                  <Grid
                    item
                    xs={4}
                    md={4}
                    sm={4}
                    lg={4}
                    key={"product-" + index}
                  >
                    <ProductItem
                      backgroundColor={secondaryColor ?? ""}
                      name={product.product_name ?? ""}
                      image={product.product_image_main}
                      price={
                        product.product_price_after_discount
                          ? Number(product.product_price_after_discount)
                          : Number(product.product_price)
                      }
                      addToCartAction={() => {
                        let params: ProductsTypes = {
                          price: product.product_price_after_discount
                            ? product.product_price_after_discount
                            : product.product_price,
                          product_id: product.product_id,
                          qty: 1,
                          is_customizable: product.is_customizable,
                          product_name: product.product_name,
                          product_image: product.product_image_main,
                          brand_name: activeBrandName,
                          brand_id: activeBrand,
                          additionals: [],
                        };
                        return addToCartAction(params);
                      }}
                      onChangeDetail={() =>
                        navigate(`/product/${product.product_id}/detail`)
                      }
                    />
                  </Grid>
                ))
              ) : (
                <Box width={"100%"}>
                  <NormalText
                    fontSize={"32px"}
                    textAlign={"center"}
                    margin={"100px 0 0 0"}
                  >
                    Oops, Belum ada produk
                  </NormalText>
                </Box>
              )
            ) : (
              <ProductLoading />
            )}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SingleBrandPage;
