import styled from "styled-components";

interface LoaderProps {
  size?: string;
}

const Loader = ({ size }: LoaderProps) => {
  return (
    <ContainerStyle size={size}>
      <LoadingStyle />
    </ContainerStyle>
  );
};

const LoadingStyle = styled.div`
  cursor: progress;
  border: 5px solid transparent;
  border-top: 5px solid orange;
  border-left: 5px solid orange;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: spin 0.5s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ContainerStyle = styled.div<LoaderProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => (size ? size : "40px")};
  height: ${({ size }) => (size ? size : "40px")};
  font-size: 24px;
  text-transform: uppercase;
`;

export default Loader;
