import { ResponseProps, BrandState } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { listAllBrand } from './brand.services';

export const initialState: BrandState = {
    data: {} as ResponseProps,
    status: "loading",
    error: {}
}

export const brandSlice = createSlice({
    name: 'brand',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(listAllBrand.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(listAllBrand.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(listAllBrand.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })

    }
})

export const BrandReducer = brandSlice.reducer