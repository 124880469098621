import { Skeleton, Box } from "@mui/material";
import styled from "styled-components";

const BrandLoading = () => {
  return (
    <Box height={"264px"}>
      <BrandBox>
        <Skeleton
          sx={{ height: 150, width: 150, borderRadius: "20px" }}
          animation="wave"
          variant="rectangular"
        />
        <Skeleton height={30} width={200} sx={{ borderRadius: "10px" }} />
      </BrandBox>
      <BrandBox>
        <Skeleton
          sx={{ height: 150, width: 150, borderRadius: "20px" }}
          animation="wave"
          variant="rectangular"
        />
        <Skeleton height={30} width={200} sx={{ borderRadius: "10px" }} />
      </BrandBox>
      <BrandBox>
        <Skeleton
          sx={{ height: 150, width: 150, borderRadius: "20px" }}
          animation="wave"
          variant="rectangular"
        />
        <Skeleton height={30} width={200} sx={{ borderRadius: "10px" }} />
      </BrandBox>
      <BrandBox>
        <Skeleton
          sx={{ height: 150, width: 150, borderRadius: "20px" }}
          animation="wave"
          variant="rectangular"
        />
        <Skeleton height={30} width={200} sx={{ borderRadius: "10px" }} />
      </BrandBox>
    </Box>
  );
};

const BrandBox = styled.div`
  width: 100%;
  margin: 20px 0 0 0;
  height: 264px;
  border-radius: 0 0 0 10px;
  text-align: -webkit-center;
`;

export default BrandLoading;
