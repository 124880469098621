import styled, { css } from "styled-components";
import { motion } from "framer-motion";

export const Layout = styled(motion.div)`
  padding: 0px;
  margin: 0px;
  background: #131318;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
  position: fixed;
  overflow: auto;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 2px;
    height: 1px;
  }
  &::-webkit-scrollbar-track {
    background: #1e1e1e;
  }
  &::-webkit-scrollbar-thumb {
    background: linear-gradient(
      131.42deg,
      #e7557055 32.19%,
      #ed676a55 53.45%,
      #f1726755 66.7%,
      #fd965b55 83.78%
    );
    border-radius: 8px;
    opacity: 0.1;
  }
`;
export interface StackProps {
  direction?: string | undefined;
  align?: "start" | "end" | "center" | "between" | "evenly" | undefined;
  width?: string | undefined;
  height?: string | undefined;
  wrap?: string | undefined;
  margin?: string;
  padding?: string;
  background?: string;
  position?:
    | "fixed"
    | "relative"
    | "sticky"
    | "absolute"
    | "static"
    | "-webkit-sticky"
    | "inherit"
    | "initial"
    | "-ms-page"
    | "unset"
    | undefined;
  display?: string;
  justify?: string;
  disableSelectText?: boolean;
  disableDrag?: boolean;
  isPressble?: boolean;
  zIndex?: number;
  opacity?: number;
}

export const AppContainer = styled.div<StackProps>`
  position: ${({ position }) => position};
  display: ${({ display }) => display ?? "flex"};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  background: ${({ background }) => background};
  flex-wrap: ${({ wrap }) => wrap};
  width: ${({ width }) => (width ? width : "100%")};
  height: ${({ height }) => (height ? height : "100%")};
  flex-direction: ${({ direction }) => direction};
  align-items: ${(p) => {
    switch (p.align) {
      case "center":
        return "center";
      case "start":
        return "flex-start";
      case "end":
        return "flex-end";
      case "between":
        return "space-between";
      case "evenly":
        return "space-evenly";
      default:
        return undefined;
    }
  }};
  ${(p) =>
    p.disableSelectText === true &&
    css`
      user-select: none;
      -ms-user-select: none;
      -moz-user-select: none;
      -ms-touch-select: none;
      -webkit-user-select: none;
    `}
  ${(p) =>
    p.disableDrag === true &&
    css`
      user-drag: none;
      -moz-window-dragging: none;
      -webkit-user-drag: none;
    `}
  justify-content: ${(p) => {
    switch (p.align) {
      case "center":
        return "center";
      case "start":
        return "flex-start";
      case "end":
        return "flex-end";
      case "between":
        return "space-between";
      case "evenly":
        return "space-evenly";
      default:
        return undefined;
    }
  }};

  @media only screen and (min-width: 500px) {
    max-width: 1600px;
  }
`;

interface SpacerLineProps {
  height?: string;
}

export const SpacerLine = styled.div<SpacerLineProps>`
  background: rgba(186, 186, 186, 1);
  width: 100%;
  height: ${(props) => props.height ?? "4px"};
`;
