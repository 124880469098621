import { CheckoutResponse, CheckoutState } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { createOrder, resetCheckoutData } from './checkout.services';

export const initialState: CheckoutState = {
    data: {} as CheckoutResponse,
    status: "loading",
    error: {}
}

export const checkoutSlice = createSlice({
    name: 'chekout',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createOrder.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(createOrder.fulfilled, (state, action) => {
                if (action.payload) {
                    state.data = action.payload.data;
                    state.status = action.payload.status;
                    state.error = action.payload.error
                }
            })
            .addCase(createOrder.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
            .addCase(resetCheckoutData.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(resetCheckoutData.fulfilled, (state, action) => {
                state.data = initialState.data;
                state.status = "reset";
                state.error = initialState.error
            })
            .addCase(resetCheckoutData.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.error
            })
    }
})

export const CheckoutReducer = checkoutSlice.reducer